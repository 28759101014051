import { apiURL } from "../config/consts";
import { toast } from "react-toastify";
import fetch from "./fetchWrapper";

export default class ManagementService {
  static add = async (data) => {
    try {
      const response = await fetch(`${apiURL}/pcp_service`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
        body: JSON.stringify(data),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static edit = async (id, data) => {
    try {
      const response = await fetch(`${apiURL}/pcp_service/${id}`, {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
        body: JSON.stringify(data),
      });

      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);

      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };
  static get = async (page) => {
    try {
      const response = await fetch(`${apiURL}/pcp_services/${page}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static getByBase = async (base) => {
    try {
      const url = `${apiURL}/pcp_services/base/` + base;
      const response = await fetch(url, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static getById = async (id) => {
    try {
      const response = await fetch(`${apiURL}/pcp_service/${id}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static filter = async (filter, page) => {
    try {
      const response = await fetch(`${apiURL}/pcp_services/filter/${page}`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
        body: JSON.stringify(filter),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static mechanical_filter = async (filter, page) => {
    try {
      const response = await fetch(
        `${apiURL}/mechanical_pendencies/filter/${page}`,
        {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("@omni/fast_track/token")
            )}`,
          }),
          body: JSON.stringify(filter),
        }
      );
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      const result = await response.json();
      return result;
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static next_pendencies = async (filter, page) => {
    try {
      const response = await fetch(
        `${apiURL}/next_pendencies/filter/${page}`,
        {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("@omni/fast_track/token")
            )}`,
          }),
          body: JSON.stringify(filter),
        }
      );
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      const result = await response.json();
      return result;
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static getAttachs = async (id) => {
    try {
      const response = await fetch(
        `${apiURL}/pcp_service/attachs/${id}`,
        {
          method: "GET",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("@omni/fast_track/token")
            )}`
          })
        }
      );
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      const result = await response.json();
      return result;
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };
}
