import React, { Component } from 'react';
import { Link, browserHistory } from "react-router";
import { toast } from 'react-toastify';
import $ from 'jquery';
import axios from 'axios';
import { apiURL } from "../../config/consts";
import Swal from 'sweetalert2';
import LoadingModal from '../LoadingModal';

export default class ImportFlights extends Component {

  constructor(props) {
    super(props);
    this.state = {
      active: true,
      file: null,
      loaded: 0,
      disabledButton: false,
      flights: [],
      loading: false
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    $('.inputfile').each(function () {
      var $input = $(this),
        $label = $input.next('label'),
        labelVal = $label.html();
      $input.on('change', function (e) {
        var fileName = '';
        if (this.files && this.files.length > 1)
          fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
        else if (e.target.value)
          fileName = e.target.value.split('\\').pop();

        if (fileName)
          $label.find('span').html(fileName);
        else
          $label.html(labelVal);
      });
      // Firefox bug fix
      $input
        .on('focus', function () { $input.addClass('has-focus'); })
        .on('blur', function () { $input.removeClass('has-focus'); });
    });
  }

  handleselectedFile = event => {
    this.setState({
      file: event.target.files[0],
      loaded: 0,
    })
  }

  handleUpload = async () => {
    const confirmation = (await Swal.fire({
      title: 'Confirmação',
      text: 'Você tem certeza que deseja fazer upload deste arquivo?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    })).value;
    if (confirmation) {
      this.setState({
        loading: true
      })

      let token = localStorage.getItem('@omni/fast_track/token').slice(1, -1);

      const config = {
        headers: {
          authorization: `Bearer ${token}`
        }
      }

      const data = new FormData()
      if (this.state.file.name)
        data.append('file', this.state.file, this.state.file.name)
      else
        data.append('file', this.state.file, 'programming.xlsx')

      axios
        .post(apiURL + '/flights/upload/', data, config, {
          onUploadProgress: ProgressEvent => {
            this.setState({
              loaded: (ProgressEvent.loaded / ProgressEvent.total * 100),
              disabledButton: true
            })
          },
        })
        .then(res => {
          this.setState({
            flights: res.data.flights,
            loading: false
          })
          // browserHistory.push('/engineering/aircrafts')
          // toast.success('Upload concluído!')
        })
    }
  }

  renderFileData = () => {
    const uniqueAircrafts = [...new Set(this.state.flights.map(flight => flight.aircraft))]
    const uniqueBases = [...new Set(this.state.flights.map(flight => flight.base))]

    let totalFlightSteps = 0;

    this.state.flights.map((flight) => {
      if (flight.flight_steps) {
        totalFlightSteps += flight.flight_steps
      }
    })

    return (
      <tr>
        <td>{uniqueAircrafts.length}</td>
        <td>{uniqueBases.length}</td>
        <td>{this.state.flights.length}</td>
        <td>{totalFlightSteps}</td>
      </tr>
    )
  }

  render() {
    return (
      <div className="wrapper wrapper-content">
        <div
          className="row wrapper border-bottom white-bg page-heading animated bounceInRight"
          style={{ margin: 'auto', padding: '40px' }}
        >
          <h2>Importação de voos</h2><br />
          <div className="row" style={{ textAlign: 'center' }}>
            <div className="col-lg-12" style={{ marginTop: '10px' }}>
              <div className="box">
                <input type="file" name="file-5[]" onChange={this.handleselectedFile} id="file-5" className="inputfile inputfile-4" style={{ display: 'none' }}
                  accept=".xlsx" />
                <label htmlFor="file-5">
                  <figure>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                      <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
                    </svg>
                  </figure>
                  <span>
                    Escolha o arquivo (.xlsx)
                  </span>
                </label>
              </div>
            </div>
          </div>
          <br />
          <div className="row" style={{ textAlign: 'center' }} >
            <div className="col-lg-12">
              <button
                className="btn btn-success"
                onClick={this.handleUpload}
                style={{ margin: '2px' }}
              >
                Salvar
              </button>
              <button
                className="btn btn-danger"
                style={{ margin: '2px' }}
                onClick={async () => {
                  const confirmation = (await Swal.fire({
                    title: 'Confirmação',
                    text: 'Você tem certeza que deseja cancelar?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não'
                  })).value;
                  if (confirmation) {
                    browserHistory.push(`/engineering/aircrafts`)
                  }
                }}
              >Cancelar</button>
              {/* </Link> */}
            </div>
          </div>
          <div className="col-lg-12">
            <center style={{ marginTop: '48px' }}>
              {this.state.loading ? (
                <div className="col-lg-12" style={{ height: '100px' }}>
                  <div className="row">
                    <LoadingModal visible={this.state.loading} />
                  </div>
                </div>
              ) : ''}
              {this.state.flights.length > 0 ? (
                <>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Total de Aeronaves Operando</th>
                        <th>Total de Bases Operando</th>
                        <th>Quantidade de Registros</th>
                        <th>Total de etapas</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.flights && this.renderFileData()}
                    </tbody>
                  </table>
                  <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4"><i class="fas fa-check-circle" style={{ color: '#1AB394', fontSize: '48px' }}></i></div>
                  </div>
                  <div className="col-lg-12" style={{ fontSize: '20px', margin: '32px 0' }}>Importação concluída com sucesso. Por favor, preencha os voos e downloads no Gerenciamento HUMS.</div>
                  <div className="col-md-4"></div>
                  <div className="col-md-4" style={{ paddingTop: 18, paddingLeft: 0, paddingRight: '8px' }}>
                    <Link to="/engineering/aircrafts">
                      <button
                        className="btn btn-success btn-block"
                      >
                        <i className="fa fa-plane"></i> Gerenciamento HUMS
                        </button>
                    </Link>
                  </div>
                </>
              ) : ''}
            </center>
          </div>
        </div>
      </div>
    )
  }
}