import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import ProcessService from '../../services/ProcessService';
import RoundedPagination from '../../components/common/RoundedPagination';

export default class Processs extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      processes: [],
      filter: '',
      page: props.params.page || 1,
      pages: 0 
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.filter();
  }

  async componentWillReceiveProps(nextProps) {
    this.setState({ page: nextProps.params.page || 1 }, () => {
      this.filter();
    });
  }

  getProcesss = async () => {
    this.setState({ loading: true });
    const obj = await ProcessService.get(this.state.page);
    const processes = obj ? obj.result : [];
    const pages = obj ? obj.pages : 0;
    this.setState({ processes, pages });
    this.setState({ loading: false });
  }

  filter = async () => {
    if (this.state.filter.length > 0) {
      const obj = await ProcessService.get(1, this.state.filter);
      const processes = obj ? obj.result : [];
      this.setState({ processes });
    } else this.getProcesss();
  }

  renderProcess = (process) => {
    return (
      <tr
        id="identify"
        key={process._id}
        onClick={() => this.props.history.push(`/edit-process/${process._id}`)}
        style={{ cursor: 'pointer' }}
      >
        <td>
          <i
            className={`fa fa-${process.active ? 'check' : 'times'} text-${process.active ? 'success' : 'error'}`}
          />
        </td>
        <td style={{ color: '#337ab7' }}>{process.code}</td>
        <td style={{ color: '#337ab7' }}>{process.type}</td>
        <td>{process.description}</td>
      </tr>
    );
  }

  handlePagination = (_event, value) => {
    this.setState({ page: value }, () => {
      browserHistory.push(`/process/${this.state.page}`);
    });
  }

  render() {
    return (
      <div className="wrapper wrapper-content  animated fadeInDown">
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-6">
                <h2>Processos</h2>
                <ol className="breadcrumb">
                  <li>
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li>
                    <Link>Configurações</Link>
                  </li>
                  <li className="active">
                    <strong>Processos</strong>
                  </li>
                </ol>
              </div>
              <div className="col-lg-6">
                <div className="pull-right" style={{ paddingTop: 10 }}>
                  <Link to='/add-process'>
                    <button className="btn btn-success" data-toggle="modal" data-target="#addModal  ">Adicionar</button>
                  </Link>
                </div>
                {/* <form onSubmit={e => { e.preventDefault(); this.filter(); }}>
                   <div className="input-group pull-right" style={{ marginTop: 15 }}>
                   <input
                   type="text"
                   placeholder="Procurar Motivo"
                   className="input-sm form-control"
                   value={this.state.filter}
                   onChange={e => this.setState({ filter: e.target.value })}
                   style={{ backgroundColor: 'rgba(255, 255, 255, 0.835)' }}
                   />
                   <span className="input-group-btn">
                   <button type="submit" className="btn btn-sm btn-success" >
                   <i className="fa fa-search"></i>
                   </button>
                   </span>
                   </div>
                   </form> */}
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper wrapper-content">
          <div className="row">
            <div className="col-lg-12 ibox-content">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Ativo</th>
                      <th>Código</th>
                      <th>Tipo</th>
                      <th>Descrição</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.loading
                     ? <tr><th colSpan={6}><div className='spinner spinner-lg' /></th></tr>
                     : this.state.processes.map(this.renderProcess)}
                  </tbody>
                </table>
              </div>

              <RoundedPagination onChange={this.handlePagination}
                                 page={this.state.page}
                                 count={this.state.pages} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
