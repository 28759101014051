import { apiURL } from "../config/consts";
import { toast } from "react-toastify";
import Axios from "axios";
import { saveAs } from "file-saver";

const axiosConfig = {
  responseType: "arraybuffer",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${JSON.parse(
      localStorage.getItem("@omni/fast_track/token")
    )}`,
  },
};

export default class FileService {
  static fetchMOSAReport = async (filter) => {
    try {
      Axios.post(
        apiURL + "/report/mosa",
        {
          base: filter.base,
          hangar: filter.hangar,
          start_service: new Date(filter.start_service),
          end_service: new Date(filter.end_service),
          mosa_status: filter.mosa_status
        },
        axiosConfig
      )
        .then((res) => {
          var blob = new Blob([res.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, "dados_mosa.xlsx");
        })
        .catch((err) => {
        });
    } catch (e) {
      toast.error(e);
      return false;
    }
  };

  static fetchReport = async (filter) => {
    Axios.post(
      apiURL + "/report",
      {
        base: filter.base,
        hangar: filter.hangar,
        start_service: new Date(filter.start_service),
        end_service: new Date(filter.end_service),
      },
      axiosConfig
    ).then((res) => {
      var blob = new Blob([res.data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "programacao.xlsx");
    });
  };

  static fetchEvaluationReport = async (filter) => {
    Axios.post(
      apiURL + "/mechanics-evaluations-report",
      {
       ...filter,
      },
      axiosConfig
    ).then((res) => {
      var blob = new Blob([res.data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "relatorio.xlsx");
    });
  };

  static fetchMechanicalPendingReport = async (filter) => {
    let response = await Axios.post(
      apiURL + "/mechanical_pendencies/report",
      {
        name: filter.name,
        base: filter.base,
        period: filter.period,
        period_type: filter.period_type,
      },
      axiosConfig
    ).then((res) => {
      var blob = new Blob([res.data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "dados_pendencies.xlsx");
      response = true
      return res;
    })
      .catch(function (error) {
        console.log(error);
        return error.response;
      });

    return response
  };
  
  static fetchHUMSReport = async (filter) => {
    try {
      Axios.post(
        `${apiURL}/flights-report/report/excel`,
        filter,
        axiosConfig,
      ).then((res) => {
        var blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "HUMS.xlsx");
      })
      .catch((err) => {
      });
    } catch (e) {
      toast.error(e);
      return false;
    }
  };
}
