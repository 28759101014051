import { apiURL } from '../config/consts';
import { toast } from 'react-toastify';
import fetch from './fetchWrapper';

export default class AircraftsService {
  static get = async (page, filter = false) => {
    try {
      const url = filter
        ? `${apiURL}/aircraft-filter/${filter}`
        : `${apiURL}/aircrafts-listall/${page}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem('@omni/fast_track/token')
          )}`,
        }),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static filter = async (page, filter, flag = 0) => {
    try {
      const url = flag ? `${apiURL}/aircrafts-listall/${page}?limit=100` : `${apiURL}/aircrafts-listall/${page}`;
      const response = await fetch(url, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem('@omni/fast_track/token')
          )}`,
        }),
        body: JSON.stringify(filter),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static getAll = async (params) => {
    try {
      const url = `${apiURL}/aircrafts-listall?${new URLSearchParams(params)}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem('@omni/fast_track/token')
          )}`,
        }),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static getById = async (id) => {
    try {
      const response = await fetch(`${apiURL}/aircraft/${id}`, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem('@omni/fast_track/token')
          )}`,
        }),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static add = async (data) => {
    try {
      const response = await fetch(`${apiURL}/aircraft`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem('@omni/fast_track/token')
          )}`,
        }),
        body: JSON.stringify(data),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static edit = async (id, data) => {
    try {
      const response = await fetch(`${apiURL}/aircraft/${id}`, {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem('@omni/fast_track/token')
          )}`,
        }),
        body: JSON.stringify(data),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };
}
