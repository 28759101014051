import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import MosaService from '../services/MosaService';
import AircraftService from '../services/AircraftsService';
import BaseService from '../services/BasesService';
import Moment from 'moment';
import HangarsService from '../services/HangarsService';
import ProcessService from '../services/ProcessService';
import RoundedPagination from '../components/common/RoundedPagination';
import LoadingModal from './LoadingModal';

export default class MosaManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      services: [],
      filter: '',
      pages: 0,
      page: 1,
      base: null,
      code: '',
      hangar: null,
      process: null,
      filter_hangars: [],
      filter_aircrafts: [],
      filter_bases: [],
      filter_processes: [],
      filter_complexities: [],
      filter_inspections: [],
    };

    const role = JSON.parse(localStorage.getItem('@omni/fast_track/user')).role;
    if (role !== 'ADM' && role !== 'ADM - TI')
      browserHistory.push('/dashboard');
  }

  getHangars = async (base) => {
    const filter_hangars = await HangarsService.getByBase(base);
    this.setState({
      filter_hangars: filter_hangars ? filter_hangars : [],
    });
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    let start_service = Moment(this.props.params.date);
    start_service = this.validationDate(start_service.format());
    const bases = await BaseService.getAll();
    const hangars = await HangarsService.getAll();
    const filter_aircrafts = await AircraftService.getAll();
    const processes = await ProcessService.getAll();

    if (this.props.location.state && this.props.location.state.clearFilter) {
      sessionStorage.removeItem('@fast_track/MOSA_filters');
      this.props.router.replace('/mosa_managements');
    }
    const storedFilters = JSON.parse(
      sessionStorage.getItem('@fast_track/MOSA_filters') || 'null'
    );

    await this.setState({
      filter_bases: bases ? bases : [],
      filter_processes: processes ? processes : [],
      bases,
      processes,
      hangars,
      filter_hangars: hangars,
      start_service: this.props.params.date ? start_service : null,
      searching: true,
      filter_aircrafts: filter_aircrafts ? filter_aircrafts : [],
      loading: false,
      ...(storedFilters
        ? {
            base: storedFilters.base,
            process: storedFilters.process,
            hangar: storedFilters.hangar,
            aircraft: storedFilters.aircraft,
            start_service: storedFilters.start_service,
            end_service: storedFilters.end_service,
            start_prediction: storedFilters.start_prediction,
            end_prediction: storedFilters.end_prediction,
            complexity: storedFilters.complexity,
            programming: storedFilters.programming,
            mosa_status: storedFilters.mosa_status,
            risk: storedFilters.risk,
            code: storedFilters.code,
          }
        : {}),
    });
    this.getServices();
  }

  async componentWillReceiveProps(nextProps) {
    let start_service = Moment(nextProps.params.date);
    start_service = this.validationDate(start_service.format());
    const bases = await BaseService.getAll();
    const hangars = await HangarsService.getAll();
    const filter_aircrafts = await AircraftService.getAll();
    const processes = await ProcessService.getAll();

    // const baseName = base ? this.state.filter_bases.filter(b => b._id === base)[0].name : null;
    await this.setState({
      bases,
      processes,
      // baseName,
      hangars,
      filter_hangars: hangars,
      start_service: nextProps.params.date ? start_service : null,
      searching: true,
      filter_aircrafts: filter_aircrafts ? filter_aircrafts : [],
      loading: false,
    });
  }

  componentDidUpdate(prevProps, { page }) {
    if (page !== this.state.page) this.getServices();
  }

  getServices = async (props) => {
    this.setState({ loading: true });
    this.page = this.state.page;
    const obj = await MosaService.filter(
      {
        base: this.state.base,
        process: this.state.process,
        hangar: this.state.hangar,
        code: this.state.code,
        aircraft: this.state.aircraft,
        start_service: this.state.start_service,
        end_service: this.state.end_service,
        start_prediction: this.state.start_prediction,
        end_prediction: this.state.end_prediction,
        complexity: this.state.complexity,
        programming: this.state.programming,
        mosa_status: this.state.mosa_status,
        risk: this.state.risk,
      },
      this.page
    );

    const services = obj ? obj.result : [];
    const pages = obj ? obj.pages : 0;
    const total = obj ? obj.total : 0;

    await this.setState({ services, pages, total });
    let MOSACount = 0;
    services.map(() => MOSACount++);
    this.setState({ MOSACount });
    this.setState({ loading: false });
  };

  filter = async () => {
    if (this.state.filter.length > 0) {
      this.setState({ loading: true, page: 1 });
      const obj = await MosaService.get(1);
      const services = obj ? obj.result : [];
      this.setState({ services });
      this.setState({ loading: false });
    } else this.getServices();
  };

  getWorstEvent = (events) => {
    return events.reduce((worst, current) => {
      const value = current.probability * current.impact;
      return value >= worst ? value : worst;
    }, 0);
  };

  selectColor = (events) => {
    const result = this.getWorstEvent(events);
    if (result >= 1 && result <= 3) return '#92d050';
    else if (result > 3 && result <= 6) return '#ffc000';
    else if (result > 6 && result <= 12) return '#ff0000';
    else if (result > 12 && result <= 25) return '#c00000';
    else return '#FFF';
  };
  selectResult = (events) => {
    const result = this.getWorstEvent(events);
    if (result >= 1 && result <= 3) return 'Risco Pequeno';
    else if (result > 3 && result <= 6) return 'Risco Moderado';
    else if (result > 6 && result <= 12) return 'Risco Alto';
    else if (result > 12 && result <= 25) return 'Risco Crítico';
    else return 'Não Há Riscos';
  };

  printStatusMOSA = (status) => {
    switch (status) {
      case 'MOSA Solicitada pela Qualidade': {
        return 'Sol. pela Qualidade';
      }
      case 'MOSA Solicitada pela Manutenção': {
        return 'Sol. pela Manutenção';
      }
      case 'MOSA Realizada': {
        return 'Realizada';
      }
      case 'MOSA não Realizada': {
        return 'Não Realizada';
      }
      case 'MOSA Conferida': {
        return 'Conferida';
      }
      case 'MOSA Lançada': {
        return 'Lançada';
      }
      default: {
        return status;
      }
    }
  };

  fixTimezone = (date) => {
    if (!date) return null;

    const fixedDate = new Date(date);
    fixedDate.setMinutes(
      fixedDate.getMinutes() + fixedDate.getTimezoneOffset()
    );
    return fixedDate;
  };

  renderServices = (service) => {
    const execute_prediction = this.fixTimezone(service.execute_prediction);
    const mosa_start = this.fixTimezone(service.mosa_start);
    const mosa_end = this.fixTimezone(service.mosa_end);

    return (
      <tr
        id="identify"
        key={service._id}
        onClick={() => browserHistory.push(`/mosa_management/${service._id}`)}
        style={{ cursor: 'pointer' }}
      >
        <td>
          <a>{service.aircraft ? service.aircraft.prefix : 'Não informada'}</a>
        </td>
        <td>
          {service.aircraft && service.aircraft._model
            ? service.aircraft._model.manufacturer
            : ''}{' '}
          -{' '}
          {service.aircraft && service.aircraft._model
            ? service.aircraft._model.model
            : ''}
        </td>
        <td>{service.register}</td>
        <td>{service.inspection}</td>
        <td>{service.code}</td>
        <td>
          {service.hangar
            ? service.position
              ? service.hangar
                ? service.hangar.name
                : '' + ' (' + service.position + ')'
              : service.hangar
              ? service.hangar.name
              : ''
            : 'Não informado'}
        </td>
        <td>
          {service.execute_prediction
            ? Moment(new Date(execute_prediction))
                .locale('pt-BR')
                .format('DD/MM/YYYY HH:mm')
            : 'Sem data'}
        </td>
        <td style={{ color: '#1884CB' }}>
          <b>
            {mosa_start
              ? Moment(new Date(mosa_start))
                  .locale('pt-BR')
                  .format('DD/MM/YYYY HH:mm')
              : 'Sem data'}
          </b>
        </td>
        <td>
          {mosa_end
            ? Moment(new Date(mosa_end))
                .locale('pt-BR')
                .format('DD/MM/YYYY HH:mm')
            : 'Sem data'}
        </td>
        <td>
          {service.programming ? 'Serv. Programado' : 'Serv. não Programado'}
        </td>
        <td>{service.process && service.process.description}</td>
        <td>
          {service.isMosa ? (
            <div
              className={
                service.mosa_status == 'MOSA Realizada'
                  ? 'btn btn-warning'
                  : service.mosa_status == 'MOSA Conferida'
                  ? 'btn btn-primary'
                  : service.mosa_status === 'MOSA não Realizada'
                  ? 'btn btn-light'
                  : 'btn btn-info'
              }
              style={{
                backgroundColor:
                  service.mosa_status === 'MOSA não Realizada'
                    ? '#cfd8dc'
                    : undefined,
              }}
            >
              {service.mosa_status
                ? this.printStatusMOSA(service.mosa_status)
                : ''}
            </div>
          ) : (
            <div className="btn">&nbsp;&nbsp;&nbsp;&nbsp;</div>
          )}
        </td>
        <td
          style={{
            backgroundColor:
              service.mosa_status == 'MOSA Conferida'
                ? this.selectColor(service.events)
                : '',
            color:
              this.selectResult(service.events) === 'Não Há Riscos'
                ? '#000'
                : '#FFF',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          {service.mosa_status == 'MOSA Conferida' &&
            this.selectResult(service.events)}
        </td>
      </tr>
    );
  };

  changeBase = async (base) => {
    if (base) {
      const hangars = await HangarsService.getByBase(base);
      this.setState({
        filter_hangars: hangars,
      });
    } else {
      const hangars = await HangarsService.getAll();
      this.setState({
        filter_hangars: hangars,
      });
    }
  };

  validationDate = (date) => {
    return !date
      ? null
      : `${new Date(date).getFullYear()}-${`${
          new Date(date).getMonth() + 1
        }`.padStart(2, 0)}-${`${new Date(date).getDate()}`.padStart(
          2,
          0
        )}T${`${new Date(date).getHours()}`.padStart(2, 0)}:${`${new Date(
          date
        ).getMinutes()}`.padStart(2, 0)}`;
  };

  handleSearch = async () => {
    this.setState({ searching: true, page: 1 });
    const {
      base,
      process,
      hangar,
      aircraft,
      start_service,
      end_service,
      start_prediction,
      end_prediction,
      complexity,
      programming,
      mosa_status,
      risk,
      code,
    } = this.state;
    sessionStorage.setItem(
      '@fast_track/MOSA_filters',
      JSON.stringify({
        base,
        process,
        hangar,
        aircraft,
        start_service,
        end_service,
        start_prediction,
        end_prediction,
        complexity,
        programming,
        mosa_status,
        risk,
        code,
      })
    );
    this.getServices();
  };

  handleClear = async () => {
    await this.setState({
      hangar: '',
      code: '',
      base: '',
      process: '',
      aircraft: '',
      start_service: '',
      end_service: '',
      start_prediction: '',
      end_prediction: '',
      complexity: '',
      programming: '',
      services: [],
      page: 1,
      mosa_status: '',
      risk: false,
    });
    this.getServices();
    this.setState({ searching: true });
  };

  handlePagination = (event, value) => {
    this.setState({ page: value });
  };

  renderBasesOptions = (base) => (
    <option value={base._id}>
      {`${base.name}`} ({`${base.code}`}){' '}
    </option>
  );

  renderProcessOptions = (process) => (
    <option value={process._id}>
      {`${process.description}`} ({`${process.code}`}){' '}
    </option>
  );

  renderHangarsOptions = (hangar) => (
    <option value={hangar._id}>{`${hangar.code} (${
      hangar.base ? hangar.base.name : ''
    })`}</option>
  );

  renderAircraftsOptions = (aircraft) => (
    <option value={aircraft._id}>{`${aircraft.prefix}  (${
      aircraft._model ? aircraft._model.manufacturer : ''
    } - ${aircraft._model ? aircraft._model.model : ''})`}</option>
  );

  render() {
    return (
      <div>
        <div className="wrapper wrapper-content animated fadeInDown">
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6">
                  <h2>Gestão MOSA</h2>
                  <ol className="breadcrumb">
                    <li>
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="active">
                      <strong>Gestão MOSA</strong>
                    </li>
                  </ol>
                </div>
                <div className="col-lg-6"></div>
              </div>
            </div>
            <div className="col-lg-12" style={{ paddingTop: 20 }}>
              <div className="row">
                <div className="col-xs-12 col-md-6 col-xl-3">
                  Código MOSA
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-tag"></i>
                    </span>
                    <input
                      className="form-control"
                      value={this.state.code}
                      onChange={(e) => {
                        this.setState({ code: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-xl-3">
                  Bases
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-home"></i>
                    </span>
                    <select
                      className="form-control"
                      value={this.state.base}
                      onChange={(e) => {
                        this.setState({ base: e.target.value });
                        this.changeBase(e.target.value);
                      }}
                    >
                      <option value="">Todas as bases</option>
                      {this.state.filter_bases.map(this.renderBasesOptions)}
                    </select>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-xl-3">
                  Hangar
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-home"></i>
                    </span>
                    <select
                      className="form-control"
                      value={this.state.hangar}
                      onChange={(e) =>
                        this.setState({ hangar: e.target.value })
                      }
                    >
                      <option value="">Todos os hangares</option>
                      {this.state.filter_hangars.map(this.renderHangarsOptions)}
                    </select>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-xl-3">
                  Aeronave
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-plane"></i>
                    </span>
                    <select
                      className="form-control"
                      value={this.state.aircraft}
                      onChange={(e) =>
                        this.setState({
                          aircraft: e.target.value,
                        })
                      }
                    >
                      <option value="">Todas as aeronaves</option>
                      {this.state.filter_aircrafts.map(
                        this.renderAircraftsOptions
                      )}
                    </select>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-xl-3">
                  Previsão de Início (de)
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-calendar"></i>
                    </span>
                    <input
                      type="date"
                      className="form-control"
                      value={this.state.start_prediction}
                      onChange={(e) =>
                        this.setState({
                          start_prediction: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-xl-3">
                  Previsão de Início (até)
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-calendar"></i>
                    </span>
                    <input
                      type="date"
                      className="form-control"
                      value={this.state.end_prediction}
                      onChange={(e) =>
                        this.setState({
                          end_prediction: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-xl-3">
                  Início do Serviço
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-plane"></i>
                    </span>
                    <input
                      type="date"
                      className="form-control"
                      value={this.state.start_service}
                      onChange={(e) =>
                        this.setState({
                          start_service: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-xl-3">
                  Fim do Serviço
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-plane"></i>
                    </span>
                    <input
                      type="date"
                      className="form-control"
                      value={this.state.end_service}
                      onChange={(e) =>
                        this.setState({
                          end_service: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="col-xs-12 col-md-6 col-xl-3">
                  Complexidade
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-plane"></i>
                    </span>
                    <select
                      className="form-control"
                      value={this.state.complexity}
                      onChange={(e) =>
                        this.setState({
                          complexity: e.target.value,
                        })
                      }
                    >
                      <option value="">Todas as Complexidades</option>
                      <option value="Base">Base</option>
                      <option value="Line">Line</option>
                    </select>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-xl-3">
                  Status MOSA
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-tasks"></i>
                    </span>
                    <select
                      className="form-control"
                      value={this.state.mosa_status}
                      onChange={(e) =>
                        this.setState({
                          mosa_status: e.target.value,
                        })
                      }
                    >
                      <option value={null}>Todos os Status</option>
                      <option>MOSA Solicitada pela Qualidade</option>
                      <option>MOSA Solicitada pela Manutenção</option>
                      <option>MOSA não Realizada</option>
                      <option>MOSA Realizada</option>
                      <option>MOSA Conferida</option>
                      <option>MOSA Lançada</option>
                    </select>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-xl-3">
                  Nível de Risco
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-exclamation-triangle"></i>
                    </span>
                    <select
                      className="form-control"
                      value={this.state.risk}
                      onChange={(e) =>
                        this.setState({
                          risk: e.target.value,
                        })
                      }
                    >
                      <option value={null}>Todos os Riscos</option>
                      <option>Risco Pequeno</option>
                      <option>Risco Moderado</option>
                      <option>Risco Alto</option>
                      <option>Risco Crítico</option>
                    </select>
                  </div>
                </div>

                <div className="col-xs-12 col-md-6 col-xl-3">
                  Processo
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-clipboard"></i>
                    </span>
                    <select
                      className="form-control"
                      value={this.state.process}
                      onChange={(e) =>
                        this.setState({ process: e.target.value })
                      }
                    >
                      <option value="">Todos os Processos</option>
                      {this.state.filter_processes.map(
                        this.renderProcessOptions
                      )}
                    </select>
                  </div>
                </div>

                <div className="col-xs-12">
                  <div className="row">
                    <div
                      className="col-xs-12 col-sm-6"
                      style={{ paddingTop: 25 }}
                    >
                      <span
                        className="label"
                        style={{
                          fontSize: 16,
                          width: '100%',
                          display: 'block',
                        }}
                      >
                        Quantidade de MOSA:{' '}
                        {this.state.total ? this.state.total : 0}
                      </span>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                      <div className="row">
                        <div
                          className="col-xs-12 col-sm-6"
                          style={{ padding: 5, paddingTop: 20 }}
                        >
                          <button
                            onClick={this.handleClear}
                            className="btn btn-danger btn-block"
                          >
                            Limpar Filtro
                          </button>
                        </div>
                        <div
                          className="col-xs-12 col-sm-6"
                          style={{ padding: 5, paddingTop: 20 }}
                        >
                          <button
                            onClick={this.handleSearch}
                            className="btn btn-success btn-block"
                          >
                            Buscar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row wrapper wrapper-content">
            <LoadingModal className="col-lg-12" visible={this.state.loading} />
            <div className="row animated fadeInDown">
              <div
                hidden={this.state.loading}
                className="col-lg-12 table-responsive ibox-content"
                style={{
                  border: 'rgba(0,0,0,0.05) solid 1px',
                  fontSize: 'smaller',
                  overflowX: 'auto',
                }}
              >
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Aeronave</th>
                      <th>Modelo da Aeronave</th>
                      <th>Registro</th>
                      <th>Inspeção</th>
                      <th>Código MOSA</th>
                      <th>Hangar (Pos)</th>
                      <th>Previsão de Execução</th>
                      <th>Início do Serviço</th>
                      <th>Fim do Serviço</th>
                      <th>Tipo</th>
                      <th>Processo</th>
                      <th>Status MOSA</th>
                      <th>Nível de Risco</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!this.state.searching ? (
                      <tr>
                        <td
                          colSpan={16}
                          style={{
                            textAlign: 'center',
                            fontSize: 20,
                            height: 100,
                            verticalAlign: 'middle',
                          }}
                        >
                          Por favor, insira valores para busca.
                        </td>
                      </tr>
                    ) : this.state.services.length > 0 ? (
                      this.state.services.map(this.renderServices)
                    ) : (
                      <tr>
                        <td
                          colSpan={16}
                          style={{
                            textAlign: 'center',
                            fontSize: 20,
                            height: 100,
                            verticalAlign: 'middle',
                          }}
                        >
                          Nenhum serviço encontrado para as condições impostas.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <RoundedPagination
                  count={this.state.pages}
                  onChange={this.handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
