import React, { useEffect, useState } from 'react';
import { Link, browserHistory } from 'react-router';
import AircraftService from '../../services/AircraftsService';
import AircraftModelsService from '../../services/AircraftModelsService';
import BaseService from '../../services/BasesService';
import Moment from 'moment';
import EngineeringService from '../../services/EngineeringService';
import LoadingModal from '../LoadingModal';
import Swal from 'sweetalert2';
import RoundedPagination from '../../components/common/RoundedPagination';
import './styles.css';
import Flights from '../../components/common/Flights';
import FileDownloadService from '../../services/FileService';

export default function FlightManagment(props) {
  const [state, setState] = useState({
    pages: 1,
    page: 1,
    base: null,
    bases: null,
    aircraft: {},
    filter_aircrafts: [],
    filter_bases: [],
    startDate: null,
    endDate: null,
    flights: [],
    status: '',
    loading: true,
    loadingReport: false,
  });
  const [selectedAircraft, setSelectedAircraft] = useState('');
  const [selectedAircraftModel, setSelectedAircraftModel] = useState('');
  const [status, setStatus] = useState('');
  const [selectedBase, setSelectedBase] = useState('');
  const [searchStart, setSearchStart] = useState('');
  const [searchEnd, setSearchEnd] = useState('');

  const verifyUserRole = () => {
    const role = JSON.parse(localStorage.getItem('@omni/fast_track/user')).role;
    if (role !== 'ADM' && role !== 'ADM - TI' && role !== 'ENGENHARIA')
      browserHistory.push('/dashboard');
  };

  const fetchData = async () => {
    window.scrollTo(0, 0);
    setState({ loading: true });
    let flight_date;
    if (props.params.date) {
      flight_date = Moment(props.params.date);
      flight_date = validationDate(flight_date.format());
    }
    const bases = await BaseService.getAll();
    const filter_aircrafts = await AircraftService.getAll();
    const filter_aircraft_models = await AircraftModelsService.getAll();
    const filter = {
      aircraft: selectedAircraft,
      aircraft_model: selectedAircraftModel,
    };
    const fetchedFlights = await EngineeringService.filter(filter, 1);

    setState((oldState) => ({
      ...oldState,
      filter_bases: bases ? bases : [],
      bases,
      filter_aircraft_models,
      flight_date: props.params.date ? flight_date : null,
      searching: true,
      filter_aircrafts: filter_aircrafts ? filter_aircrafts : [],
      loading: false,
      flights: fetchedFlights ? fetchedFlights.result : [],
      pages: fetchedFlights ? fetchedFlights.pages : 1,
    }));
  };

  useEffect(() => {
    verifyUserRole();
    fetchData();
  }, []);

  const fetchDataWhenDateChanges = async (date) => {
    let flight_date = Moment(props.params.date);
    flight_date = validationDate(flight_date.format());
    const bases = await BaseService.getAll();
    const filter_aircrafts = await AircraftService.getAll();
    setState((oldState) => ({
      ...oldState,
      bases,
      flight_date: props.params.date ? flight_date : null,
      searching: true,
      filter_aircrafts: filter_aircrafts ? filter_aircrafts : [],
    }));
    getServices();
  };

  useEffect(() => {
    if (props.params.date) {
      fetchDataWhenDateChanges(props.params.date);
    }
  }, [props]);

  useEffect(() => {
    if (state.page)
      getServices({
        aircraft: selectedAircraft,
        aircraft_model: selectedAircraftModel,
        base: selectedBase,
        startDate: searchStart,
        endDate: searchEnd,
        status,
      });
  }, [state.page]);

  const getServices = async (props) => {
    setState((oldState) => ({ ...oldState, loading: true }));
    const obj = await EngineeringService.filter(props, state.page);
    const flights = obj ? obj.result : [];
    const pages = obj ? obj.pages : 0;
    const total = obj ? obj.total : 0;
    setState((oldState) => ({
      ...oldState,
      flights,
      pages,
      total,
      loading: false,
    }));
  };

  const handlePagination = (event, value) => {
    setState((oldState) => ({ ...oldState, page: value }));
  };

  const validationDate = (date) => {
    return !date
      ? null
      : `${new Date(date).getFullYear()}-${`${
          new Date(date).getMonth() + 1
        }`.padStart(2, 0)}-${`${new Date(date).getDate()}`.padStart(
          2,
          0
        )}T${`${new Date(date).getHours()}`.padStart(2, 0)}:${`${new Date(
          date
        ).getMinutes()}`.padStart(2, 0)}`;
  };

  const handleClear = async () => {
    setState((oldState) => ({
      ...oldState,
      flight_date: null,
      page: 1,
    }));
    setSelectedAircraft('');
    setSelectedAircraftModel('');
    setSearchStart('');
    setSearchEnd('');
    setSelectedBase('');
    setStatus('');
    getServices();
    setState((oldState) => ({ ...oldState, searching: true }));
  };

  const renderBasesOptions = (base) => (
    <option key={base._id} value={base._id}>
      {`${base.name}`} ({`${base.code}`}){' '}
    </option>
  );

  const handleClick = async () => {
    const confirmation = (
      await Swal.fire({
        title: 'Aviso',
        text: 'Deseja exportar os dados para excel?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Confirmar',
      })
    ).value;

    if (confirmation) {
      const data = {
        aircraft: selectedAircraft,
        aircraft_model: selectedAircraftModel,
        base: selectedBase,
        search_start: searchStart,
        search_end: searchEnd,
        status,
      };
      setState((oldState) => ({ ...oldState, loadingReport: true }));
      await FileDownloadService.fetchHUMSReport(data);
      setTimeout(() => {
        setState((oldState) => ({ ...oldState, loadingReport: false }));
      }, 20000); // Wait 20 seconds
    }
  };

  const handleSearch = async () => {
    const data = {
      aircraft: selectedAircraft,
      aircraft_model: selectedAircraftModel,
      base: selectedBase,
      startDate: searchStart,
      endDate: searchEnd,
      status,
    };

    setState((oldState) => ({ ...oldState, page: 1 }));

    getServices(data);
  };

  return (
    <div>
      <LoadingModal className='col-lg-12' visible={!state.bases} />
      {state.bases && (
        <>
          <div
            className='wrapper wrapper-content animated fadeInDown'
            style={{ paddingBottom: '0' }}
          >
            <div className='row wrapper border-bottom white-bg page-heading'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-lg-8'>
                    <h2>Gerenciamento de Download HUMS</h2>
                    <ol className='breadcrumb'>
                      <li>
                        <Link to='/dashboard'>Home</Link>
                      </li>
                      <li className='active'>
                        <strong>Gerenciamento de Download HUMS</strong>
                      </li>
                    </ol>
                  </div>
                  <div className='col-lg-4'>
                    <div
                      className='col-md-6'
                      style={{
                        paddingTop: 18,
                        paddingLeft: 0,
                        paddingRight: '8px',
                      }}
                    />
                    <div
                      className='col-md-6'
                      style={{
                        paddingTop: 18,
                        paddingLeft: 0,
                        paddingRight: '8px',
                      }}
                    >
                      <button
                        className='btn btn-primary btn-block'
                        onClick={handleClick}
                        disabled={state.loadingReport}
                      >
                        <i className='fa fa-file-excel'></i>
                        {' '}
                        {state.loadingReport ? 'Processando...' : 'Gerar Heat Map'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-12' style={{ paddingTop: 20 }}>
                <div className='row'>
                  <div className='col-lg-6'>
                    Base
                    <div className='input-group m-b'>
                      <span className='input-group-addon'>
                        <i className='fa fa-home'></i>
                      </span>
                      <select
                        className='form-control'
                        value={selectedBase}
                        onChange={(e) => setSelectedBase(e.target.value)}
                      >
                        <option value=''>Todas as bases</option>
                        {state.filter_bases.map(renderBasesOptions)}
                      </select>
                    </div>
                  </div>

                  <div className='col-lg-6'>
                    Modelo de Aeronave
                    <div className='input-group m-b'>
                      <span className='input-group-addon'>
                        <i className='fa fa-plane'></i>
                      </span>
                      <select
                        className='form-control'
                        value={selectedAircraftModel}
                        onChange={(e) =>
                          setSelectedAircraftModel(e.target.value)
                        }
                      >
                        <option value=''>Todos os modelos de aeronave</option>
                        {state.filter_aircraft_models &&
                          state.filter_aircraft_models.map((aircraft_model) => (
                            <option
                              key={aircraft_model._id}
                              value={aircraft_model._id}
                            >{`${aircraft_model.manufacturer} - ${aircraft_model.model}`}</option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className='col-lg-12'>
                    Aeronave
                    <div className='input-group m-b'>
                      <span className='input-group-addon'>
                        <i className='fa fa-plane'></i>
                      </span>
                      <select
                        className='form-control'
                        value={selectedAircraft}
                        onChange={(e) => setSelectedAircraft(e.target.value)}
                      >
                        <option value=''>Todas as aeronave</option>
                        {state.filter_aircrafts &&
                          state.filter_aircrafts.map((aircraft) => (
                            <option value={aircraft._id} key={aircraft._id}>
                              {`${aircraft.prefix}`} (
                              {`${aircraft._model.manufacturer}`} -{' '}
                              {`${aircraft._model.model}`})
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className='col-lg-6'>
                    Início do período
                    <div className='input-group m-b'>
                      <span className='input-group-addon'>
                        <i className='fa fa-calendar'></i>
                      </span>
                      <input
                        type='date'
                        className='form-control'
                        value={searchStart}
                        onChange={(e) => setSearchStart(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    Fim do período
                    <div className='input-group m-b'>
                      <span className='input-group-addon'>
                        <i className='fa fa-calendar'></i>
                      </span>
                      <input
                        type='date'
                        className='form-control'
                        value={searchEnd}
                        onChange={(e) => setSearchEnd(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    <div
                      className='col-md-6'
                      style={{
                        paddingTop: 18,
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                    />
                    <div
                      className='col-md-3'
                      style={{
                        paddingTop: 18,
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                    >
                      <button
                        onClick={handleClear}
                        className='btn btn-danger btn-block'
                      >
                        Limpar Filtro
                      </button>
                    </div>
                    <div
                      className='col-md-3'
                      style={{
                        paddingTop: 18,
                        paddingLeft: '8px',
                        paddingRight: 0,
                      }}
                    >
                      <button
                        onClick={handleSearch}
                        className='btn btn-success btn-block'
                      >
                        Buscar
                      </button>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='wrapper wrapper-content'>
            <div className='row animated fadeInDown'>
              <div
                className='col-lg-12 table-responsive ibox-content'
                style={{
                  paddingLeft: '40px',
                  border: 'rgba(0,0,0,0.05) solid 1px',
                  paddingRight: '40px',
                  paddingBottom: '20px',
                  paddingTop: '20px',
                  overflowX: 'auto',
                }}
              >
                {state.loading ? (
                  <div className='col-lg-12' style={{ height: '100px' }}>
                    <div className='row'>
                      <LoadingModal visible={state.loading} />
                    </div>
                  </div>
                ) : (
                  <>
                    <table className='table table-striped'>
                      <thead>
                        <tr>
                          <th>Modelo da Aeronave</th>
                          <th>Aeronave</th>
                          <th>Base</th>
                          <th>Data dos Voos</th>
                          {/* <th>Preenchimento</th> */}
                          <th>Downloads x Voos</th>
                          <th>Razão Downloads x Voos</th>
                        </tr>
                      </thead>
                      <tbody>
                        {state.flights ? (
                          state.flights.map(Flights)
                        ) : (
                          <p>Nenhum registro para esta aeronave</p>
                        )}
                      </tbody>
                    </table>
                    {state.flights.length > 0 ? (
                      ''
                    ) : (
                      <center>Nenhum registro para esta aeronave.</center>
                    )}
                    <RoundedPagination
                      page={state.page}
                      count={state.pages}
                      onChange={handlePagination}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
