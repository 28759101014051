import React, { useEffect, useState } from 'react';
import { Link, browserHistory } from 'react-router';
import AircraftService from '../../services/AircraftsService';
import BaseService from '../../services/BasesService';
import FlightsReportService from '../../services/FlightsReportService';
import LoadingModal from '../LoadingModal';
import { toast } from 'react-toastify';
import './styles.css';
import moment from 'moment';
import Swal from 'sweetalert2';
import FlightModal from './NewFlightModal';
import AlertModal from './AlertModal';
import {
  FiCheckCircle as CheckIcon,
  FiXCircle as UncheckIcon,
} from 'react-icons/fi';
import EngineeringService from '../../services/EngineeringService';
import FlightStepService from '../../services/FlightStepService';

export default function AddFlight(props) {
  const [state, setState] = useState({
    base: null,
    aircraft: null,
    filter_aircrafts: [],
    filter_bases: [],
    status: '',
    downloads: 0,
    flights: [],
    flight: 'new',
    aircraft_id: null,
    status_id: null,
    loading: true,
    step: 'new',
    alert: 'new',
    steps: [],
    openFlightModal: false,
    openAlertModal: false,
    flights: [],
  });
  const [base, setBase] = useState(null);
  const [flightDate, setFlightDate] = useState('NaN-NaN-NaNTNaN:NaN');
  const [flightsCount, setFlightsCount] = useState(0);
  const [flightsFetched, setFlightsFetched] = useState(0);
  const [note, setNote] = useState('');
  const [downloads, setDownloads] = useState([]);

  const verifyUserRole = () => {
    const role = JSON.parse(localStorage.getItem('@omni/fast_track/user')).role;
    if (role !== 'ADM' && role !== 'ADM - TI' && role !== 'ENGENHARIA')
      browserHistory.push('/dashboard');
  };

  const getBase = (aircraft) => {
    return (
      (aircraft &&
        aircraft.movimentation &&
        aircraft.movimentation[aircraft.movimentation.length - 1] &&
        aircraft.movimentation[aircraft.movimentation.length - 1]._base) ||
      null
    );
  };

  const fetchFlights = async () => {};

  const fetchData = async (flightId) => {
    window.scrollTo(0, 0);
    setState((oldState) => ({ ...oldState, loading: true }));
    const bases = await BaseService.getAll();
    const filter_aircrafts = await AircraftService.getAll();
    const {
      aircraft,
      downloads,
      flightsCount: flights,
      flightsFetched,
      date,
      note,
    } = await EngineeringService.getById(flightId);
    const base = getBase(aircraft);
    const flight_date = moment(date).utc().endOf('day') || null;
    const steps = await FlightStepService.getAll(flightId);

    if (aircraft || date) {
      setFlightDate(flight_date.format('YYYY-MM-DD'));
      setBase(base);
      setDownloads(downloads || []);
      setFlightsCount(flights);
      setFlightsFetched(flightsFetched);
      setNote(note);

      // Fetch flights to the list
      const start = new Date(date);
      start.setUTCHours(0, 0, 0, 0);
      const end = new Date(date);
      end.setUTCHours(23, 59, 59, 999);
      const filter = {
        aircraft: aircraft._id,
        search_start: start,
        search_end: end,
      };

      const flightsFetched = await FlightsReportService.filter(filter, 1, 1);

      setState((oldState) => ({
        ...oldState,
        filter_bases: bases ? bases : [],
        base,
        aircraft,
        filter_aircrafts: filter_aircrafts ? filter_aircrafts : [],
        aircraft_id: aircraft._id,
        flightsCount: flights,
        flightsFetched,
        steps,
        loading: false,
        flights: flightsFetched.result || [],
      }));
    }
  };

  const downloadsCount = downloads.filter(
    ({ download_has_done }) => download_has_done
  ).length;

  useEffect(() => {
    verifyUserRole();
    if (props.params.id) fetchData(props.params.id);
  }, [props.params.id]);

  const handleClick = async () => {
    const confirmation = (
      await Swal.fire({
        title: 'Confirmação',
        text: 'Você tem certeza que deseja atualizar este registro de voos e downloads?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })
    ).value;

    if (confirmation) {
      await consolidateData();
    }
  };

  const consolidateData = async () => {
    const flightData = {
      note: note,
      flightsCount,
      downloadsCount,
      isEdited: state.flightsCount !== flightsCount,
    };

    await EngineeringService.update(flightData, props.params.id);
    toast.success('Informações de voos e downloads atualizadas com sucesso.');
    browserHistory.goBack();
  };

  const handleCloseFlightModal = async () => {
    const steps = await FlightStepService.getAll(props.params.id);
    setState((oldState) => ({ ...oldState, openFlightModal: false, steps }));
  };

  const handleCloseAlertModal = async () => {
    const steps = await FlightStepService.getAll(props.params.id);
    setState((oldState) => ({ ...oldState, openAlertModal: false, steps }));
  };

  const prettyBaseName = (aircraft) => {
    if (
      aircraft &&
      aircraft.movimentation &&
      aircraft.movimentation.length > 0
    ) {
      const now = new Date();
      const baseSelected = aircraft.movimentation.find((item) =>
        item.endOperation
          ? new Date(item.startOperation).getTime() <= now.getTime() &&
            new Date(item.endOperation).getTime() >= now.getTime()
          : new Date(item.startService).getTime() <= now.getTime()
      );
      if (baseSelected && baseSelected._base) {
        return baseSelected._base.name;
      }
    }
    return 'Não definida';
  };

  const styles = {
    spanWarning: {
      color: 'red',
    },
  };

  const formatTime = (time) => {
    const newTime = time.split(':');
    return `${newTime[0]}h:${newTime[1]}m`;
  };

  return (
    <>
      {state.loading ? (
        <div className="col-lg-12">
          <div className="row">
            <LoadingModal visible={state.loading} />
          </div>
        </div>
      ) : (
        <div>
          <FlightModal
            open={state.openFlightModal}
            handleClose={handleCloseFlightModal}
            step={state.step}
            flight={props.params.id}
          />
          <AlertModal
            open={state.openAlertModal}
            handleClose={handleCloseAlertModal}
            alert={state.alert}
            step={state.step}
          />
          <div className="wrapper wrapper-content animated fadeInDown">
            <div className="row wrapper border-bottom white-bg page-heading">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-8">
                    <h2>Registro da aeronave {state.aircraft.prefix}</h2>
                    <ol className="breadcrumb">
                      <li>
                        <Link to="/engineering/aircrafts">Engenharia</Link>
                      </li>
                      <li className="active">
                        <strong>Registro de aeronave</strong>
                      </li>
                    </ol>
                  </div>
                  <div className="col-lg-4"></div>
                </div>
              </div>
              <h4
                className="col-lg-12"
                style={{
                  borderBottom: '1.5px solid rgba(0,0,0,0.07)',
                  textAlign: 'center',
                  marginTop: '20px',
                  marginBottom: '20px',
                  padding: '10px',
                  color: 'red',
                  fontSize: '18px',
                }}
              >
                DADOS DO REGISTRO
              </h4>
              <div className="col-lg-12" style={{ paddingTop: 10 }}>
                <div className="row">
                  <div className="col-lg-12">
                    <h4>
                      <b>Aeronave</b>
                    </h4>
                    <div className="input-group m-b">
                      <span className="input-group-addon">
                        <i className="fa fa-plane"></i>
                      </span>
                      <input
                        className="form-control"
                        value={
                          state.aircraft.prefix
                            ? `${state.aircraft.prefix} (${state.aircraft._model.manufacturer} - ${state.aircraft._model.model})`
                            : 'Não definido'
                        }
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <h4>
                      <b>Base do registro</b>
                    </h4>
                    <div className="input-group m-b">
                      <span className="input-group-addon">
                        <i className="fa fa-home"></i>
                      </span>
                      <input
                        className="form-control"
                        value={prettyBaseName(state.aircraft)}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <h4>
                      <b>Data do registro</b>
                    </h4>
                    <div className="input-group m-b">
                      <span className="input-group-addon">
                        <i className="fa fa-calendar"></i>
                      </span>
                      <input
                        type="date"
                        className="form-control"
                        value={flightDate}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <h4>
                      <b>Contagem de downloads</b>
                    </h4>
                    <div className="input-group m-b">
                      <span className="input-group-addon">
                        <i className="fa fa-download"></i>
                      </span>
                      <input
                        disabled
                        className="form-control"
                        value={downloadsCount}
                        type="number"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <h4>
                      <b>Contagem de voos</b>
                    </h4>
                    <div className="input-group m-b">
                      <span className="input-group-addon">
                        <i className="fa fa-plane"></i>
                      </span>
                      <input
                        className="form-control"
                        value={flightsCount}
                        onChange={(event) =>
                          setFlightsCount(+event.target.value)
                        }
                        type="number"
                      />
                    </div>
                    <span style={styles.flightsWarning}>
                      Obs.: Para esse registro, foram detectados{' '}
                      <b>{flightsFetched}</b> voos via api do SigTrip.
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    Observação
                    <div className="input-group m-b">
                      <span className="input-group-addon">
                        <i className="fa fa-file"></i>
                      </span>
                      <textarea
                        className="form-control"
                        rows="3"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        style={{
                          maxHeight: '240px',
                          resize: 'none',
                          outline: 'none',
                        }}
                      ></textarea>
                    </div>
                    <h4
                      className="col-lg-12"
                      style={{
                        borderBottom: '1.5px solid rgba(0,0,0,0.07)',
                        textAlign: 'center',
                        marginTop: '20px',
                        marginBottom: '20px',
                        padding: '10px',
                        color: 'red',
                        fontSize: '18px',
                      }}
                    >
                      REGISTROS
                    </h4>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Local de partida (Horário)</th>
                          <th>Local de chegada (Horário)</th>
                          <th>Intervalo entre decolagem e pouso</th>
                          <th>Profissional (cargo)</th>
                          <th>Número de inscrição do profissional</th>
                          <th>Combustível inicial</th>
                          <th>Combustível final</th>
                        </tr>
                      </thead>
                      <tbody>
                        {state.flights.length === 0 ? (
                          <tr>
                            <td colSpan="7" style={{ textAlign: 'center' }}>
                              Nenhum relatório encontrado
                            </td>
                          </tr>
                        ) : (
                          state.flights.map((register, index) => (
                            <tr
                              onClick={() => {
                                browserHistory.push(
                                  `/flights_report/${register.flight_number}`
                                );
                              }}
                              style={{ cursor: 'pointer' }}
                              key={index}
                            >
                              <td>
                                {register.departure_location} (
                                {register.takeoff_time})
                              </td>
                              <td>
                                {register.arrival_location} (
                                {register.landing_time})
                              </td>
                              <td>{formatTime(register.takeoff_land_time)}</td>
                              <td>
                                {register.staff} ({register.duty})
                              </td>
                              <td>{register.inscription}</td>
                              <td>{register.fuel_start}</td>
                              <td>{register.fuel_end}</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                    <h4
                      className="col-lg-12"
                      style={{
                        borderBottom: '1.5px solid rgba(0,0,0,0.07)',
                        textAlign: 'center',
                        marginTop: '20px',
                        marginBottom: '20px',
                        padding: '10px',
                        color: 'red',
                        fontSize: '18px',
                      }}
                    >
                      REGISTROS DE DOWNLOADS
                    </h4>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Matrícula do colaborador</th>
                          <th>Horário inicial do vôo</th>
                          <th>Horário final do vôo</th>
                          <th>Realizado</th>
                          <th>Motivo (se não)</th>
                          <th>Dados válidos</th>
                          <th>Motivo (se não)</th>
                          <th>Observações gerais</th>
                        </tr>
                      </thead>
                      <tbody>
                        {downloads.length === 0 ? (
                          <tr>
                            <td colSpan="7" style={{ textAlign: 'center' }}>
                              Nenhum registro encontrado
                            </td>
                          </tr>
                        ) : (
                          downloads.map((record, index) => (
                            <tr key={index}>
                              <td>{record.registration}</td>
                              <td>{record.flight_start}</td>
                              <td>{record.flight_end}</td>
                              <td>
                                {record.download_has_done ? (
                                  <CheckIcon color="#28a745" />
                                ) : (
                                  <UncheckIcon color="#dc3545" />
                                )}
                              </td>
                              <td>{record.download_reason}</td>
                              <td>
                                {record.generated_data_was_valid ? (
                                  <CheckIcon color="#28a745" />
                                ) : (
                                  <UncheckIcon color="#dc3545" />
                                )}
                              </td>
                              <td>{record.generated_reason}</td>
                              <td>{record.observation}</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-lg-12" style={{ marginBottom: 20 }} />
                  <div
                    className="col-lg-12"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                    }}
                  >
                    <div
                      style={{
                        paddingTop: 18,
                        paddingLeft: 0,
                        paddingRight: '8px',
                      }}
                    >
                      <Link onClick={browserHistory.goBack}>
                        <button className="btn btn-info btn-block">
                          Voltar
                        </button>
                      </Link>
                    </div>
                    <div
                      style={{
                        paddingTop: 18,
                        paddingRight: 0,
                        paddingLeft: '8px',
                      }}
                    >
                      <button
                        onClick={handleClick}
                        className="btn btn-primary btn-block"
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
