import React, { Component } from "react";
import { Link, browserHistory } from "react-router";
import HangarsService from "../services/HangarsService";
import AddHangar from "./AddHangar";
import Hangar from "./Hangar";
import renderHTML from "react-render-html";
import RoundedPagination from '../components/common/RoundedPagination';

export default class Hangars extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hangars: [],
      filter: "",
      page: props.params.page || 1,
      pages: 0,
      selectedHangar: ""
    };
    const role = JSON.parse(localStorage.getItem("@omni/fast_track/user")).role;
    if (role === "PCP" || role === "COORDENADOR" || role === "VISUALIZADOR")
      browserHistory.push("/dashboard");
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.filter();
  }

  async componentWillReceiveProps(nextProps) {
    this.setState({ page: nextProps.params.page || 1 }, () => {
      this.filter();
    });
  }

  getHangars = async () => {
    this.setState({ loading: true });
    const obj = await HangarsService.get(this.state.page);
    const hangars = obj ? obj.result : [];
    const pages = obj ? obj.pages : 0;
    this.setState({ hangars, pages, loading: false });
  };

  filter = async () => {
    if (this.state.filter.length > 0) {
      this.setState({ loading: true });
      const obj = await HangarsService.get(1, this.state.filter);
      const hangars = obj ? obj.result : [];
      this.setState({ hangars, loading: false });
    } else this.getHangars();
  };

  renderHangar = hangar => {
    return (
      <tr
        id="identify"
        // key={hangar._id}
        data-toggle="modal"
        data-target="#editModal"
        onClick={() => this.setState({ selectedHangar: hangar._id })}
        style={{ cursor: "pointer" }}
      >
        <td style={{ padding: 20, paddingTop: 30 }}>
          <i
            className={`fa fa-${hangar.active ? "check" : "times"} text-${
              hangar.active ? "success" : "danger"
              }`}
          />
        </td>
        <td style={{ padding: 20, paddingTop: 30, color: '#337ab7' }}>{hangar.name}</td>
        <td style={{ padding: 20, paddingTop: 30 }}>{hangar.code}</td>
        <td style={{ padding: 20, paddingTop: 30 }}>{hangar.base ? hangar.base.name : "Não informado"}</td>
        <td style={{ maxWidth: 50 }}>{renderHTML(hangar.layout)}</td>
      </tr>
    );
  };

  handleFilterSubmit = (e) => {
    e.preventDefault();
    this.handlePagination(e, 1);
  }

  handlePagination = (_event, value) => {
    this.setState({ page: value }, () => {
      browserHistory.push(`/hangars/${this.state.page}`);
    });
  }

  render() {
    return (
      <div className="wrapper wrapper-content animated fadeInDown">
        <AddHangar refresh={() => this.getHangars(this.props)} />
        <Hangar
          refresh={() => this.getHangars(this.props)}
          selected={this.state.selectedHangar}
        />
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-6">
                <h2>Hangares</h2>
                <ol className="breadcrumb">
                  <li>
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li>
                    <Link>Configurações</Link>
                  </li>
                  <li className="active">
                    <strong>Hangares</strong>
                  </li>
                </ol>
              </div>
              <div className="col-lg-6">
                <div className="pull-right" style={{ paddingTop: 10 }}>
                  <button
                    className="btn btn-success"
                    data-toggle="modal"
                    data-target="#addModal"
                  >
                    Adicionar
                  </button>
                </div>
                <form
                  onSubmit={this.handleFilterSubmit}
                >
                  <div
                    className="input-group pull-right"
                    style={{ marginTop: 15 }}
                  >
                    <input
                      type="text"
                      placeholder="Procurar Hangar"
                      className="input-sm form-control"
                      value={this.state.filter}
                      onChange={e => this.setState({ filter: e.target.value })}
                      style={{ backgroundColor: "rgba(255, 255, 255, 0.835)" }}
                    />
                    <span className="input-group-btn">
                      <button type="submit" className="btn btn-sm btn-success">
                        <i className="fa fa-search"></i>
                      </button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper wrapper-content">
          <div className="row animated fadeInDown">
            <div className="ibox-content">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Ativo</th>
                      <th>Nome</th>
                      <th>Código</th>
                      <th>Base</th>
                      <th>Layout</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.loading ? (
                      <tr>
                        <th colSpan={6}>
                          <div className="spinner spinner-lg" />
                        </th>
                      </tr>
                    ) : (
                        this.state.hangars.map(this.renderHangar)
                      )}
                  </tbody>
                </table>
              </div>

              <RoundedPagination onChange={this.handlePagination}
                                 page={this.state.page}
                                 count={this.state.pages} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
