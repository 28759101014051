import React from 'react';

class ComponentSpinner extends React.Component {
    render() {
        return (
            <div style={{ height: '10em', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ marginTop: '240px', height: 80 }}>
                    <div className="loader2">

                    </div>
                </div>
            </div>
        )
    }
}

export default ComponentSpinner