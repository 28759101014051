import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import ArchiveService from '../services/ImportArchiveService';
import PcpServices from '../services/PcpService';
import Management from '../services/ManagementService';
import { toast } from 'react-toastify';
import * as $ from 'jquery';
import Moment from 'moment';
import LoadingModal from './LoadingModal';
import Swal from 'sweetalert2';

export default class EditPcpImport extends Component {

  constructor(props) {
    super(props);
    this.user = JSON.parse(localStorage.getItem('@omni/fast_track/user'));
    this.state = {
      active: true,
      number_services: 0,
      number_services_fast: 0,
      date_uploaded: null,
      original_name: '',
      uploaded_by: null,
      status: null,
      services: [],
      wrongServices: [],
      initial_status: null,
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0)
    this.setState({ loading: true });
    const doc = await ArchiveService.getById(this.props.params.id)
    await this.setState({ ...doc })
    await this.setState({ initial_status: doc.status })

    const services = await PcpServices.getByArchiveId(this.props.params.id)
    await this.setState({ services })
    const wrongServices = await PcpServices.getErrorsByArchiveId(this.props.params.id)
    await this.setState({ wrongServices })

    await this.setState({ number_services_fast: services.length + wrongServices.length });
    await this.setState({ loading: false });
  }

  deleteService = async (id) => {
    const confirmation = (await Swal.fire({
      title: 'Confirmação',
      text: 'Você tem certeza que deseja deletar este serviço?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    })).value;
    if (confirmation) {
      const result = await PcpServices.delete(id)
      const services = await PcpServices.getByArchiveId(this.props.params.id)
      await this.setState({ services })
      await this.setState({ number_services_fast: (this.state.number_services_fast - 1) })
      toast.success('Serviço apagado com sucesso!')
    }
  }

  goToService = (service) => {
    if (this.user.role !== 'PCP')
      browserHistory.push(`/management/${service._id}`)
  }

  renderServices = (service) => {
    return (
      <tr
        id="identify"
        key={service._id}
        style={{ cursor: 'pointer' }}
      >
        <td onClick={() => this.goToService(service)}><a>{service.aircraft && service.aircraft.prefix}</a></td>
        <td onClick={() => this.goToService(service)}>{service.aircraft && service.aircraft._model.manufacturer} - {service.aircraft && service.aircraft._model.model}</td>
        <td onClick={() => this.goToService(service)}>{service.inspection}</td>
        <td onClick={() => this.goToService(service)}>{service.register}</td>
        <td onClick={() => this.goToService(service)}>{service.complexity}</td>
        <td onClick={() => this.goToService(service)}>{service && service.execute_prediction ? Moment(new Date(service.execute_prediction)).locale('pt-BR').format('DD/MM/YYYY HH:mm') : 'Sem data'}</td>
        {
          service.status === 'Cancelado' ? (
            <td onClick={() => this.goToService(service)} style={{ color: 'red' }}>{service.status}</td>
          ) :
            <td onClick={() => this.goToService(service)}>{service.status}</td>
        }
        <td>
          {
            service.isMosa ?
              <div onClick={async () => {
                if (this.user.role === 'PCP') return;
                let newServices = []
                await this.state.services.map((element) => {
                  if (service._id == element._id) {
                    element.isMosa = false
                  }
                  newServices.push(JSON.parse(JSON.stringify(element)))
                })
                this.setState({ services: newServices })
              }} className='btn btn-danger' style={{ fontWeight: 900 }}>M</div>
              :
              <div onClick={async () => {
                if (this.user.role === 'PCP') return;
                let newServices = []
                await this.state.services.map((element) => {
                  if (service._id == element._id) {
                    element.isMosa = true
                  }
                  newServices.push(JSON.parse(JSON.stringify(element)))
                })
                this.setState({ services: newServices })
              }} className='btn' style={{ border: '1px solid rgba(0,0,0,0.1)' }}>&nbsp;&nbsp;&nbsp;&nbsp;</div>
          }
        </td>
      </tr>
    );
  }

  renderWrongServices = (service) => {
    return (
      <tr
        id="identify"
        key={service._id}
        style={{ cursor: 'pointer' }}
      >
        <td onClick={() => this.goToService(service)}><a>{service.aircraft && service.aircraft.prefix}</a></td>
        <td onClick={() => this.goToService(service)}>{service.aircraft && service.aircraft._model.manufacturer} - {service.aircraft && service.aircraft._model.model}</td>
        <td onClick={() => this.goToService(service)}>{service.inspection}</td>
        <td onClick={() => this.goToService(service)}>{service.register}</td>
        <td onClick={() => this.goToService(service)}>{service.complexity}</td>
        <td onClick={() => this.goToService(service)}>{service && service.execute_prediction ? Moment(new Date(service.execute_prediction)).locale('pt-BR').format('DD/MM/YYYY HH:mm') : 'Sem data'}</td>
        <td onClick={() => this.goToService(service)} style={{ color: 'red' }}>{service.error_reason}</td>
      </tr>
    );
  }

  handleUpdate = async () => {
    const confirmation = (await Swal.fire({
      title: 'Confirmação',
      text: 'Você tem certeza que deseja salvar as alterações a este arquivo?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    })).value;
    if (confirmation) {
      await this.state.services.map(async (element) => {
        if (this.state.status !== 'Cancelado') {
          if (element.isMosa) {
            element.mosa_status = 'MOSA Solicitada pela Qualidade'
            if (element.execute_prediction)
              element.code = Moment(new Date(element.execute_prediction)).locale('pt-BR').format('YYYYMMDD') + '-' + Math.floor(Math.random() * 65536)

            if (this.state.status !== 'Importado') {
              element.status = 'Conferido'
            } else {
              element.status = 'Importado'
            }
          } else {
            element.code = null
            if (this.state.status !== 'Importado') {
              element.status = 'Conferido'
            } else {
              element.status = 'Importado'
            }
          }
        } else {
          element.status = 'Cancelado'
        }

        await Management.edit(element._id, element).then((result) => { })
      })

      const archiveUpdated = {
        ...this.state,
        services: this.state.services ? this.state.services.map(el => el._id) : [],
      };
      await ArchiveService.edit(this.props.params.id, archiveUpdated).then(async function (res) {
        browserHistory.push('/pcp-archives')
        toast.success('Documento atualizado com sucesso!')
      })
    }
  }

  render() {
    return (
      <div className="wrapper wrapper-content">
        <LoadingModal visible={this.state.loading} />
        <div
          className="row wrapper border-bottom white-bg page-heading animated bounceInRight"
          style={{ margin: 'auto', padding: '40px' }}
        >
          <h2>Editar Importação de PCP</h2><br />
          <div className="row">
            <div className="col-lg-12 row" style={{ background: 'rgba(0,0,0,0.04)', paddingLeft: '40px', paddingRight: '40px', paddingBottom: '20px', paddingTop: '20px', margin: '20px', textAlign: 'center' }}>
              <div className="col-lg-4">
                <h4>Nome do Arquivo: {this.state.name}</h4>
              </div>
              <div className="col-lg-4">
                <h4>Código: {this.state._id}</h4>
              </div>
              <div className="col-lg-4">
                <h4>Número de Serviços: {this.state.number_services_fast}</h4>
              </div>
              <div className="col-lg-4">
                <h4>Upload feito por: {this.state.uploaded_by ? this.state.uploaded_by.name : 'Usuário não encontrado'}</h4>
              </div>
              <div className="col-lg-4">
                <h4>Upload feito em: {Moment(new Date(this.state.date_uploaded)).locale('pt-BR').format('DD/MM/YYYY HH:mm')}</h4>
              </div>
              <div className="col-lg-4">
                <h4>Nome Original: {this.state.original_name}</h4>
              </div>
            </div>
            <div className="col-lg-12 row" style={{ paddingLeft: '40px', paddingRight: '40px', paddingBottom: '20px', paddingTop: '20px', margin: '20px' }}>
              <div className="col-lg-6">
                <h4><b>Nome do Arquivo</b></h4>
                <div className="input-group m-b">
                  <span className="input-group-addon">
                    <i className="fa fa-file"></i>
                  </span>
                  <input
                    type="text"
                    placeholder="Nome do Arquivo"
                    className="form-control"
                    value={this.state.name}
                    onChange={e => this.setState({ name: e.target.value })}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <h4><b>Status do Arquivo</b></h4>
                <div className="input-group m-b">
                  <span className="input-group-addon">
                    <i className="fa fa-check"></i>
                  </span>
                  <select
                    className="form-control"
                    value={this.state.status}
                    disabled={this.state.initial_status === 'Conferido pela Qualidade' && (JSON.parse(localStorage.getItem('@omni/fast_track/user')).role === 'PCP')}
                    onChange={e => this.setState({ status: e.target.value })}
                  >
                    <option value="" disabled selected>Selecione o Status</option>
                    <option>Importado</option>
                    {
                      (this.state.initial_status === 'Conferido pela Qualidade' || (JSON.parse(localStorage.getItem('@omni/fast_track/user')).role !== 'PCP')) ?
                        <option>Conferido pela Qualidade</option> : null
                    }
                    <option>Cancelado</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-12">
                <h4><b>Observações</b></h4>
                <textarea
                  type="text"
                  rows="6"
                  placeholder="Observações"
                  className="form-control"
                  value={this.state.observation}
                  onChange={e => this.setState({ observation: e.target.value })}
                ></textarea>
              </div>
            </div>
            <br />
            <div className="col-lg-12 table-responsive ibox-content" style={{ paddingLeft: '40px', border: 'rgba(0,0,0,0.05) solid 1px', paddingRight: '40px', paddingBottom: '20px', paddingTop: '20px' }}>
              <h3>Serviços importados no documento ({this.state.services.length})</h3>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Aeronave</th>
                    <th>Modelo da Aeronave</th>
                    <th>Inspeção</th>
                    <th>Registro</th>
                    <th>Complexidade</th>
                    <th>Previsão de Execução</th>
                    <th>Status</th>
                    <th>MOSA</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.services.map(this.renderServices)}
                </tbody>
              </table>
            </div>
            <br />
            {this.state.wrongServices && this.state.wrongServices.length > 0 &&
              <div className="col-lg-12 table-responsive ibox-content" style={{ paddingLeft: '40px', border: 'rgba(0,0,0,0.05) solid 1px', paddingRight: '40px', paddingBottom: '20px', paddingTop: '20px' }}>
                <h3>Serviços com <b style={{ color: 'red' }}>ERRO</b> ({this.state.wrongServices && this.state.wrongServices.length})</h3>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Aeronave</th>
                      <th>Modelo da Aeronave</th>
                      <th>Inspeção</th>
                      <th>Registro</th>
                      <th>Complexidade</th>
                      <th>Previsão de Execução</th>
                      <th>Erro</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.wrongServices && this.state.wrongServices.map(this.renderWrongServices)}
                  </tbody>
                </table>
              </div>
            }
            <div className=" col-lg-12 row" style={{ textAlign: 'center', marginTop: '20px' }} >
              <div className="col-lg-12">
                <button
                  className="btn btn-success"
                  onClick={this.handleUpdate}
                  style={{ margin: '2px' }}
                >Salvar</button>
                <button
                  className="btn btn-danger"
                  style={{ margin: '2px' }}
                  onClick={async () => {
                    const confirmation = (await Swal.fire({
                      title: 'Confirmação',
                      text: 'Você tem certeza que deseja cancelar?',
                      type: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Sim',
                      cancelButtonText: 'Não'
                    })).value;
                    if (confirmation) {
                      browserHistory.push(`/pcp-archives/`)
                    }
                  }}
                >Cancelar</button>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
