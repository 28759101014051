import React, { Component } from "react";
import { Link, browserHistory } from "react-router";
import Moment from 'moment';
import { TextField, Select, MenuItem } from "@material-ui/core";

import UsersService from "../../../services/UsersService";
import MosaService from "../../../services/MosaService";
import LoadingModal from "../../LoadingModal";
import RoundedPagination from '../../../components/common/RoundedPagination';

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      pages: 0,
      page: 1,
      base: "",
      name: "",
      auditors: [],
      auditor: '',
      services: [],
      start_service: '',
      end_service: '',
      start_mosa: '',
      mosa_status: ''
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    const auditors = await UsersService.get(1, { text: '', base: '', role: 'AUDITOR MOSA' });

    this.setState({
      searching: true,
      auditors: auditors.result,
    });
    this.getServices();
  }

  handleSearch = async (type) => {
    this.setState({ searching: true, page: 1 });
    const {
      base,
      hangar,
      aircraft,
      start_service,
      end_service,
      start_prediction,
      end_prediction,
      complexity,
      programming,
      mosa_status,
      risk,
      code
    } = this.state;
    sessionStorage.setItem(
      '@fast_track/MOSA_filters',
      JSON.stringify({
        base,
        hangar,
        aircraft,
        start_service,
        end_service,
        start_prediction,
        end_prediction,
        complexity,
        programming,
        mosa_status,
        risk,
        code
      })
    );
    this.getServices();
  };

  printStatusMOSA = (status) => {
    switch (status) {
      case 'MOSA Solicitada pela Qualidade': {
        return 'Solicitada pela Qualidade';
      }
      case 'MOSA Solicitada pela Manutenção': {
        return 'Solicitada pela Manutenção';
      }
      case 'MOSA Realizada': {
        return 'Realizada';
      }
      case 'MOSA não Realizada': {
        return 'Não Realizada';
      }
      case 'MOSA Conferida': {
        return 'Conferida';
      }
      case 'MOSA Lançada': {
        return 'Lançada';
      }
      default: {
        return status;
      }
    }
  };

  componentDidUpdate(prevProps, { page }) {
    if (page !== this.state.page) this.getServices();
  }

  handleClear = async () => {
    this.setState({
      loading: true,
      services: [],
      start_service: '',
      end_service: '',
      start_mosa: '',
      end_mosa: '',
      auditor: '',
      mosa_status: ''
    });

    this.page = this.state.page;
    const obj = await MosaService.filter(
      {
        auditor: '',
        mosa_status: '',
        start_service: '',
        end_service: '',
      },
      this.page
    );

    const services = obj ? obj.result : [];
    const pages = obj ? obj.pages : 0;
    const total = obj ? obj.total : 0;
    this.setState({ services, pages, total });
    this.setState({ loading: false });
  };

  getServices = async (props) => {
    this.setState({ loading: true });
    this.page = this.state.page;
    const obj = await MosaService.filter(
      {
        auditor: this.state.auditor,
        mosa_status: this.state.mosa_status,
        start_service: this.state.start_service,
        end_service: this.state.end_service,
        // base: this.state.base,
        // hangar: this.state.hangar,
        // code: this.state.code,
        // aircraft: this.state.aircraft,
        // start_prediction: this.state.start_prediction,
        // end_prediction: this.state.end_prediction,
        // complexity: this.state.complexity,
        // programming: this.state.programming,
        // risk: this.state.risk,
      },
      this.page
    );

    const services = obj ? obj.result : [];
    const pages = obj ? obj.pages : 0;
    const total = obj ? obj.total : 0;
    this.setState({ services, pages, total });
    this.setState({ loading: false });
  };

  handlePagination = (event, value) => {
    this.setState({ page: value });
  };

  renderServices = (service) => {
    const execute_prediction = new Date(service.execute_prediction);
    execute_prediction.setMinutes(
      execute_prediction.getMinutes() + execute_prediction.getTimezoneOffset()
    );
    const start_service = new Date(service.start_service);
    start_service.setMinutes(
      start_service.getMinutes() + start_service.getTimezoneOffset()
    );
    const end_service = new Date(service.end_service);
    end_service.setMinutes(
      end_service.getMinutes() + end_service.getTimezoneOffset()
    );
    return (
      <tr
        id="identify"
        key={service._id}
        onClick={() => browserHistory.push(`/mosa_management/${service._id}`)}
        style={{ cursor: 'pointer' }}
      >
        <td>
          <a>{service.aircraft ? service.aircraft.prefix : 'Não informada'}</a>
        </td>
        <td>
          {service.aircraft && service.aircraft._model ? service.aircraft._model.manufacturer : ''} -{' '}
          {service.aircraft && service.aircraft._model ? service.aircraft._model.model : ''}
        </td>
        <td>{service.register}</td>
        <td>{service.inspection}</td>
        <td>{service.code}</td>
        <td>
          {service.hangar
            ? service.position
              ? service.hangar ? service.hangar.name : '' + ' (' + service.position + ')'
              : service.hangar ? service.hangar.name : ''
            : 'Não informado'}
        </td>
        <td>
          {service.inspector.inspector ? service.inspector.inspector.name : ''}
        </td>
        <td style={{ color: '#1884CB' }}>
          <b>
            {service.start_service
              ? Moment(new Date(start_service))
                .locale('pt-BR')
                .format('DD/MM/YYYY HH:mm')
              : 'Sem data'}
          </b>
        </td>
        <td>
          {service.end_service
            ? Moment(new Date(end_service))
              .locale('pt-BR')
              .format('DD/MM/YYYY HH:mm')
            : 'Sem data'}
        </td>
        <td>
          {service.programming ? 'Serv. Programado' : 'Serv. não Programado'}
        </td>
        <td>
          {service.isMosa ? (
            <div
              className={
                service.mosa_status === 'MOSA Realizada'
                  ? 'btn btn-warning'
                  : service.mosa_status === 'MOSA Conferida'
                    ? 'btn btn-primary'
                    : service.mosa_status === 'MOSA não Realizada'
                      ? 'btn btn-light'
                      : 'btn btn-info'
              }
              style={{
                backgroundColor:
                  service.mosa_status === 'MOSA não Realizada'
                    ? '#cfd8dc'
                    : undefined,
              }}
            >
              {service.mosa_status
                ? this.printStatusMOSA(service.mosa_status)
                : ''}
            </div>
          ) : (
            <div className="btn">&nbsp;&nbsp;&nbsp;&nbsp;</div>
          )}
        </td>
        {/* <td
           style={{
           backgroundColor:
           service.mosa_status == 'MOSA Conferida'
           ? this.selectColor(service.events)
           : '',
           color:
           this.selectResult(service.events) === 'Não Há Riscos'
           ? '#000'
           : '#FFF',
           textAlign: 'center',
           fontWeight: 'bold',
           }}
           >
           {service.mosa_status == 'MOSA Conferida' &&
           this.selectResult(service.events)}
           </td> */}
      </tr>
    );
  };

  render() {
    const styles = {
      dateContainer: {
        display: 'flex',
        flexDirection: 'column',
      },
    };
    return (
      <>
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-6">
                <h2>MOSAs por auditor</h2>
                <ol className="breadcrumb">
                  <li>
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="active">
                    <strong>Mosa por auditor</strong>
                  </li>
                </ol>
              </div>
              <div className="col-lg-6"></div>
            </div>
          </div>
          <div className="col-lg-12" style={{ paddingTop: 20 }}>
            <div className="row">
              <div className="col-xs-12 col-md-6 col-xl-3">
                Auditores
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.auditor}
                  style={{ width: '100%', fontSize: 16 }}
                  displayEmpty={true}
                  onChange={(e) =>
                    this.setState({
                      auditor: e.target.value,
                    })
                  }
                >
                  <MenuItem value={''} style={{ fontSize: 16 }}>Todos os Auditores</MenuItem>
                  {this.state.auditors ?
                    this.state.auditors.map(
                      el => <MenuItem key={el._id} value={el._id} style={{ fontSize: 16 }}>
                        {`${el.name}`}
                      </MenuItem>
                    )
                    :
                    ''
                  }
                </Select>
              </div>
              <div className="col-xs-12 col-md-6 col-xl-3" style={styles.dateContainer}>
                Intervalo de
                <TextField
                  type="date"
                  value={this.state.start_service}
                  onChange={(e) =>
                    this.setState({
                      start_service: e.target.value,
                    })
                  }
                  InputProps={{
                    style: {
                      fontSize: 16,
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-xs-12 col-md-6 col-xl-3" style={styles.dateContainer}>
                Até
                <TextField
                  type="date"
                  value={this.state.end_mosa}
                  onChange={(e) =>
                    this.setState({
                      end_mosa: e.target.value,
                    })
                  }
                  InputProps={{
                    style: {
                      fontSize: 16,
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-xs-12 col-md-6 col-xl-3" style={styles.dateContainer}>
                Status MOSA
                <div className="input-group m-b">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.mosa_status}
                    style={{ width: '100%', fontSize: 16 }}
                    displayEmpty={true}
                    onChange={(e) =>
                      this.setState({
                        mosa_status: e.target.value,
                      })
                    }
                  >
                    <MenuItem value={''} style={{ fontSize: 16 }}>Todos os Status</MenuItem>
                    <MenuItem value={'MOSA Solicitada pela Qualidade'} style={{ fontSize: 16 }}>MOSA Solicitada pela Qualidade</MenuItem>
                    <MenuItem value={'MOSA Solicitada pela Manutenção'} style={{ fontSize: 16 }}>MOSA Solicitada pela Manutenção</MenuItem>
                    <MenuItem value={'MOSA não Realizada'} style={{ fontSize: 16 }}>MOSA não Realizada</MenuItem>
                    <MenuItem value={'MOSA Realizada'} style={{ fontSize: 16 }}>MOSA Realizada</MenuItem>
                    <MenuItem value={'MOSA Conferid'} style={{ fontSize: 16 }}>MOSA Conferida</MenuItem>
                    <MenuItem value={'MOSA Lançada'} style={{ fontSize: 16 }}>MOSA Lançada</MenuItem>
                  </Select>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6" >
                <div className="row">
                  <div
                    className="col-xs-12 col-sm-6"
                    style={{ padding: 5, paddingTop: 20 }}
                  >
                    <button
                      onClick={this.handleClear}
                      className="btn btn-danger btn-block"
                    >
                      Limpar Filtro
                    </button>
                  </div>
                  <div
                    className="col-xs-12 col-sm-6"
                    style={{ padding: 5, paddingTop: 20 }}
                  >
                    <button
                      onClick={() => this.handleSearch('services')}
                      className="btn btn-success btn-block"
                    >
                      Buscar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper wrapper-content">
          <LoadingModal className="col-lg-12" visible={this.state.loading} />
          <div className="row animated fadeInRight">
            <div
              hidden={this.state.loading}
              className="col-lg-12 table-responsive ibox-content"
              style={{
                paddingLeft: '40px',
                border: 'rgba(0,0,0,0.05) solid 1px',
                paddingRight: '40px',
                paddingBottom: '20px',
                paddingTop: '20px',
                overflowX: 'auto',
              }}
            >
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Aeronave</th>
                    <th>Modelo da Aeronave</th>
                    <th>Registro</th>
                    <th>Inspeção</th>
                    <th>Código MOSA</th>
                    <th>Hangar (Pos)</th>
                    {/* <th>Previsão de Execução</th> */}
                    <th>Auditor</th>
                    <th>Início do Serviço</th>
                    <th>Fim do Serviço</th>
                    <th>Tipo</th>
                    <th>Status MOSA</th>
                    {/* <th>Nível de Risco</th> */}
                  </tr>
                </thead>
                <tbody>
                  {!this.state.searching ? (
                    <tr>
                      <td
                        colSpan={11}
                        style={{
                          textAlign: 'center',
                          fontSize: 20,
                          height: 100,
                          verticalAlign: 'middle',
                        }}
                      >
                        Por favor, insira valores para busca.
                      </td>
                    </tr>
                  ) : this.state.services.length > 0 ? (
                    this.state.services.map(this.renderServices)
                  ) : (
                    <tr>
                      <td
                        colSpan={11}
                        style={{
                          textAlign: 'center',
                          fontSize: 20,
                          height: 100,
                          verticalAlign: 'middle',
                        }}
                      >
                        Nenhum serviço encontrado para as condições impostas.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <RoundedPagination count={this.state.pages}
                onChange={this.handlePagination} />
            </div>
          </div>
        </div>
      </>
    );
  }
}
