import React, { Component } from 'react';
import HangarsService from '../services/HangarsService';
import BasesService from '../services/BasesService';
import ReactSwitch from 'react-switch';
import { toast } from 'react-toastify';
import $ from 'jquery';
import '../styles/form.css';
import Swal from 'sweetalert2';

export default class AddHangar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
      name: '',
      code: '',
      base: '',
      layout: '',
      positions: [
        {
          position: 1,
          position_x: null,
          position_y: null,
          align: '',
        },
      ],
      bases: [],
    };
  }

  async componentDidMount() {
    const bases = await BasesService.getAll();
    this.setState({ bases: bases ? bases : [] });
  }

  save = async () => {
    const confirmation = (
      await Swal.fire({
        title: 'Confirmação',
        text: 'Você tem certeza que deseja adicionar este hangar?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        customContainerClass: 'sweetAlert',
      })
    ).value;
    if (confirmation) {
      const result = await HangarsService.add({
        active: this.state.active,
        name: this.state.name,
        code: this.state.code,
        base: this.state.base,
        layout: this.state.layout,
        positions: this.state.positions.filter(
          (pos) => pos.position_x !== null && pos.position_y !== null
        ),
      });
      if (result) {
        toast.success('Base criada com sucesso!');
        this.props.refresh();
        $('#addModal').removeClass('show').addClass('hide');
        $('.modal-backdrop').removeClass('show').addClass('hide');
        this.setState({
          active: true,
          name: '',
          code: '',
          base: '',
          layout: '',
          positions: [
            {
              position: 1,
              position_x: null,
              position_y: null,
              align: '',
            },
          ],
        });
      }
    }
  };

  renderPositionForm = (pos, index) => (
    <div className="input-button-row">
      <div className="input-group m-b" style={{ width: '90%' }}>
        <span className="input-group-addon">
          <i className="fa fa-arrows-alt"></i>
        </span>
        <div>
          <input
            type="number"
            placeholder="X"
            className="position-coord"
            value={pos.position_x}
            onChange={(e) => {
              let positions = this.state.positions;
              positions[index].position_x = e.target.value;
              this.setState({ positions });
            }}
          />
          <input
            type="number"
            placeholder="Y"
            className="position-coord"
            value={pos.position_y}
            onChange={(e) => {
              let positions = this.state.positions;
              positions[index].position_y = e.target.value;
              this.setState({ positions });
            }}
          />
        </div>
        <input
          type="text"
          placeholder="Alinhamento"
          className="form-control"
          value={pos.align}
          onChange={(e) => {
            let positions = this.state.positions;
            positions[index].align = e.target.value;
            this.setState({ positions });
          }}
        />
      </div>
      {index === this.state.positions.length - 1 ? (
        <button
          className="btn btn-success"
          onClick={() =>
            this.setState((currentState) => ({
              positions: [
                ...currentState.positions,
                {
                  position: currentState.positions.length + 1,
                  position_x: null,
                  position_y: null,
                  align: '',
                },
              ],
            }))
          }
        >
          <i className="fa fa-plus" />
        </button>
      ) : null}
    </div>
  );

  render() {
    return (
      <div className="modal inmodal" id="addModal">
        <div className="modal-dialog animated bounceInRight">
          <div
            className="modal-content row wrapper border-bottom white-bg page-heading"
            style={{ margin: 'auto' }}
          >
            <div className="modal-header">
              <h2>Novo Hangar</h2>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <h4>
                  <b>Ativo?</b>
                </h4>
                <ReactSwitch
                  checked={this.state.active}
                  onChange={(active) => this.setState({ active })}
                  onColor="#57b87b"
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h4>
                  <b>Nome*</b>
                </h4>
                <div className="input-group m-b">
                  <span className="input-group-addon">
                    <i className="fa fa-warehouse"></i>
                  </span>
                  <input
                    type="text"
                    placeholder="Nome"
                    className="form-control"
                    value={this.state.name}
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h4>
                  <b>Código*</b>
                </h4>
                <div className="input-group m-b">
                  <span className="input-group-addon">
                    <i className="fa fa-id-badge"></i>
                  </span>
                  <input
                    type="text"
                    placeholder="Código"
                    className="form-control"
                    value={this.state.code}
                    onChange={(e) => this.setState({ code: e.target.value })}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h4>
                  <b>Base*</b>
                </h4>
                <div className="input-group m-b">
                  <span className="input-group-addon">
                    <i className="fa fa-home"></i>
                  </span>
                  <select
                    className="form-control"
                    value={this.state.base}
                    onChange={(e) => this.setState({ base: e.target.value })}
                  >
                    <option value="">Selecione uma base</option>
                    {this.state.bases.map((base) => (
                      <option value={base._id}>{base.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h4>
                  <b>Layout*</b>
                </h4>
                <div className="input-group m-b">
                  <span className="input-group-addon">
                    <i className="fa fa-image"></i>
                  </span>
                  <textarea
                    type="text"
                    placeholder="Layout (SVG)"
                    className="form-control"
                    value={this.state.layout}
                    onChange={(e) => this.setState({ layout: e.target.value })}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h4>
                  <b>Posições*</b>
                </h4>
                {this.state.positions.map(this.renderPositionForm)}
              </div>
            </div>
            <br />
            <div
              className="row"
              style={{ display: 'flex', justifyContent: 'space-around' }}
            >
              <div>
                <button
                  data-target="#addModal"
                  className="btn btn-success"
                  onClick={this.save}
                >
                  Salvar
                </button>
              </div>
              <div className="text-right">
                <button
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={() =>
                    this.setState({
                      active: true,
                      name: '',
                      code: '',
                      base: '',
                      layout: '',
                      positions: [
                        {
                          position: 1,
                          position_x: null,
                          position_y: null,
                          align: '',
                        },
                      ],
                      bases: [],
                    })
                  }
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
