import React, { Component } from 'react';
import { Link } from 'react-router';
import '../styles/login.css';
import UsersService from '../services/UsersService';
import { toast, ToastContainer } from 'react-toastify';

const altButtonsWrapper = {
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  marginTop: '8px',
  marginBottom: '16px',
};

const altButtons = {
  color: '#fff',
  fontSize: '16px',
};

const downloadButton = {
  marginTop: 16,
};

const dt = new Date();
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { login: '', password: '' };
  }

  login = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    await UsersService.login(
      this.state,
      async (user) => {
        if (
          (user.fastTrackingAccess.role === 'AUDITOR MOSA' &&
            !user.fastTrackingAccess.enable_maintenance_web) ||
          user.fastTrackingAccess.role === 'MECÂNICO'
        )
          toast.error('Usuário não autorizado!');
        else {
          localStorage.setItem(
            '@omni/fast_track/user',
            JSON.stringify((await user).fastTrackingAccess)
          );
          localStorage.setItem(
            '@omni/fast_track/token',
            JSON.stringify((await user).token)
          );
          var myHeaders = new Headers();
          const { createdAt, updatedAt, _id } = (await user).fastTrackingAccess;
          if (createdAt === updatedAt) {
            this.props.history.push(`/edit-password/${_id}`);
            toast.success('Bem Vindo! Por favor, crie uma nova senha.');
          } else {
            this.props.history.push('/');
            toast.success('Bem Vindo!');
          }
        }
      },
      (e) => {
        toast.error(e.message ? e.message : 'Usuário ou senha inválido!');
      }
    );
    this.setState({ loading: false });
  };

  render() {
    return (
      <div className="main container-fluid">
        <form onSubmit={this.login}>
          <div className="loginContainer text-center animated fadeIn">
            <img src="/img/logo.png" alt="Logo" />
            <h2>Fast Track</h2>
            <input
              type="text"
              placeholder="Login"
              value={this.state.login}
              className="form-control"
              onChange={(e) => this.setState({ login: e.target.value })}
            />
            <br />
            <input
              type="password"
              placeholder="Senha"
              value={this.state.password}
              className="form-control"
              onChange={(e) => this.setState({ password: e.target.value })}
            />
            <br />
            {this.state.loading ? (
              <div className="btn btn-light btn-block">
                <div className="spinner"></div>
              </div>
            ) : (
              <input
                className="btn btn-light btn-block"
                type="submit"
                value="Entrar"
              />
            )}
            <br />
            <div style={altButtonsWrapper}>
              <Link to="redefine-password" style={altButtons}>
                Esqueci minha senha
              </Link>
            </div>
            <p>
              <small>Powered by Criatech {dt.getFullYear()}</small>
            </p>
          </div>
        </form>
        <center style={downloadButton}>
          <Link to="record-hums">
            <button className="btn btn-primary">
              Novo Registro de Downloads do HUMS
            </button>
          </Link>
        </center>
        <ToastContainer autoClose={3000} />
      </div>
    );
  }
}
