import { apiURL } from "../config/consts";
import { toast } from "react-toastify";
import fetch from "./fetchWrapper";

export default class ProcessService {
  static add = async (data) => {
    try {
      const response = await fetch(`${apiURL}/process`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
        body: JSON.stringify(data),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static get = async (page) => {
    try {
      const response = await fetch(`${apiURL}/processes-listall/${page}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static getAll = async () => {
    try {
      const response = await fetch(`${apiURL}/processes-listall`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static getById = async (id) => {
    try {
      const response = await fetch(`${apiURL}/process/${id}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static delete = async (archiveId) => {
    try {
      const response = await fetch(`${apiURL}/process/${archiveId}`, {
        method: "DELETE",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static edit = async (id, data) => {
    try {
      const response = await fetch(`${apiURL}/process/${id}`, {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
        body: JSON.stringify(data),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };
}
