import React, { Component } from 'react';
import { Link } from "react-router";
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from "moment";

import MosaService from "../../../services/MosaService";
import LoadingModal from "../../LoadingModal";
import RoundedPagination from '../../../components/common/RoundedPagination';

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
      name: "",
      code: "",
      pages: 0,
      page: 1,
      mosa_list: []
    };
  }

  getMOSA = async (mechanic) => {
    this.setState({ loading: true, mosa_list: [] });
    this.page = this.state.page;
    const response = await MosaService.filter({
      hangar: '',
      code: '',
      aircraft: '',
      start_service: '',
      end_service: '',
      start_prediction: '',
      end_prediction: '',
      complexity: '',
      programming: '',
      mosa_status: '',
      mechanic,
      risk: false,
    }, this.page);
    const pages = response ? response.pages : 0;
    this.setState({
      pages,
      loading: false,
      mosa_list: response.result
    });
  }

  renderMosas = ({ _id, code, mosa_end, aircraft, register, inspection }) => {
    return (
      <tr id="identify" key={_id}>
        <td>
          <a>{moment(mosa_end).format("DD/MM/YYYY")}</a>
        </td>
        <td>
          <a>{aircraft ? aircraft.prefix : ''}</a>
        </td>
        <td>
          <a>{register}</a>
        </td>
        <td>
          <a>{inspection}</a>
        </td>
        <td>
          <Link to={`/management/${_id}`}>
            <a>{code}</a>
          </Link>
        </td>
      </tr>
    );
  }

  handlePagination = (event, value) => {
    this.setState({ page: value });
  };

  render() {
    return (
      <>
        {/* <Slide> */}
        <LoadingModal visible={this.state.loading} />
        <Dialog
          onEnter={() => this.getMOSA(this.props.mechanic)}
          style={{ zIndex: 1000000, minWidth: 600, minHeight: 300 }}
          open={this.props.open}
          maxWidth={'md'}
          scroll={'body'}
          keepMounted
          onClose={this.props.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <div
            className="modal-content row wrapper border-bottom white-bg page-heading"
            style={{ margin: "auto", padding: "30px" }}
          >
            <div className="mosas-modal-header">
              <h2>MOSAs</h2>
              <hr />
            </div>
            <div className="row" style={{ marginTop: "20px" }}>
              {/* <LoadingModal className="col-lg-12" visible={this.state.loading} /> */}
              <div
                // hidden={this.state.loading}
                className="col-lg-12 table-responsive ibox-content"
                style={{
                  paddingLeft: "40px",
                  border: "rgba(0,0,0,0.05) solid 1px",
                  paddingRight: "40px",
                  paddingBottom: "20px",
                  paddingTop: "20px",
                }}
              >
                <>
                  <table className="table table-striped" style={{ minWidth: 700, minHeight: 200, textAlign: 'center' }}>
                    <thead>
                      <tr>
                        <th>Data</th>
                        <th>Aeronave</th>
                        <th>Registro</th>
                        <th style={{ textAlign: 'center' }}>Inspeção</th>
                        <th>Código MOSA</th>
                      </tr>
                    </thead>

                    {this.state.mosa_list && this.state.mosa_list.length > 0 ?
                      <tbody>{this.state.mosa_list.map(this.renderMosas)}</tbody>
                      :
                      <tbody>
                        <tr>
                          <td colSpan={5}>
                            <center>{this.state.loading ? <CircularProgress color={'rgb(25, 42, 57)'} /> : <span>Não existem registros para o usuário selecionado.</span>}</center>
                          </td>
                        </tr>
                      </tbody>
                    }
                  </table>

                  <RoundedPagination count={this.state.pages}
                                     onChange={this.handlePagination} />
                </>
              </div>
            </div>
            <div className="col-lg-12" style={{ textAlign: "center" }}>
              <button
                className="btn btn-success"
                data-dismiss="modal"
                style={{ marginTop: "20px" }}
                onClick={() => this.props.handleClose()}
              >
                Voltar
              </button>
            </div>
          </div>
        </Dialog>
        {/* </Slide> */}
      </>
    );
  }
}
