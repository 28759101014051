import React, { Component } from 'react'
import { Link, browserHistory } from 'react-router'
import UsersService from '../services/UsersService'
import BasesService from '../services/BasesService'
import ReactSwitch from 'react-switch'
import { toast } from 'react-toastify'
import LoadingModal from './LoadingModal'
import Swal from 'sweetalert2'
import MultiSelect from 'react-multi-select-component'
import { auditor_cmpt_arr } from '../utils/select-options'

const styles = {
  helperText: {
    fontSize: 10,
  },
};

export default class User extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      active: true,
      name: '',
      login: '',
      email: '',
      password: '',
      group: '',
      sigmec_role: '',
      sigmec_post: '',
      role: '',
      imported: false,
      base: null,
      auditing_areas: [],
      sync_date: new Date(),
      bases: []
    }
    const user = JSON.parse(localStorage.getItem('@omni/fast_track/user'))
    if (
      (user.role === 'PCP' ||
        user.role === 'COORDENADOR' ||
        user.role === 'VISUALIZADOR') &&
      user._id !== props.params.id
    )
      browserHistory.push('/dashboard')
  }

  async componentDidMount() {
    this.setState({ loading: true })

    const [user, bases] = await Promise.all([
      UsersService.getById(this.props.params.id),
      BasesService.getAll()
    ])

    if (!user) browserHistory.goBack()

    this.setState({ ...user, bases: bases })

    if (user.sync_date)
      this.setState({ sync_date: this.validationDate(user.sync_date) })

    this.setState({ loading: false })
  }

  save = async () => {
    const user = JSON.parse(localStorage.getItem('@omni/fast_track/user'))
    if (
      (user._id === this.props.params.id ||
        !['ADM', 'ADM - TI'].includes(user.role)) &&
      user.role !== this.state.role
    )
      return
    const confirmation = (
      await Swal.fire({
        title: 'Confirmação',
        text:
          'Você tem certeza que deseja salvar as alterações a este usuário?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      })
    ).value
    if (confirmation) {
      const result = await UsersService.edit(this.props.params.id, this.state)
      if (result) {
        browserHistory.goBack();
        toast.success('Usuário atualizado!')
      }
    }
  }

  validationDate = date => {
    return !date
      ? null
      : `${new Date(date).getFullYear()}-${`${new Date(date).getMonth() +
        1}`.padStart(2, 0)}-${`${new Date(date).getDate()}`.padStart(
          2,
          0
        )}T${`${new Date(date).getHours()}`.padStart(2, 0)}:${`${new Date(
          date
        ).getMinutes()}`.padStart(2, 0)}`
  }

  renderBaseOptions = base => (
    <option value={base._id}>
      {`${base.name}`} ({`${base.code}`}){' '}
    </option>
  )

  render() {
    if (this.state.loading) return <LoadingModal visible />

    return (
      <>
        <div className='wrapper wrapper-content col-xl-12'>
          <div
            className='row wrapper border-bottom white-bg page-heading animated bounceInRight'
            style={{ margin: 'auto', padding: '30px' }}
          >
            <h2>Editar dados do usuário</h2>
            <br />
            <div className='row'>
              <div className='col-lg-6'>
                <h4>
                  <b>Ativo?</b>
                </h4>
                <ReactSwitch
                  checked={this.state.active}
                  onChange={active => this.setState({ active })}
                  onColor='#57b87b'
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>
              <div className='col-lg-6'>
                <Link to={`/edit-password/${this.props.params.id}`}>
                  <button className='pull-right btn btn-success'>
                    Mudar Senha
                  </button>
                </Link>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-6'>
                <h4>
                  <b>Nome*</b>
                </h4>
                <div className='input-group m-b'>
                  <span className='input-group-addon'>
                    <i className='fa fa-user'></i>
                  </span>
                  <input
                    type='text'
                    placeholder='Nome completo'
                    className='form-control'
                    value={this.state.name}
                    onChange={e => this.setState({ name: e.target.value })}
                  />
                </div>
              </div><div className='col-lg-6'>
                <h4>
                  <b>E-mail*</b>
                </h4>
                <div className='input-group m-b'>
                  <span className='input-group-addon'>
                    <i className='fa fa-envelope'></i>
                  </span>
                  <input
                    type='text'
                    placeholder='E-mail'
                    className='form-control'
                    value={this.state.email}
                    onChange={e => this.setState({ email: e.target.value })}
                  />
                </div>
              </div>
              <div className='col-lg-6'>
                <h4>
                  <b>Login*</b>
                </h4>
                <div className='input-group m-b'>
                  <span className='input-group-addon'>
                    <i className='fa fa-envelope'></i>
                  </span>
                  <input
                    type='text'
                    placeholder='Login'
                    className='form-control'
                    value={this.state.login}
                    disabled={
                      !['ADM', 'ADM - TI'].includes(
                        JSON.parse(
                          localStorage.getItem('@omni/fast_track/user')
                        ).role
                      ) ||
                      JSON.parse(localStorage.getItem('@omni/fast_track/user'))
                        ._id === this.props.params.id
                    }
                    onChange={e => this.setState({ login: e.target.value })}
                  />
                </div>
              </div>
              <div className='col-lg-6'>
                <h4>
                  <b>Grupo*</b>
                </h4>
                <div className='input-group m-b'>
                  <span className='input-group-addon'>
                    <i className='fa fa-users'></i>
                  </span>
                  <input
                    type='text'
                    placeholder='Grupo'
                    className='form-control'
                    value={this.state.group}
                    onChange={e => this.setState({ group: e.target.value })}
                  />
                </div>
              </div>
              <div className='col-lg-6'>
                <h4>
                  <b>Perfil*</b>
                </h4>
                <div className='input-group m-b'>
                  <span className='input-group-addon'>
                    <i className='fa fa-cog'></i>
                  </span>
                  <select
                    className='form-control'
                    value={this.state.role}
                    onChange={e =>
                      this.setState({
                        role: e.target.value,
                        enable_maintenance_web: false
                      })
                    }
                    disabled={
                      !['ADM', 'ADM - TI'].includes(
                        JSON.parse(
                          localStorage.getItem('@omni/fast_track/user')
                        ).role
                      )
                    }
                  >
                    <option value='' disabled selected>
                      Selecione a Função
                    </option>
                    <option>ADM - TI</option>
                    <option>ADM</option>
                    <option>AVALIADOR</option>
                    <option>COORDENADOR</option>
                    <option>ENGENHARIA</option>
                    <option>VISUALIZADOR</option>
                    <option>PCP</option>
                    <option>AUDITOR MOSA</option>
                    <option>MECÂNICO</option>
                    <option>APOIO</option>
                  </select>
                </div>
                {this.state.role === 'AUDITOR MOSA' && (
                  <div className='form-check'>
                    <input
                      type='checkbox'
                      className='form-check-input'
                      id='enableMaintenanceCheck'
                      checked={this.state.enable_maintenance_web}
                      onChange={e =>
                        this.setState({
                          enable_maintenance_web: e.target.checked
                        })
                      }
                    />
                    <label
                      className='form-check-label'
                      for='enableMaintenanceCheck'
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp; Acesso à manutenção no cliente
                      web
                    </label>

                    <h4>
                      <b>Competência</b>
                    </h4>
                    <MultiSelect
                      options={auditor_cmpt_arr}
                      value={this.state.auditing_areas}
                      onChange={value =>
                        this.setState({ auditing_areas: value })
                      }
                      labelledBy={'Selecione as competências'}
                      disableSearch
                      hasSelectAll={false}
                      overrideStrings={{
                        allItemsAreSelected: 'All items are selected.',
                        clearSearch: 'Limpar busca',
                        noOptions: 'Sem opções',
                        search: 'Buscar',
                        selectAll: 'Selecionar todos',
                        selectSomeItems: 'Selecione as competências'
                      }}
                    />
                  </div>
                )}
              </div>
              <div className='col-lg-6'>
                <h4>
                  <b>Matrícula</b>
                </h4>
                <div className='input-group m-b'>
                  <span className='input-group-addon'>
                    <i className='fa fa-credit-card'></i>
                  </span>
                  <input
                    type='text'
                    placeholder='Matrícula'
                    className='form-control'
                    value={this.state.registration}
                    onChange={e =>
                      this.setState({ registration: e.target.value })
                    }
                  />
                </div>
              </div>
              {this.state.role == 'MECÂNICO' ||
                this.state.role == 'AUDITOR MOSA' ? (
                <div className='col-lg-6'>
                  <h4>
                    <b>Base</b>
                  </h4>
                  <div className='input-group m-b'>
                    <span className='input-group-addon'>
                      <i className='fa fa-home'></i>
                    </span>
                    <select
                      className='form-control'
                      value={this.state.base}
                      onChange={e => {
                        this.setState({
                          base: e.target.value,
                          sync_date: new Date()
                        })
                      }}
                    >
                      <option value=''>Selecione uma base</option>
                      {this.state.bases.map(this.renderBaseOptions)}
                    </select>
                  </div>
                </div>
              ) : null}
              <div className="col-lg-6">
                <h4>
                  <b>Sigmec Post</b>
                </h4>
                <div className="input-group m-b">
                  <span className="input-group-addon">
                    <i className="fa fa-users"></i>
                  </span>
                  <input
                    type="text"
                    placeholder="Sigmec Post"
                    className="form-control"
                    value={this.state.sigmec_post}
                    disabled
                    onChange={(e) => this.setState({ sigmec_post: e.target.value })}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <h4>
                  <b>Sigmec Role</b>
                </h4>
                <div className="input-group m-b">
                  <span className="input-group-addon">
                    <i className="fa fa-users"></i>
                  </span>
                  <input
                    type="text"
                    placeholder="Sigmec Role"
                    className="form-control"
                    value={this.state.sigmec_role}
                    disabled
                    onChange={(e) => this.setState({ sigmec_role: e.target.value })}
                  />
                </div>
              </div>
              {this.state.imported && (
                <div className='col-lg-6'>
                  <h4>
                    <b>Última Sincronização</b>
                  </h4>
                  <div className='input-group m-b'>
                    <span className='input-group-addon'>
                      <i className='fa fa-calendar' />
                    </span>
                    <input
                      type='datetime-local'
                      placeholder='Última Sincronização'
                      className='form-control'
                      value={this.state.sync_date}
                      disabled
                    />
                  </div>
                </div>
              )}
            </div>
            <br />
            <div className='col-lg-12' style={{ textAlign: 'center' }}>
              <button
                className='btn btn-success'
                onClick={this.save}
                style={{ margin: '2px' }}
              >
                Salvar
              </button>
              <Link to='/users'>
                <button
                  className='btn btn-danger'
                  data-dismiss='modal'
                  style={{ margin: '2px' }}
                >
                  Cancelar
                </button>
              </Link>
            </div>
          </div>
        </div>
      </>
    )
  }
}
