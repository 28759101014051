/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import ManagementService from '../services/ManagementService';
import AircraftService from '../services/AircraftsService';
import BaseService from '../services/BasesService';
import Moment from 'moment';
import LoadingModal from './LoadingModal';
import HangarsService from '../services/HangarsService';
import moment from 'moment';
import RoundedPagination from '../components/common/RoundedPagination';

export default class Management extends Component {
  constructor(props) {
    super(props);
    const role = JSON.parse(localStorage.getItem('@omni/fast_track/user')).role;
    this.state = {
      services: [],
      filter: '',
      pages: 0,
      page: 1,
      filter_hangars: [],
      filter_aircrafts: [],
      filter_bases: [],
      filter_complexities: [],
      filter_inspections: [],
      role,
    };
  }

  getHangars = async (base) => {
    this.setState({ loading: true });
    const filter_hangars = await HangarsService.getByBase(base);
    this.setState({
      filter_hangars: filter_hangars ? filter_hangars : [],
      loading: false,
    });
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ loading: true });
    const baseStored = localStorage.getItem('@omni/fast_track/base', false);

    if (this.props.params.base)
      localStorage.setItem('@omni/fast_track/base', this.props.params.base);
    if (baseStored && !this.props.params.base)
      browserHistory.push(`/managements/${baseStored}`);

    const base = this.props.params.base ? this.props.params.base : null;
    this.setState({ base });

    let start_service = this.props.params.date
      ? Moment(this.props.params.date)
      : null;
    start_service = start_service
      ? this.validationDate(start_service.format())
      : null;

    const hangar = this.props.params.hangar ? this.props.params.hangar : '';
    const filter_aircrafts = await AircraftService.getAll();
    const baseName = (await BaseService.getById(base)).name;

    await this.setState({
      baseName,
      hangar,
      start_service: this.props.params.date ? start_service : null,
      searching: true,
      filter_aircrafts: filter_aircrafts ? filter_aircrafts : [],
      loading: false,
    });
    if (base) this.getHangars(base);

    if (localStorage.getItem('@omni/fast_track/management_filter')) {
      const storedFilter = JSON.parse(
        localStorage.getItem('@omni/fast_track/management_filter')
      );
      if (storedFilter.stateToPreserve) {
        await this.setState(storedFilter.stateToPreserve);
        localStorage.removeItem('@omni/fast_track/management_filter');
      }
    }
    this.getServices();
  }

  componentDidUpdate(prevProps, { page }) {
    if (page !== this.state.page) this.getServices();
  }

  getServices = async (props) => {
    if (this.state.filter_bases.length < 1) this.setState({ loading: true });
    this.page = this.state.page;
    const obj = await ManagementService.filter(
      {
        base: this.state.base,
        hangar: this.state.hangar,
        aircraft: this.state.aircraft,
        start_service:
          this.state.start_service !== 'NaN-NaN-NaNTNaN:NaN' &&
            this.state.start_service
            ? this.state.start_service
            : null,
        end_service:
          this.state.end_service !== 'NaN-NaN-NaNTNaN:NaN' &&
            this.state.end_service
            ? this.state.end_service
            : null,
        start_prediction:
          this.state.start_prediction !== 'NaN-NaN-NaNTNaN:NaN' &&
            this.state.start_prediction
            ? this.state.start_prediction
            : null,
        end_prediction:
          this.state.end_prediction !== 'NaN-NaN-NaNTNaN:NaN' &&
            this.state.end_prediction
            ? this.state.end_prediction
            : null,
        complexity: this.state.complexity,
        register: this.state.register,
        programming: this.state.programming,
        mosa_status: this.state.mosa_status,
      },
      this.page
    );
    const services = obj ? obj.result : [];
    const pages = obj ? obj.pages : 0;
    await this.setState({ services, pages });
    this.setState({ loading: false });
  };

  filter = async () => {
    if (this.state.filter.length > 0) {
      this.setState({ loading: true });
      const obj = await ManagementService.get(1);
      const services = obj ? obj.result : [];
      this.setState({ services });
      this.setState({ loading: false });
    } else this.getServices();
  };

  handlePagination = (event, value) => {
    this.setState({ page: value });
  };

  printStatusMOSA = (status) => {
    switch (status) {
      case 'MOSA Solicitada pela Qualidade': {
        return 'Solicitada pela Qualidade';
      }
      case 'MOSA Solicitada pela Manutenção': {
        return 'Solicitada pela Manutenção';
      }
      case 'MOSA Realizada': {
        return 'Realizada';
      }
      case 'MOSA não Realizada': {
        return 'Não Realizada';
      }
      case 'MOSA Conferida': {
        return 'Conferida';
      }
      default: {
        return status;
      }
    }
  };

  renderServices = (service) => {
    const execute_prediction = new Date(service.execute_prediction);
    execute_prediction.setMinutes(
      execute_prediction.getMinutes() + execute_prediction.getTimezoneOffset()
    );
    const start_service = new Date(service.start_service);
    start_service.setMinutes(
      start_service.getMinutes() + start_service.getTimezoneOffset()
    );
    const end_service = new Date(service.end_service);
    end_service.setMinutes(
      end_service.getMinutes() + end_service.getTimezoneOffset()
    );

    return (
      <tr
        id="identify"
        key={service._id}
        onClick={() => {
          localStorage.setItem(
            '@omni/fast_track/management_filter',
            JSON.stringify({
              stateToPreserve: {
                aircraft: this.state.aircraft,
                complexity: this.state.complexity,
                register: this.state.register,
                programming: this.state.programming,
                base: this.state.base,
                hangar: this.state.hangar,
                start_service: this.state.start_service,
                end_service: this.state.end_service,
                start_prediction: this.state.start_prediction,
                end_prediction: this.state.end_prediction,
                mosa_status: this.state.mosa_status,
                page: this.state.page,
                pages: this.state.pages,
              },
            })
          );
          browserHistory.push(`/management/${service._id}`);
        }}
        style={{ cursor: 'pointer' }}
      >
        <td>
          <a>{service.aircraft ? service.aircraft.prefix : 'Não informada'}</a>
        </td>
        <td>
          {service.aircraft ? service.aircraft._model.manufacturer : ''} -{' '}
          {service.aircraft ? service.aircraft._model.model : ''}
        </td>
        <td>{service.inspection}</td>
        <td>{service.register}</td>
        <td>
          {service.hangar
            ? service.position
              ? service.hangar.name + ' (' + service.position + ')'
              : service.hangar.name
            : 'Não informado'}
        </td>
        <td>
          {service.execute_prediction
            ? Moment(execute_prediction).format('DD/MM/YYYY HH:mm')
            : 'Sem data'}
        </td>
        <td style={{ color: '#1884CB' }}>
          <b>
            {service.start_service
              ? Moment(new Date(start_service))
                .locale('pt-BR')
                .format('DD/MM/YYYY HH:mm')
              : 'Sem data'}
          </b>
        </td>
        <td>
          {service.end_service
            ? Moment(new Date(end_service))
              .locale('pt-BR')
              .format('DD/MM/YYYY HH:mm')
            : 'Sem data'}
        </td>
        <td>
          {service.programming ? 'Serv. Programado' : 'Serv. não Programado'}
        </td>
        <td>{service.complexity}</td>
        <td>
          {service.isMosa ? (
            <div
              className={
                service.mosa_status === 'MOSA Realizada'
                  ? 'btn btn-warning'
                  : service.mosa_status === 'MOSA Conferida'
                    ? 'btn btn-primary'
                    : 'btn btn-danger'
              }
            >
              {service.mosa_status
                ? this.printStatusMOSA(service.mosa_status)
                : ''}
            </div>
          ) : (
            <div className="btn">&nbsp;&nbsp;&nbsp;&nbsp;</div>
          )}
        </td>
      </tr>
    );
  };

  validationDate = (date) => {
    return !date
      ? null
      : `${new Date(date).getFullYear()}-${`${new Date(date).getMonth() + 1
        }`.padStart(2, 0)}-${`${new Date(date).getDate()}`.padStart(
          2,
          0
        )}T${`${new Date(date).getHours()}`.padStart(2, 0)}:${`${new Date(
          date
        ).getMinutes()}`.padStart(2, 0)}`;
  };

  handleSearch = async () => {
    if (
      !this.state.aircraft &&
      !this.state.complexity &&
      !this.state.register &&
      !this.state.programming &&
      !this.state.base &&
      !this.state.hangar &&
      !this.state.start_service &&
      !this.state.end_service &&
      !this.state.start_prediction &&
      !this.state.end_prediction &&
      !this.state.mosa_status
    ) {
      this.setState({ services: [], page: 1, searching: false });
    } else {
      this.setState({ searching: true, page: 1 });
      this.getServices();
    }
  };

  handleClear = async () => {
    await this.setState({
      hangar: '',
      aircraft: '',
      start_service: '',
      end_service: '',
      start_prediction: '',
      end_prediction: '',
      complexity: '',
      register: '',
      programming: '',
      mosa_status: '',
      services: [],
      page: 1,
    });
    this.getServices();
    this.setState({ searching: true });
  };

  renderBasesOptions = (base) => (
    <option value={base._id}>
      {`${base.name}`} ({`${base.code}`}){' '}
    </option>
  );

  renderHangarsOptions = (hangar) => (
    <option value={hangar._id}>{`${hangar.code} (${hangar.base.name})`}</option>
  );

  renderAircraftsOptions = (aircraft) => (
    <option value={aircraft._id}>{`${aircraft.prefix}  (${aircraft ? aircraft._model.manufacturer : ''
      } - ${aircraft ? aircraft._model.model : ''})`}</option>
  );

  render() {
    return (
      <div>
        <LoadingModal visible={this.state.loading && !this.state.base} />
        <div
          style={{ display: !this.state.base ? 'none' : 'block' }}
          className="wrapper wrapper-content animated fadeInDown"
        >
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="row">
                <div className="col-lg-8">
                  <h2>Gerenciamento de {this.state.baseName}</h2>
                  <ol className="breadcrumb">
                    <li>
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="active">
                      <strong>Gerenciamento de {this.state.baseName}</strong>
                    </li>
                  </ol>
                </div>
                <div className={!['PCP', 'VISUALIZADOR'].includes(this.state.role) ? "col-lg-2" : "col-lg-4"} style={{ paddingTop: '10px' }}>
                  <Link to="/managements/bases">
                    <button
                      className="btn btn-info btn-block"
                      style={{ marginRight: 10 }}
                      onClick={() => {
                        localStorage.removeItem('@omni/fast_track/base');
                        browserHistory.replace('/managements/bases');
                      }}
                    >
                      ALTERAR BASE
                    </button>
                  </Link>
                </div>
                {!['PCP', 'VISUALIZADOR'].includes(this.state.role) && <div className="col-lg-2" style={{ paddingTop: '10px' }}>
                  <Link
                    className="btn btn-success btn-block"
                    to={`/new-management/${this.state.base}`}
                  >
                    Novo Serviço
                  </Link>
                </div>}
              </div>
            </div>
            <div className="col-lg-12" style={{ paddingTop: 20 }}>
              <div className="row">
                <div className="col-lg-3">
                  Aeronave
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-plane"></i>
                    </span>
                    <select
                      className="form-control"
                      value={this.state.aircraft}
                      onChange={(e) =>
                        this.setState({ aircraft: e.target.value })
                      }
                    >
                      <option value="">Todas as aeronaves</option>
                      {this.state.filter_aircrafts.map(
                        this.renderAircraftsOptions
                      )}
                    </select>
                  </div>
                </div>

                <div className="col-lg-3">
                  Tipo de Serviço
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-tasks"></i>
                    </span>
                    <select
                      className="form-control"
                      value={this.state.programming}
                      onChange={(e) =>
                        this.setState({ programming: e.target.value })
                      }
                    >
                      <option value="">Todos os Tipos</option>
                      <option value="Serv. Programado">Programado</option>
                      <option value="Serv. não Programado">
                        Não Programado
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-3">
                  Complexidade
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-clock"></i>
                    </span>
                    <select
                      className="form-control"
                      value={this.state.complexity}
                      onChange={(e) =>
                        this.setState({ complexity: e.target.value })
                      }
                    >
                      <option value="">Todas as Complexidades</option>
                      <option value="Base">Base</option>
                      <option value="Line">Line</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-3">
                  Registro
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-tag"></i>
                    </span>
                    <input
                      placeholder="Registro"
                      className="form-control"
                      value={this.state.register}
                      onChange={(e) =>
                        this.setState({ register: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  Previsão de Início (de)
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-calendar"></i>
                    </span>
                    <input
                      type="date"
                      className="form-control"
                      value={this.state.start_prediction}
                      onChange={(e) =>
                        this.setState({
                          start_prediction: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  Previsão de Início (até)
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-calendar"></i>
                    </span>
                    <input
                      type="date"
                      className="form-control"
                      value={this.state.end_prediction}
                      onChange={(e) =>
                        this.setState({
                          end_prediction: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  Início do Serviço
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-calendar-alt"></i>
                    </span>
                    <input
                      type="date"
                      className="form-control"
                      value={this.state.start_service}
                      onChange={(e) => {
                        this.setState({
                          start_service: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  Fim do Serviço
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-calendar-alt"></i>
                    </span>
                    <input
                      type="date"
                      className="form-control"
                      value={this.state.end_service}
                      onChange={(e) =>
                        this.setState({
                          end_service: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  Status MOSA
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-tasks"></i>
                    </span>
                    <select
                      className="form-control"
                      value={this.state.mosa_status}
                      onChange={(e) =>
                        this.setState({ mosa_status: e.target.value })
                      }
                    >
                      <option value={null}>Todos os Status</option>
                      <option>MOSA Solicitada pela Qualidade</option>
                      <option>MOSA Solicitada pela Manutenção</option>
                      <option>MOSA não Realizada</option>
                      <option>MOSA Realizada</option>
                      <option>MOSA Conferida</option>
                      <option>MOSA Lançada</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-3">
                  Hangar
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-home"></i>
                    </span>
                    <select
                      className="form-control"
                      value={this.state.hangar}
                      onChange={(e) =>
                        this.setState({ hangar: e.target.value })
                      }
                    >
                      <option value="">Todos os hangares</option>
                      {this.state.filter_hangars.map(this.renderHangarsOptions)}
                    </select>
                  </div>
                </div>
                <div className="col-lg-4"></div>
                <div className="col-lg-4">
                  <div className="col-md-6" style={{ paddingTop: 20 }}>
                    <button
                      onClick={this.handleClear}
                      className="btn btn-danger btn-block"
                    >
                      Limpar Filtro
                    </button>
                  </div>
                  <div className="col-md-6" style={{ paddingTop: 20 }}>
                    <button
                      onClick={this.handleSearch}
                      className="btn btn-success btn-block"
                    >
                      Buscar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="wrapper wrapper-content">
            <div className="row animated fadeInDown">
              <div
                className="col-lg-12 table-responsive ibox-content"
                style={{
                  paddingLeft: '40px',
                  border: 'rgba(0,0,0,0.05) solid 1px',
                  paddingRight: '40px',
                  paddingBottom: '20px',
                  paddingTop: '20px',
                }}
              >
                <table className="table table-striped">
                  <thead>
                    <tr>
                      {/* <th>Ativo</th> */}
                      <th>Aeronave</th>
                      <th>Modelo da Aeronave</th>
                      <th>Inspeção</th>
                      <th>Registro</th>
                      <th>Hangar (Pos)</th>
                      <th>Previsão de Execução</th>
                      <th>Início</th>
                      <th>Fim</th>
                      <th>Tipo</th>
                      <th>Complexidade</th>
                      <th>Status Mosa</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.loading ? (
                      <tr>
                        <th colSpan={11}>
                          <div className="spinner spinner-lg" />
                        </th>
                      </tr>
                    ) : !this.state.searching ? (
                      <tr>
                        <td
                          colSpan={11}
                          style={{
                            textAlign: 'center',
                            fontSize: 20,
                            height: 100,
                            verticalAlign: 'middle',
                          }}
                        >
                          Por favor, insira valores para busca.
                        </td>
                      </tr>
                    ) : this.state.services.length > 0 ? (
                      this.state.services.map(this.renderServices)
                    ) : (
                      <tr>
                        <td
                          colSpan={11}
                          style={{
                            textAlign: 'center',
                            fontSize: 20,
                            height: 100,
                            verticalAlign: 'middle',
                          }}
                        >
                          Nenhum serviço encontrado para as condições impostas.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <RoundedPagination count={this.state.pages}
                  onChange={this.handlePagination} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
