import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import ProcessService from '../../services/ProcessService';
import ReactSwitch from 'react-switch';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

export default class AddProcess extends Component {

    constructor(props) {
        super(props);
        this.state = {
            active: true,
            code: '',
            name: '',
            description: ''
        };
    }

    save = async () => {
        const confirmation = (await Swal.fire({
            title: 'Confirmação',
            text: 'Você tem certeza que deseja adicionar este processo?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        })).value;
        if (confirmation) {
            const result = await ProcessService.add(this.state);
            if (result) {
                browserHistory.push('/process');
                toast.success('Processo criado com sucesso!');
            }
        }
    }

    render() {
        return (
            <div className="wrapper wrapper-content">
                <div className="row wrapper border-bottom white-bg page-heading animated bounceInRight" style={{ margin: 'auto', padding: '30px' }}>
                    <h2>Criar novo processo</h2><br />
                    <div className="row">
                        <div className="col-lg-6">
                            <h4><b>Ativo?</b></h4>
                            <ReactSwitch
                                checked={this.state.active}
                                onChange={active => this.setState({ active })}
                                onColor='#57b87b'
                                checkedIcon={false}
                                uncheckedIcon={false}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <h4><b>Código*</b></h4>
                            <div className="input-group m-b">
                                <span className="input-group-addon">
                                    <i className="fa fa-cogs"></i>
                                </span>
                                <input
                                    type="text"
                                    placeholder="Código"
                                    className="form-control"
                                    value={this.state.code}
                                    onChange={e => this.setState({ code: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <h4><b>Tipo*</b></h4>
                            <div className="input-group m-b">
                                <span className="input-group-addon">
                                    <i className="fa fa-cogs"></i>
                                </span>
                                <select
                                    placeholder="Tipo do Processo"
                                    className="form-control"
                                    value={this.state.type}
                                    onChange={e => this.setState({ type: e.target.value })
                                    }>
                                    <option value=''>Selecione o tipo</option>
                                    <option>Manutenção</option>
                                    <option>Apoio</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <h4><b>Descrição</b></h4>
                            <textarea
                                type="text"
                                placeholder="Descrição do Processo"
                                className="form-control"
                                rows="4"
                                value={this.state.description}
                                onChange={e => this.setState({ description: e.target.value })}
                            ></textarea>
                        </div>
                    </div>
                    <br />
                    <div className="col-lg-12" style={{ textAlign: 'center', }}>
                        <button
                            className="btn btn-success"
                            onClick={this.save}
                            style={{ margin: '2px' }}
                        >Salvar</button>
                        <button className="btn btn-danger"
                            data-dismiss="modal"
                            style={{ margin: '2px' }}
                            onClick={
                                async () => {
                                    const confirmation = (await Swal.fire({
                                        title: 'Confirmação',
                                        text: 'Você tem certeza que deseja cancelar?',
                                        type: 'warning',
                                        showCancelButton: true,
                                        confirmButtonColor: '#3085d6',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'Sim',
                                        cancelButtonText: 'Não'
                                    })).value;
                                    if (confirmation) {
                                        browserHistory.push('/process');
                                    }
                                }
                            }
                        >Cancelar</button>
                    </div>
                </div>
            </div>
        )
    }

}