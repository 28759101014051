import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import ImportArchiveService from '../services/ImportArchiveService';
import Moment from 'moment';
import LoadingModal from './LoadingModal';
import RoundedPagination from '../components/common/RoundedPagination';

export default class PcpImport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      archives: [],
      filter: '',
      page: props.params.page || 1,
      pages: 0,
      start: '',
      end: ''
    };
    const role = JSON.parse(localStorage.getItem('@omni/fast_track/user')).role;
    if (role !== 'PCP' && role !== 'ADM' && role !== 'ADM - TI')
      browserHistory.push('/dashboard');
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.filter();
  }

  async componentWillReceiveProps(nextProps) {
    this.setState({ page: nextProps.params.page || 1 }, () => {
      this.filter();
    });
  }

  getArchives = async () => {
    this.setState({ loading: true });
    const obj = await ImportArchiveService.get(this.state.page, {
      start: new  Date(this.state.start),
      end: new Date(this.state.end)
    });
    const archives = obj ? obj.result : [];
    const pages = obj ? obj.pages : 0;
    this.setState({ archives, pages, loading: false });
  }

  filter = async () => {
    if (this.state.start || this.state.end) {
      this.setState({ loading: true });
      const obj = await ImportArchiveService.filter(this.state.page, {
        start: new  Date(this.state.start),
        end: new Date(this.state.end)
      });
      const archives = obj ? obj.result : [];
      this.setState({ archives, pages: obj.pages, loading: false });
    } else this.getArchives();
  }

  clear = async () => {
    this.setState({ start: '', end: '' }, () => {
      this.getArchives();
    });
  }

  editview = () => {
    this.props.history.push('/users');
  }

  renderarchives = (archive) => {
    return (
      <tr id="identify"
          key={archive._id}
          style={{ cursor: 'pointer' }}
          onClick={() => browserHistory.push(`/pcp-import/${archive._id}`)}
      >

        <td>

          <a href='#'>
            {archive.name ? archive.name : 'Arquivo sem nome'}
          </a>
        </td>
        <td>
          {archive.number_services}
        </td>
        <td>
          {Moment(new Date(archive.date_uploaded)).locale('pt-BR').format('DD/MM/YYYY HH:mm')}
        </td>
        <td >
          {archive.uploaded_by?archive.uploaded_by.name: "Não informado"}
        </td>
        <td style={{ color: archive.status == 'Importado' ? 'green' : (archive.status == 'Cancelado' ? 'red' : 'blue') }}>
          {archive.status}
        </td>
      </tr >
    );
  }

  handleFilterClick = (e) => {
    e.preventDefault();
    this.handlePagination(e, 1);
  }

  handlePagination = (_event, value) => {
    this.setState({ page: value }, () => {
      browserHistory.push(`/pcp-archives/${this.state.page}`);
    });
  }

  render() {
    return (
      <div className="wrapper wrapper-content animated fadeInDown">
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-6">
                <h2>Arquivos PCP Importados</h2>
                <ol className="breadcrumb">
                  <li>
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="active">
                    <strong>Importar dados PCP</strong>
                  </li>
                </ol>
              </div>
              <div className="col-lg-6">
                <div
                  className='col-lg-12 row'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}>

                  <div className="text-right col-lg-12" style={{ marginTop: 10 }}>
                    <Link className="btn btn-success" to="/pcp-import"><i className="fa fa-upload"></i> Nova Importação</Link>
                  </div>
                  <div className="col-lg-6" style={{ marginTop: 15 }}>
                    A partir de
                    <div className="input-group" >
                      <span className='input-group-addon'>
                        <i className='fa fa-calendar-alt' />
                      </span>
                      <input
                        type="date"
                        className="input-sm form-control"
                        value={this.state.start}
                        onChange={e => this.setState({ start: e.target.value })}
                        style={{ backgroundColor: 'rgba(255, 255, 255, 0.835)' }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6" style={{ marginTop: 15 }}>
                    Até
                    <div className="input-group" >
                      <span className='input-group-addon'>
                        <i className='fa fa-calendar-alt' />
                      </span>
                      <input
                        type="date"
                        className="input-sm form-control"
                        value={this.state.end}
                        onChange={e => this.setState({ end: e.target.value })}
                        style={{ backgroundColor: 'rgba(255, 255, 255, 0.835)' }}
                      />
                    </div>
                  </div>
                  <div
                    className="col-lg-12"
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'space-around',
                    }}>
                    <div className='text-center col-lg-6'>
                      <button
                        className='btn btn-danger btn-block'
                        style={{ marginTop: 30 }}
                        onClick={this.clear}
                      >
                        Limpar
                      </button>
                    </div>
                    <div className='text-center col-lg-6'>
                      <button
                        className='btn btn-success btn-block'
                        style={{ marginTop: 30 }}
                        onClick={this.handleFilterClick}
                      >
                        Filtrar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper wrapper-content">
          <div className="row animated fadeInDown">
            <div className="col-lg-12 ibox-content">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      {/* <th>Ativo</th> */}
                      <th>Nome</th>
                      <th>Nº Serviços</th>
                      <th>Data de Importação</th>
                      <th>Importado por</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.loading
                     ? <tr><th colSpan={6}><div className='spinner spinner-lg' /></th></tr>
                     : this.state.archives.length ?
                     this.state.archives.map(this.renderarchives) :
                     <tr>
                       <td
                         colSpan={11}
                         style={{
                           textAlign: 'center',
                           fontSize: 20,
                           height: 100,
                           verticalAlign: 'middle',
                         }}
                       >
                         Nenhum arquivo encontrado.
                       </td>
                     </tr>}
                  </tbody>
                </table>
              </div>

              <RoundedPagination onChange={this.handlePagination}
                                 page={this.state.page}
                                 count={this.state.pages} />
            </div>
          </div>
        </div>
      </div >
    );
  }
}
