import React, { Component } from 'react';
import { Link } from 'react-router';
import '../../styles/login.css';
import UsersService from '../../services/UsersService';
import { toast, ToastContainer } from 'react-toastify';

const loginLabel = {
  fontSize: 16,
  color: '#fff',
  cursor: 'pointer',
  marginBottom: 20,
};

export default class RedefinePassword extends Component {
  constructor(props) {
    super(props);
    this.state = { email: '' };
  }

  login = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    await UsersService.redefine(this.state, async (user) => {
      toast.success('Um email com as instruções para redefinir a senha foi enviado!');
      setTimeout(() => {
        this.props.history.push('/login');
      }, 3000);
    }, (e) => {
      toast.error(e.message ? e.message : 'Erro ao redefinir a senha!');
    });
    this.setState({ loading: false });
  }

  render() {
    return (
      <div className='main container-fluid'>
        <form onSubmit={this.login}>
          <div className='loginContainer text-center animated fadeIn'>
            <img src='/img/logo.png' alt='Logo' />
            <h2>Esqueci minha senha</h2>
            <input
              type='text'
              placeholder='Informe seu email'
              value={this.state.email}
              className='form-control'
              onChange={e => this.setState({ email: e.target.value })}
            /><br />
            {
              this.state.loading
                ? <div className='btn btn-light btn-block'>
                  <div className="spinner"></div>
                </div>
                : <input
                  className='btn btn-light btn-block'
                  type='submit'
                  value='Enviar email'
                />
            }
            <br />
            <Link to='login'>
              <span style={loginLabel}>Voltar para a página de Login</span>
            </Link>
            <p><small>Powered by Criatech {new Date().getFullYear()}</small></p>
          </div>
        </form>
        <ToastContainer autoClose={3000} />
      </div>
    );
  }
}
