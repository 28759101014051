import React, { useState, useEffect } from "react";
import { Link, browserHistory } from "react-router";
import AircraftsService from "../../services/AircraftsService";
import AddAircraft from "../AddAircraft";
import EditAircraft from "../EditAircraft";
import RoundedPagination from '../../components/common/RoundedPagination';

const Aircrafts = (props) => {
  const [aircrafts, setAircrafts] = useState([]);
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(props.params.page || 1);
  const [pages, setPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedAircraft, setSelectedAircraft] = useState(null);
  const role = JSON.parse(localStorage.getItem("@omni/fast_track/user")).role;

  useEffect(() => {
    if (role === "PCP" || role === "COORDENADOR" || role === "VISUALIZADOR") {
      browserHistory.push("/dashboard");
    } else {
      getAircrafts();
    }
  }, []);

  useEffect(() => {
    browserHistory.push(`/aircrafts/${page}`);
    getAircrafts();
  }, [page]);

  useEffect(() => {
    setPage(props.params.page || 1);
  }, [props.params.page]);

  const getAircrafts = async () => {
    setLoading(true);
    const obj = await AircraftsService.get(page, filter);
    const aircrafts = obj ? obj.result : [];
    const pages = obj ? obj.pages : 0;
    setAircrafts(aircrafts);
    setPages(pages);
    setLoading(false);
  };

  const handleFilterSubmit = e => {
    e.preventDefault();
    setPage(1);
    getAircrafts();
  };

  const handlePagination = (_event, value) => {
    setPage(value);
  };

  const renderaircraft = aircraft => {
    return (
      <tr
        id="identify"
        key={aircraft._id}
        // style={{ cursor: "pointer" }}
        // data-toggle="modal"
        // data-target="#editModal"
        // onClick={() => setSelectedAircraft(aircraft._id)}
      >
        <td>
          <i
            className={`fa fa-${aircraft.active ? "check" : "times"} text-${aircraft.active ? "success" : "danger"
              }`}
          />
        </td>
        <td style={{ color: '#337ab7' }}>{`${aircraft._model.manufacturer} - ${aircraft._model.model}`}</td>
        <td>{aircraft.prefix}</td>
        <td>{
          aircraft.movimentation.map((elem) => {
            return ((!elem.endOperation) || (new Date()).getTime() >= (new Date(elem.startOperation)).getTime() && ((new Date()).getTime() <= (new Date(elem.endOperation)).getTime())) ? elem._base.name : ''
          })
        }
        </td>
      </tr>
    );
  };

  return (
    <div className="wrapper wrapper-content animated fadeInDown">
      <AddAircraft refresh={() => getAircrafts()} />
      <EditAircraft
        refresh={() => getAircrafts()}
        selected={selectedAircraft}
      />
      <div className="row wrapper border-bottom white-bg page-heading">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-6">
              <h2>Aeronaves</h2>
              <ol className="breadcrumb">
                <li>
                  <Link to="/dashboard">Home</Link>
                </li>
                <li>
                  <Link>Configurações</Link>
                </li>
                <li className="active">
                  <strong>Aeronaves</strong>
                </li>
              </ol>
            </div>
            <div className="col-lg-6">
              <div className="pull-right" style={{ paddingTop: 10 }}>
                {/* <button
                  className="btn btn-success"
                  data-toggle="modal"
                  data-target="#addModal"
                >
                  Adicionar
                </button> */}
              </div>
              <form onSubmit={handleFilterSubmit}>
                <div
                  className="input-group pull-right"
                  style={{ marginTop: 15 }}
                >
                  <input
                    type="text"
                    placeholder="Procurar Aeronave"
                    className="input-sm form-control"
                    value={filter}
                    onChange={e => setFilter(e.target.value)}
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.835)" }}
                  />
                  <span className="input-group-btn">
                    <button type="submit" className="btn btn-sm btn-success">
                      <i className="fa fa-search"></i>
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper wrapper-content">
        <div className="row">
          <div className="col-lg-12 ibox-content">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Ativo</th>
                    <th>Modelo</th>
                    <th>Marcas</th>
                    <th>Base</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <th colSpan={6}>
                        <div className="spinner spinner-lg" />
                      </th>
                    </tr>
                  ) : (
                    aircrafts.map(renderaircraft)
                  )}
                </tbody>
              </table>
            </div>

            <RoundedPagination onChange={handlePagination} 
                               page={page}
                               count={pages} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aircrafts;
