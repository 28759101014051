import React, { useEffect, useState } from 'react';
import { browserHistory, Link } from 'react-router';
import EvaluationService from '../../services/EvaluationService';
import LoadingModal from '../LoadingModal';
import './styles.css';

export default function EvaluationDetails(props) {
  const [state, setState] = useState({
    loading: true
  });
  const [topics, setTopics] = useState();

  const verifyUserRole = () => {
    const role = JSON.parse(localStorage.getItem('@omni/fast_track/user')).role;
    if (role !== 'ADM' && role !== 'ADM - TI' && role !== 'AVALIADOR')
      browserHistory.push('/dashboard');
  };

  useEffect(() => {
    verifyUserRole();
    if (props.params.id) fetchData(props.params.id);
  }, [props.params.id]);

  const fetchData = async (flightId) => {
    window.scrollTo(0, 0);
    setState((oldState) => ({ ...oldState, loading: true }));
    const fetchEvaluation = await EvaluationService.getById(flightId);

    if (fetchEvaluation) {
      setTopics(() => fetchEvaluation.topics);
      setState((oldState) => ({
        ...oldState,
        ...fetchEvaluation,
        loading: false,
      }));
    }
  };

  const handleClick = async () => {
    browserHistory.goBack();
  };

  const result = React.useMemo(() => topics ? topics.reduce((total, actual) => {
    return total + (actual && actual.total || 0)
  }, 0) : 0, [topics]);

  const getResultRole = React.useCallback(() => {
    if (state.result <= 42) return "MEC I";
    if (state.result <= 65) return "MEC II";
    if (state.result <= 84) return "MEC III";
    if (state.result <= 96) return "MEC IV";

    return "MEC Master"
  }, [state.result]);

  const handleChangeSelect = React.useCallback((topicIndex, avaliation) => {
    setTopics((oldTopics) => oldTopics.map((item, index) => {
      if (index === topicIndex) {
        const topic = Object.assign({}, topics[topicIndex]);

        topic.avaliation = avaliation;
        topic.total = avaliation * topic.weight;
        return topic
      }
      return item
    }))
  }, [topics, setTopics])

  return (
    <>
      {state.loading ? (
        <div className="col-lg-12">
          <div className="row">
            <LoadingModal visible={state.loading} />
          </div>
        </div>
      ) : (
        <div>
          <div className="wrapper wrapper-content animated fadeInDown">
            <div className="row wrapper border-bottom white-bg page-heading">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-8">
                    <h2>Registro de avaliação de {state.evaluated ? state.evaluated.name : "Não informado"}</h2>
                    <ol className="breadcrumb">
                      <li>
                        <Link to="/mechanics-evaluation">Registro de avaliações</Link>
                      </li>
                      <li className="active">
                        <strong>{state.evaluated ? state.evaluated.name : "Não informado"}</strong>
                      </li>
                    </ol>
                  </div>
                  <div className="col-lg-4"></div>
                </div>
              </div>
              <h4
                className="col-lg-12"
                style={{
                  borderBottom: '1.5px solid rgba(0,0,0,0.07)',
                  textAlign: 'center',
                  marginTop: '20px',
                  marginBottom: '20px',
                  padding: '10px',
                  color: 'red',
                  fontSize: '18px',
                }}
              >
                DADOS DO REGISTRO
              </h4>
              <div className="col-lg-12" style={{ paddingTop: 10 }}>
                <div className="row">
                  <div className="col-lg-12" style={{ marginBottom: 16, fontSize: 14 }}>
                    <span>
                      <b>Pontuação: </b> {state.result ? `${state.result} (${getResultRole()}` : 'Não definido'})
                    </span>
                  </div>
                  <div className="col-lg-6">
                    <h4>
                      <b>Avaliado</b>
                    </h4>
                    <div className="input-group m-b">
                      <span className="input-group-addon">
                        <i className="fa fa-user"></i>
                      </span>
                      <input
                        className="form-control"
                        value={state.evaluated ? state.evaluated.name : 'Não informado'}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <h4>
                      <b>Avaliador</b>
                    </h4>
                    <div className="input-group m-b">
                      <span className="input-group-addon">
                        <i className="fa fa-user"></i>
                      </span>
                      <input
                        className="form-control"
                        value={state.evaluator.name || 'Não definido'}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <h4
                className="col-lg-12"
                style={{
                  borderBottom: '1.5px solid rgba(0,0,0,0.07)',
                  textAlign: 'center',
                  marginTop: '20px',
                  marginBottom: '20px',
                  padding: '10px',
                  color: 'red',
                  fontSize: '18px',
                }}
              >
                TÓPICOS
              </h4>
              <div className='wrapper'>
                {topics && topics.length === 0 ?
                  (
                    <span style={{ width: '100%', textAlign: 'center' }}>
                      Nenhum relatório encontrado
                    </span>
                  ) : (
                    <table className='table table-striped' cellSpacing={160}>
                      <thead>
                        <tr>
                          <th scope='col' className='td-pr'>Total</th>
                          <th scope='col' className='td-pr'>Tópico</th>
                          <th scope='col' className='td-pr'>Pontuação</th>
                        </tr>
                      </thead>
                      <tbody className='evaluation'>
                        {topics.map((topic, index) => (
                          <tr>
                            <td scope="row" className='td-pr td-center'>
                              <b>
                                {topic.total}
                              </b>
                            </td>
                            <td className='td-pr'>
                              {topic.title}
                            </td>
                            <td className='td-pr td-center'>
                              {topic.avaliation}/{topic.maximumCriteria}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td><b>{state.result}/100</b></td>
                        </tr>
                      </tfoot>
                    </table>
                  )
                }
              </div>
              <div className='col-lg-12' style={{ textAlign: 'center', marginTop: '8px' }}>
                <button
                  className='btn btn-success'
                  onClick={handleClick}
                >
                  Voltar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
