import React, { Component } from "react";
import { Link, browserHistory } from "react-router";
import AircraftModelsService from "../services/AircraftModelsService";
import AddAircraftModel from "./AddAircraftModel";
import EditAircraftModel from "./EditAircraftModel";
import RoundedPagination from '../components/common/RoundedPagination';

export default class AircraftModels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aircraftModels: [],
      filter: "",
      page: props.params.page || 1,
      pages: 0
    };
    const role = JSON.parse(localStorage.getItem("@omni/fast_track/user")).role;
    if (role === "PCP" || role === "COORDENADOR" || role === "VISUALIZADOR")
      browserHistory.push("/dashboard");
  }

  async componentDidMount() {
    this.filter()
  }

  async componentWillReceiveProps(nextProps) {
    this.setState({ page: nextProps.params.page || 1 }, () => {
      this.filter();
    });
  }

  getAircraftModels = async () => {
    this.setState({ loading: true });
    const obj = await AircraftModelsService.get(this.state.page);
    const aircraftModels = obj ? obj.result : [];
    const pages = obj.pages;
    this.setState({ aircraftModels, pages, loading: false });
  };

  filter = async () => {
    if (this.state.filter.length > 0) {
      this.setState({ loading: true });
      const obj = await AircraftModelsService.get(1, this.state.filter);
      const aircraftModels = obj ? obj.result : [];
      this.setState({ aircraftModels, loading: false });
    } else this.getAircraftModels();
  };

  renderaircraftModel = aircraftModel => {
    return (
      <tr
        id="identify"
        //key={aircraftModel._id}
        data-toggle="modal"
        data-target="#editModal"
        onClick={() => this.setState({ selectedModel: aircraftModel._id })}
        style={{ cursor: "pointer" }}
      >
        <td>
          <i
            className={`fa fa-${
              aircraftModel.active ? "check" : "times"
              } text-${aircraftModel.active ? "success" : "error"}`}
          />
        </td>
        <td style={{ color: '#337ab7' }}>{aircraftModel.manufacturer}</td>
        <td style={{ color: '#337ab7' }}>{aircraftModel.model}</td>
      </tr>
    );
  };

  handleFilterSubmit = (e) => {
    e.preventDefault();
    this.handlePagination(e, 1);
  }

  handlePagination = (_event, value) => {
    this.setState({ page: value }, () => {
      browserHistory.push(`/aircraft-models/${this.state.page}`);
    });
  }

  render() {
    return (
      <div className="wrapper wrapper-content animated fadeInDown">
        <AddAircraftModel refresh={() => this.getAircraftModels()} />
        <EditAircraftModel
          selected={this.state.selectedModel}
          refresh={() => this.getAircraftModels()}
        />
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-6">
                <h2>Modelos de Aeronave</h2>
                <ol className="breadcrumb">
                  <li>
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li>
                    <Link>Configurações</Link>
                  </li>
                  <li className="active">
                    <strong>Modelos de Aeronave</strong>
                  </li>
                </ol>
              </div>
              <div className="col-lg-6">
                <div className="pull-right" style={{ paddingTop: 10 }}>
                  <button
                    className="btn btn-success"
                    data-toggle="modal"
                    data-target="#addModal"
                  >
                    Adicionar
                  </button>
                </div>
                <form onSubmit={this.handleFilterSubmit}>
                  <div
                    className="input-group pull-right"
                    style={{ marginTop: 15 }}
                  >
                    <input
                      type="text"
                      placeholder="Procurar Modelo de Aeronave"
                      className="input-sm form-control"
                      value={this.state.filter}
                      onChange={e => this.setState({ filter: e.target.value })}
                      style={{ backgroundColor: "rgba(255, 255, 255, 0.835)" }}
                    />
                    <span className="input-group-btn">
                      <button type="submit" className="btn btn-sm btn-success">
                        <i className="fa fa-search"></i>
                      </button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper wrapper-content">
          <div className="row animated fadeInDown">
            <div className="col-lg-12 ibox-content">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Ativo</th>
                      <th>Fabricante</th>
                      <th>Modelo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.loading ? (
                      <tr>
                        <th colSpan={6}>
                          <div className="spinner spinner-lg" />
                        </th>
                      </tr>
                    ) : (
                        this.state.aircraftModels.map(this.renderaircraftModel)
                      )}
                  </tbody>
                </table>
              </div>

              <RoundedPagination onChange={this.handlePagination}
                                 page={this.state.page}
                                 count={this.state.pages} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
