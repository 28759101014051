import React, { Component, createElement } from 'react';
import { Link, browserHistory } from 'react-router';
import AircraftService from '../../services/AircraftsService';
import AircraftModelsService from '../../services/AircraftModelsService';
import BaseService from '../../services/BasesService';
import Moment from 'moment';
import FlightService from '../../services/FlightService';
import LoadingModal from '../LoadingModal';
import Swal from 'sweetalert2';
import { HeatMapGrid } from 'react-grid-heatmap';
import { toast } from 'react-toastify';
import './styles.css';

export default class EngineeringStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pages: 1,
      page: 1,
      base: '',
      aircraft: '',
      aircraft_model: '',
      aircraft_models: [],
      filter_aircrafts: [],
      filter_bases: [],
      search_start: '',
      search_end: '',
      flights: [],
      status: 1,
      loading: true,
      searching: true,
      data: [],
      xAxis: [],
      yAxis: [],
      airplaneToHeatMap: [],
      empty: true,
    };

    const role = JSON.parse(localStorage.getItem('@omni/fast_track/user')).role;
    if (role !== 'ADM' && role !== 'ADM - TI' && role !== 'ENGENHARIA')
      browserHistory.push('/dashboard');
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ loading: true });
    let flight_date = Moment(this.props.params.date);
    const bases = await BaseService.getAll();
    const filter_aircrafts = await AircraftService.getAll();
    const fetchedFlights = await FlightService.filter(
      {
        search_start: Moment().startOf('month'),
        search_end: Moment().endOf('month'),
      },
      1,
      1
    );
    const aircraft_models = await AircraftModelsService.getAll();

    await this.setState({
      filter_bases: bases ? bases : [],
      bases,
      aircraft_models,
      flight_date: this.props.params.date ? flight_date : null,
      filter_aircrafts: filter_aircrafts ? filter_aircrafts : [],
      airplaneToHeatMap: filter_aircrafts ? filter_aircrafts : [],
      loading: false,
      flights: fetchedFlights ? fetchedFlights.result : [],
      search_start: Moment().startOf('month'),
      search_end: Moment().endOf('month'),
    });
  }

  getServices = async (props) => {
    const obj = await FlightService.filter(props, 1, 1);
    const flights = obj ? obj.result : [];
    this.setState({ flights }, this.handleHeatMap);
  };

  renderBasesOptions = (base) => (
    <option key={base._id} value={base._id}>
      {`${base.name}`} ({`${base.code}`}){' '}
    </option>
  );

  renderAircraftsOptions = (aircraft) => (
    <option
      key={aircraft._id}
      value={aircraft._id}
    >{`${aircraft.prefix}  (${aircraft._model.manufacturer} - ${aircraft._model.model})`}</option>
  );

  renderMonthOptions = (month) => (
    <option
      key={month.value}
      value={month.value}
    >{`${month.month}`}</option>
  );

  renderYearOptions = (year) => (
    <option
      key={year.year}
      value={year.year}
    >{`${year.year}`}</option>
  );

  renderAircraftModelsOptions = (aircraft_model) => (
    <option
      key={aircraft_model._id}
      value={aircraft_model._id}
    >{`${aircraft_model.manufacturer} - ${aircraft_model.model}`}</option>
  );

  handleClear = async () => {
    let response;

    if (this.state.aircraft) {
      response = await AircraftService.getAll();
    }

    await this.setState({
      aircraft: '',
      aircraft_model: '',
      base: '',
      filter_aircrafts: response ? response : this.state.filter_aircrafts,
      airplaneToHeatMap: response ? response : this.state.filter_aircrafts,
      search_start: Moment().startOf('month'),
      search_end: Moment().endOf('month'),
      month: '',
      year: '',
      searching: true,
    });

    this.getServices({
      search_start: Moment().startOf('month'),
      search_end: Moment().endOf('month'),
      status: this.state.status
    });
  };

  handleStartDateChange = (event) => {
    this.setState({
      search_start: event.target.value,
    })
  }

  handleHeatMap = () => {
    const startDate = this.state.search_start
      ? Moment(this.state.search_start).utcOffset('0000').startOf('day')
      : Moment().date(1);

    const endDate = this.state.search_end ? Moment(this.state.search_end).utcOffset('0000').endOf('day') : Moment().endOf('month');
    const days = [];
    const searchDays = [];
    const month = endDate.month();

    while (startDate < endDate) {
      days.push(
        (startDate.date() < 10 ? '0' + startDate.date() : startDate.date()) +
        '/' +
        (startDate.month() < 9 ? '0' + (startDate.month() + 1) : startDate.month() + 1)
      );
      searchDays.push(Moment(startDate));
      startDate.add(1, 'days');
    }

    const x = this.state.airplaneToHeatMap.map((aircraft) => aircraft.prefix);
    const data = new Array(days.length)
      .fill(0)
      .map(() => new Array(x.length).fill(0).map(() => { return { value: '-', note: 'Não operou' } }));
    console.log('flights: ', this.state.flights);
    this.state.flights.forEach((flight) => {

      const column = x.indexOf(flight.aircraft.prefix);

      const row = searchDays.findIndex((day) => {
        return day.date() === Moment(flight.flight_date).date();
      });

      data[row][column] = {
        value: (1 - (flight.flights ? flight.downloads / flight.flights : 1)).toFixed(2),
        note: flight.note ? flight.note : 'Sem observação',
        flight_id: flight._id
      }
    });

    this.setState({
      data,
      xAxis: x,
      yAxis: days,
      searching: false,
      empty: false,
    });
  };

  getFillColor = (ratioValue) => {
    if (ratioValue <= 0.25) {
      return 'rgba(26,179,148, 0.5)'
    } else if (ratioValue > 0.25 && ratioValue <= 0.5) {
      return 'rgba(255,193,7)'
    } else if (ratioValue === '-') {
      return '#eee'
    } else {
      return 'rgba(237,85,104)'
    }
  }

  renderHeatMap = () => {
    return (
      <HeatMapGrid
        data={this.state.data}
        xLabels={this.state.xAxis}
        yLabels={this.state.yAxis}
        cellRender={(x, y, doc) => {
          return (
            <div
              onClick={() => doc.value !== '-' && browserHistory.push(`/engineering/flight/${doc.flight_id}`)}
              title={doc.note}
              style={{
                cursor: 'pointer',
                backgroundColor: this.getFillColor(doc.value),
                fontSize: '1.4rem'
              }}
            >
              {doc.value}
            </div>
          );
        }}
        cellHeight="36px"
        yLabelsStyle={(index) => ({
          marginTop: index === 0 ? '2px' : 0,
          padding: '0 8px',
        })}
        xLabelsStyle={() => ({
          width: '80px',
          height: '40px',
        })}
      />
    );
  };

  handleClick = async () => {

    const confirmation = (await Swal.fire({
      title: 'Aviso',
      text: 'Exportar para excel estará disponível em breve.',
      type: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Retornar',
    })).value;

    if (confirmation) {

    }
  }

  handleSearch = async () => {
    this.setState({
      searching: true,
    });

    if (Moment(this.state.search_start).utcOffset('0000').startOf('day') > Moment(this.state.search_end).utcOffset('0000').endOf('day')) {
      toast.error("Por favor, insira uma data final maior ou igual à inicial", { autoClose: 4000 });
      return;
    }

    const { base, aircraft_model, aircraft } = this.state;

    if (base || aircraft_model || aircraft) {
      const response = await AircraftService.filter(this.state.page, { base, _model: aircraft_model, aircraft }, 1);
      this.setState({
        airplaneToHeatMap: response ? response.result : this.state.airplaneToHeatMap,
      });
    } else {
      this.setState({ airplaneToHeatMap: this.state.filter_aircrafts });
    }

    if (this.state.airplaneToHeatMap.length === 0) {
      this.setState({
        empty: false
      })
      return;
    } else {
      const data = {
        aircraft: this.state.aircraft,
        aircraft_model: this.state.aircraft_model,
        base: this.state.base,
        search_start: Moment(this.state.search_start).utcOffset('0000').startOf('day'),
        search_end: Moment(this.state.search_end).utcOffset('0000').endOf('day'),
        status: this.state.status,
      };

      this.getServices(data);
    }

  };

  render() {
    return (
      <div>
        <div
          className="wrapper wrapper-content animated fadeInDown"
          style={{ paddingBottom: '0' }}
        >
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-8 col-md-12">
                  <h2>Estatísticas de Downloads x Voos HUMS</h2>
                  <ol className="breadcrumb">
                    <li>
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="active">
                      <strong>Estatísticas HUMS</strong>
                    </li>
                  </ol>
                </div>
                <div className="col-lg-4 col-md-12">
                  <div className="col-md-12" style={{ paddingTop: 18, paddingLeft: 0, paddingRight: '0px' }}>
                    <button
                      className="btn btn-primary btn-block"
                      onClick={this.handleClick}
                    >
                      <i className="fa fa-file-excel"></i> Exportar Excel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12" style={{ paddingTop: 20 }}>
              <div className="row">

                <div className="col-xl-4 col-lg-6 col-md-12">
                  Base
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-home"></i>
                    </span>
                    <select
                      className="form-control"
                      value={this.state.base}
                      onChange={(e) => {
                        this.setState({ base: e.target.value });
                      }}
                    >
                      <option value="">Todas as bases</option>
                      {this.state.filter_bases.map(this.renderBasesOptions)}
                    </select>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-12">
                  Modelo de Aeronave
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-plane"></i>
                    </span>
                    <select
                      className="form-control"
                      value={this.state.aircraft_model}
                      onChange={(e) =>
                        this.setState({
                          aircraft_model: e.target.value
                        })
                      }
                    >
                      <option value="">Todos os modelos de aeronave</option>
                      {this.state.aircraft_models && this.state.aircraft_models.map(
                        this.renderAircraftModelsOptions
                      )}
                    </select>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-12">
                  Aeronave
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-plane"></i>
                    </span>
                    <select
                      className="form-control"
                      value={this.state.aircraft}
                      onChange={(e) => {
                        this.setState({ aircraft: e.target.value });
                      }}
                    >
                      <option value="">Todas as aeronaves</option>
                      {this.state.airplaneToHeatMap.map(
                        this.renderAircraftsOptions
                      )}
                    </select>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-12">
                  Início do período
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-calendar"></i>
                    </span>
                    <input
                      type="date"
                      className="form-control"
                      value={this.state.search_start}
                      onChange={(e) =>
                        this.setState({
                          search_start: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-12">
                  Fim do período
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-calendar"></i>
                    </span>
                    <input
                      type="date"
                      className="form-control"
                      value={this.state.search_end}
                      onChange={(e) =>
                        this.setState({
                          search_end: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-12">
                  <div
                    className="col-lg-6 col-md-12"
                    style={{ paddingTop: 18, paddingLeft: 0, paddingRight: 0 }}
                  >
                    <button
                      onClick={this.handleClear}
                      className="btn btn-danger btn-block"
                    >
                      Limpar Filtro
                    </button>
                  </div>
                  <div
                    className="col-lg-6 col-md-12"
                    id="generate"
                    style={{
                      paddingTop: 18,
                      paddingLeft: '8px',
                      paddingRight: 0,
                    }}
                  >
                    <button
                      onClick={this.handleSearch}
                      className="btn btn-success btn-block"
                    >
                      Gerar Heatmap
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper wrapper-content">
          <div className="row animated fadeInDown">
            <div style={{ overflowX: 'scroll' }} id="heatmap">
              {!this.state.searching && this.renderHeatMap()}
              {!this.state.empty && this.state.airplaneToHeatMap.length === 0 && <center style={{ fontSize: '20px' }}>Nenhuma aeronave encontrada</center>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
