import React, { Component } from "react";
import ReactSwitch from "react-switch";
import AircraftsService from "../services/AircraftsService";
import AircraftModelsService from "../services/AircraftModelsService";
import BaseService from "../services/BasesService";
import { toast } from "react-toastify";
import LoadingModal from "./LoadingModal";
import Swal from "sweetalert2";
import $ from "jquery";
import { browserHistory } from "react-router";

export default class EditAircraft extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
      prefix: "",
      _model: "",
      sn: "",
      backup: false,
      observation: "",
      models: [],
      pcp_base: null,
      bases: [],
    };
  }

  async componentDidMount() {
    const models = await AircraftModelsService.getAll();
    this.setState({ models: models ? models : [] });
    const bases = await BaseService.getAll();
    this.setState({ bases: bases ? bases : [] });
  }

  async componentWillReceiveProps(nextProps) {
    this.setState({ loading: true });
    if (nextProps.selected) {
      let aircraft = await AircraftsService.getById(nextProps.selected);
      if (aircraft) {
        if (aircraft._model && aircraft._model._id) {
          aircraft._model = aircraft._model._id;
          this.setState({ ...aircraft });  
        } else {
          this.setState({ ...aircraft });
        }
      }
      else {
        $("#editModal").removeClass("show").addClass("hide");
        $(".modal-backdrop").removeClass("show").addClass("hide");
      }
    }
    this.setState({ loading: false });
  }

  save = async () => {
    const confirmation = (
      await Swal.fire({
        title: "Confirmação",
        text:
          "Você tem certeza que deseja salvar as alterações a esta aeronave?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      })
    ).value;
    if (confirmation) {
      const result = await AircraftsService.edit(this.state._id, {
        active: this.state.active,
        _model: this.state._model,
        prefix: this.state.prefix,
        sn: this.state.sn,
        backup: this.state.backup,
        observation: this.state.observation,
        pcp_base: this.state.pcp_base,
      });
      if (result) {
        toast.success("Aeronave atualizada!", { autoClose: 1000 });
        this.props.refresh();
        $("#editModal").removeClass("show").addClass("hide");
        $(".modal-backdrop").removeClass("show").addClass("hide");
      }
    }
  };

  renderModelOptions = (model) => (
    <option
      value={model._id}
    >{`${model.manufacturer} - ${model.model}`}</option>
  );

  renderBaseOptions = (base) => (
    <option value={base._id}>{`${base.code}`}</option>
  );

  render() {
    return (
      <div
        ref={(e) => (this.modal = e)}
        className="modal inmodal"
        id="editModal"
      >
        <LoadingModal visible={this.state.loading} />
        <div className="modal-dialog animated bounceInRight">
          <div
            className="modal-content row wrapper border-bottom white-bg page-heading"
            style={{ margin: "auto", padding: "30px" }}
          >
            <div className="modal-header">
              <h2>Editar Aeronave</h2>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h4>
                  <b>Ativo?</b>
                </h4>
                <ReactSwitch
                  checked={this.state.active}
                  onChange={(active) => this.setState({ active })}
                  onColor="#57b87b"
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h4>
                  <b>Marcas*</b>
                </h4>
                <div className="input-group m-b">
                  <span className="input-group-addon">
                    <i className="fa fa-plane"></i>
                  </span>
                  <input
                    type="text"
                    placeholder="Marcas"
                    className="form-control"
                    value={this.state.prefix}
                    onChange={(e) => this.setState({ prefix: e.target.value })}
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <h4>
                  <b>Modelo*</b>
                </h4>
                <div className="input-group m-b">
                  <span className="input-group-addon">
                    <i className="fa fa-plane"></i>
                  </span>
                  <select
                    className="form-control"
                    value={this.state._model}
                    onChange={(e) => this.setState({ _model: e.target.value })}
                  >
                    <option value="" disabled>
                      Selecione um modelo
                    </option>
                    {this.state.models.map(this.renderModelOptions)}
                  </select>
                </div>
              </div>
              {/* <div className="col-lg-12">
                                <h4><b>Base</b></h4>
                                <div className="input-group m-b">
                                    <span className="input-group-addon">
                                        <i className="fa fa-home"></i>
                                    </span>
                                    <select
                                        className="form-control"
                                        value={this.state.pcp_base}
                                        onChange={e => this.setState({ pcp_base: e.target.value })}
                                    >
                                        <option value={null}>Selecione uma base</option>
                                        {this.state.bases.map(this.renderBaseOptions)}
                                    </select>
                                </div>
                            </div> */}
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h4>
                  <b>S/N*</b>
                </h4>
                <div className="input-group m-b">
                  <span className="input-group-addon">
                    <i className="fa fa-envelope"></i>
                  </span>
                  <input
                    type="text"
                    placeholder="Número Serial"
                    className="form-control"
                    value={this.state.sn}
                    onChange={(e) => this.setState({ sn: e.target.value })}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <h4>
                  <b>Backup?</b>
                </h4>
                <ReactSwitch
                  checked={this.state.backup}
                  onChange={(backup) => this.setState({ backup })}
                  onColor="#57b87b"
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h4>
                  <b>Observações</b>
                </h4>
                <textarea
                  className="form-control"
                  placeholder="Observações"
                  value={this.state.observation}
                  onChange={(e) =>
                    this.setState({ observation: e.target.value })
                  }
                ></textarea>
              </div>
            </div>
            <br />
            <div className="col-lg-12" style={{ textAlign: "center" }}>
              <button
                data-target="#editModal"
                className="btn btn-success"
                onClick={this.save}
                style={{ margin: "2px" }}
              >
                Salvar
              </button>
              <button
                className="btn btn-danger"
                data-dismiss="modal"
                style={{ margin: "2px" }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
