/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Link } from 'react-router';
import $ from 'jquery';

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.user = JSON.parse(localStorage.getItem('@omni/fast_track/user'));
  }

  componentWillMount = () => { };

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }

  secondLevelActive(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1
      ? 'nav nav-second-level collapse in'
      : 'nav nav-second-level collapse';
  }

  hideDropdownContent() {
    $('.dropdown-content').hide();
    $('.dropdown-icon')
      .removeClass('fa-chevron-down')
      .addClass('fa-chevron-right');
  }

  render() {
    return (
      <nav
        className='navbar-default navbar-static-side'
        role='navigation'
        style={{
          background: '#192a39',
          position: 'absolute',
          display: 'block',
        }}
      >
        <div className='sidebar-collapse'>
          <ul className='nav metismenu' id='side-menu' ref='menu'>
            <li className='nav-header'>
              <div className='profile-element'>
                <img
                  src='https://statusfrota.omnibrasil.com.br/assets/file/logo2.png'
                  alt='Omni PCP'
                  style={{ width: '100%', margin: '0px' }}
                />
              </div>
              <div className='logo-element'>
                <span></span>
                <img
                  src='https://statusfrota.omnibrasil.com.br/assets/file/logo-pequena.png'
                  alt='Omni PCP'
                  style={{ width: '70%' }}
                />
              </div>
            </li>
            {this.user.role === 'ADM - TI' ||
              this.user.role === 'ADM' ||
              this.user.role === 'COORDENADOR' ||
              this.user.role === 'VISUALIZADOR' ||
              (this.user.role === 'AUDITOR MOSA' &&
                this.user.enable_maintenance_web)
              ? [
                <li
                  id='dashboard'
                  onClick={this.hideDropdownContent}
                  className={this.activeRoute('/dashboard')}
                >
                  <Link to='/dashboard'>
                    <i className='fa fa-tachometer-alt'></i>{' '}
                    <span className='nav-label'>Dashboard</span>
                  </Link>
                </li>,
              ]
              : null}
            {this.user.role === 'ADM - TI' || this.user.role === 'ADM' ? (
              <li className='dropdown'>
                <a href='#' className='dropdown-label'>
                  <i className='fa fa-industry' />
                  <span className='nav-label'>Qualidade</span>
                  <i
                    className='dropdown-icon fa fa-chevron-right'
                    style={{
                      right: '10%',
                      position: 'absolute',
                      fontSize: 10,
                      top: '50%',
                      marginTop: -5,
                    }}
                  ></i>
                </a>
                <ul className='dropdown-content nav metismenu'>
                  <li
                    className={this.activeRoute('/mosa_management')}
                    style={{ paddingLeft: 10 }}
                  >
                    <Link
                      to={{
                        pathname: '/mosa_managements',
                        state: { clearFilter: true },
                      }}
                    >
                      <i className='fa fa-file'></i>{' '}
                      <span className='nav-label'>Gestão MOSA</span>
                    </Link>
                  </li>
                  <li
                    className={this.activeRoute('/pcp-archives')}
                    style={{ paddingLeft: 10 }}
                  >
                    <Link to='/pcp-archives'>
                      <i className='fa fa-cloud-upload-alt'></i>{' '}
                      <span className='nav-label'>Importar Próx. Mnt</span>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}

            {this.user.role === 'ADM - TI' ||
              this.user.role === 'ADM' ||
              this.user.role === 'MECÂNICO' ||
              this.user.role === 'COORDENADOR' ||
              (this.user.role === 'AUDITOR MOSA' &&
                this.user.enable_maintenance_web) ? (
              <li className='dropdown'>
                <a href='#' className='dropdown-label'>
                  <i className='fa fa-wrench' />
                  <span className='nav-label'>Manutenção</span>
                  <i
                    className='dropdown-icon fa fa-chevron-right'
                    style={{
                      right: '10%',
                      position: 'absolute',
                      fontSize: 10,
                      top: '50%',
                      marginTop: -5,
                    }}
                  ></i>
                </a>
                <ul className='nav dropdown-content metismenu'>
                  <li
                    className={
                      this.activeRoute('/management/') ||
                      this.activeRoute('/managements/bases') ||
                      this.activeRoute('/managements')
                    }
                    style={{ paddingLeft: 10 }}
                  >
                    <Link to='/managements/bases'>
                      <i className='fa fa-tasks'></i>{' '}
                      <span className='nav-label'>Gerenciamento de Bases</span>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}
            {this.user.role === 'ADM - TI' ||
              this.user.role === 'ADM' ||
              this.user.role === 'AVALIADOR' ? (
              <li className='dropdown'>
                <a href='#' className='dropdown-label'>
                  <i className='fa fa-trophy' />
                  <span className='nav-label'>Avaliação</span>
                  <i
                    className='dropdown-icon fa fa-chevron-right'
                    style={{
                      right: '10%',
                      position: 'absolute',
                      fontSize: 10,
                      top: '50%',
                      marginTop: -5,
                    }}
                  ></i>
                </a>
                <ul className='nav dropdown-content metismenu'>
                  <li
                    className={
                      this.activeRoute('/mechanics-evaluation')
                    }
                    style={{ paddingLeft: 10 }}
                  >
                    <Link to='/mechanics-evaluation'>
                      <i className='fa fa-tasks'></i>{' '}
                      <span className='nav-label'>Mecânicos</span>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}

            {this.user.role === 'ADM - TI' ||
              this.user.role === 'ADM' ||
              this.user.role === 'ENGENHARIA' ? (
              <li className='dropdown'>
                <a href='#' className='dropdown-label'>
                  <i className='fas fa-hard-hat' />
                  <span className='nav-label'>Engenharia</span>
                  <i
                    className='dropdown-icon fa fa-chevron-down'
                    style={{
                      right: '10%',
                      position: 'absolute',
                      fontSize: 10,
                      top: '50%',
                      marginTop: -5,
                    }}
                  ></i>
                </a>
                <ul className='nav metismenu dropdown-content'>
                  <li
                    className={this.activeRoute('/flights_management')}
                    style={{ paddingLeft: 10 }}
                  >
                    <Link to='/flights_management'>
                      <i className='fas fa-plane'></i>{' '}
                      <span className='nav-label'>
                        Gerenciamento de Download HUMS
                      </span>
                    </Link>
                  </li>
                  <li
                    className={this.activeRoute('/flights_report')}
                    style={{ paddingLeft: 10 }}
                  >
                    <Link to='/flights_report'>
                      <i className='fas fa-file'></i>{' '}
                      <span className='nav-label'>Relatório de voos</span>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}

            {this.user.role === 'PCP'
              ? [
                <li
                  id='pcp-archives'
                  className={this.activeRoute('/pcp-archives')}
                >
                  <Link to='/pcp-archives'>
                    <i className='fa fa-cloud-upload-alt'></i>{' '}
                    <span className='nav-label'>Importar Próx. Mnt</span>
                  </Link>
                </li>,
              ]
              : null}

            {this.user.role === 'ADM - TI' ? (
              <li className='dropdown'>
                <a href='#' className='dropdown-label'>
                  <i className='fa fa-upload' />
                  <span className='nav-label'>PCP</span>
                  <i
                    className='fa fa-chevron-down dropdown-icon'
                    style={{
                      right: '10%',
                      position: 'absolute',
                      fontSize: 10,
                      top: '50%',
                      marginTop: -5,
                    }}
                  ></i>
                </a>
                <ul className='nav metismenu dropdown-content'>
                  <li
                    className={this.activeRoute('/pcp-archives')}
                    style={{ paddingLeft: 10 }}
                  >
                    <Link to='/pcp-archives'>
                      <i className='fa fa-cloud-upload-alt'></i>{' '}
                      <span className='nav-label'>Importar Próx. Mnt</span>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}

            {this.user.role === 'ADM - TI' ||
              this.user.role === 'ADM' ||
              this.user.role === 'ENGENHARIA' ||
              this.user.role === 'MECÂNICO' ||
              this.user.role === 'COORDENADOR' ? (
              <li className='dropdown'>
                <a href='#' className='dropdown-label'>
                  <i className='fa fa-file' />
                  <span className='nav-label'>Relatórios</span>
                  <i
                    className='fa fa-chevron-down dropdown-icon'
                    style={{
                      right: '10%',
                      position: 'absolute',
                      fontSize: 10,
                      top: '50%',
                      marginTop: -5,
                    }}
                  ></i>
                </a>
                <ul className='nav metismenu dropdown-content'>
                  {(this.user.role === 'ADM - TI' ||
                    this.user.role === 'ADM') && (
                      <>
                        <li
                          className={this.activeRoute('/mosa_reports')}
                          style={{ paddingLeft: 10 }}
                        >
                          <Link to='/mosa_reports'>
                            <i className='fas fa-file-excel'></i>{' '}
                            <span className='nav-label'>
                              &nbsp;&nbsp;Relatório MOSA
                            </span>
                          </Link>
                        </li>
                        <li
                          className={this.activeRoute('/mechanical_reports')}
                          style={{ paddingLeft: 10 }}
                        >
                          <Link to='/mechanical_reports'>
                            <i className='fas fa-file-excel'></i>{' '}
                            <span className='nav-label'>
                              &nbsp;&nbsp;Pend. MOSA
                            </span>
                          </Link>
                        </li>
                      </>
                    )}
                  {this.user.role !== 'AUDITOR MOSA' && (
                    <li
                      className={this.activeRoute('/reports')}
                      style={{ paddingLeft: 10 }}
                    >
                      <Link to='/reports'>
                        <i className='fas fa-file-excel'></i>{' '}
                        <span className='nav-label'>
                          &nbsp;&nbsp;Relatório de Progr.
                        </span>
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            ) : null}

            {this.user.role === 'ADM - TI' || this.user.role === 'ADM' ? (
              <li className='dropdown'>
                <a href='#' className='dropdown-label'>
                  <i className='fa fa-cogs' />
                  <span className='nav-label'>Configurações</span>
                  <i
                    className='fa fa-chevron-down dropdown-icon'
                    style={{
                      right: '10%',
                      position: 'absolute',
                      fontSize: 10,
                      top: '50%',
                      marginTop: -5,
                    }}
                  ></i>
                </a>
                <ul className='nav metismenu dropdown-content'>
                  <li
                    className={this.activeRoute('/users')}
                    aria-expanded='false'
                    style={{ paddingLeft: 10 }}
                  >
                    <Link to='/users'>
                      <i className='fa fa-users'></i>{' '}
                      <span className='nav-label'>&nbsp;&nbsp;Usuários</span>
                    </Link>
                  </li>
                  <li
                    className={this.activeRoute('/profiles')}
                    style={{ paddingLeft: 10 }}
                  >
                    <Link to='/profiles'>
                      <i className='fa fa-user'></i>{' '}
                      <span className='nav-label'>&nbsp;&nbsp;Perfis</span>
                    </Link>
                  </li>
                  <li
                    className={this.activeRoute('/bases')}
                    style={{ paddingLeft: 10 }}
                  >
                    <Link to='/bases'>
                      <i className='fa fa-home'></i>{' '}
                      <span className='nav-label'>&nbsp;&nbsp;Bases</span>
                    </Link>
                  </li>
                  <li
                    className={this.activeRoute('/hangars')}
                    style={{ paddingLeft: 10 }}
                  >
                    <Link to='/hangars'>
                      <i className='fas fa-warehouse'></i>{' '}
                      <span className='nav-label'>&nbsp;&nbsp;Hangares</span>
                    </Link>
                  </li>
                  <li
                    className={this.activeRoute('/aircrafts')}
                    style={{ paddingLeft: 10 }}
                  >
                    <Link to='/aircrafts'>
                      <i className='fa fa-plane'></i>{' '}
                      <span className='nav-label'>&nbsp;&nbsp;Aeronaves</span>
                    </Link>
                  </li>
                  <li
                    className={this.activeRoute('/aircraft-models')}
                    style={{ paddingLeft: 10 }}
                  >
                    <Link to='/aircraft-models'>
                      <i className='fa fa-fighter-jet'></i>{' '}
                      <span className='nav-label'>
                        &nbsp;&nbsp;Modelos de Aeronaves
                      </span>{' '}
                    </Link>
                  </li>
                  <li
                    className={this.activeRoute('/reasons')}
                    style={{ paddingLeft: 10 }}
                  >
                    <Link to='/reasons'>
                      <i className='fa fa-times'></i>{' '}
                      <span className='nav-label'>
                        &nbsp;&nbsp;Motivos de Cancelamento
                      </span>{' '}
                    </Link>
                  </li>
                  <li
                    className={this.activeRoute('/process')}
                    style={{ paddingLeft: 10 }}
                  >
                    <Link to='/process'>
                      <i className='fa fa-cogs'></i>{' '}
                      <span className='nav-label'>&nbsp;&nbsp;Processos</span>{' '}
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}
          </ul>
        </div>
      </nav>
    );
  }

  componentDidMount = () => {
    const navigation = this;
    navigation.hideDropdownContent();

    $('.dropdown-label').on('click', function () {
      const $content = $(this).parent().find('.dropdown-content');
      const $dropdownIcon = $(this).find('.dropdown-icon');
      const right = 'fa-chevron-right';
      const down = 'fa-chevron-down';

      if ($content.is(':visible')) {
        navigation.hideDropdownContent();
        $content.show();
        $dropdownIcon.removeClass(down).addClass(right);
      } else {
        navigation.hideDropdownContent();
        $dropdownIcon.removeClass(right).addClass(down);
      }

      $content.animate({ height: 'toggle' }, 300);
    });
  };
}

export default Navigation;
