import React, { Component } from "react";
import { Link, browserHistory } from "react-router";
import BaseService from "../services/BasesService";

export default class ManagementBases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bases: [],
      loading: true
    };

    const baseStored = localStorage.getItem("@omni/fast_track/base", false);
    if (baseStored) browserHistory.replace(`/managements/${baseStored}`);
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const bases = await BaseService.getAll();
    if (bases) this.setState({ bases });
    this.setState({ loading: false });
  }

  renderCard = base => (
    <Link to={`/managements/${base._id}`} className="col-lg-3 col-md-4 col-sm-12 col-xs-12" style={{
      padding: 10,
    }}>
      <div
        className="cardanimated fadeIn"
        style={{
          cursor: "pointer",
          borderRadius: 8,
          boxShadow: "3px 3px 3px #888",
          backgroundColor: "#192a39",
          color: "#fff",
        }}
      >
        <div
          className="card-header"
          key={base._id}
          style={{
            backgroundColor: "#192a39",
            color: "#FFF",
            fontSize: 24,
            borderRadius: "8px 8px 0px 0px",
            borderBottomColor: "#FFF3",
            padding: 10,
          }}
        >
          {base.name}
        </div>
        <div className="no-gutters" style={{
          backgroundColor: '#001f3f',
          minHeight: 140,
        }}>
          <div className="card-title" style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}>
            <div
              style={{
                height: "100%",
                marginTop: "auto",
                fontSize: 34,
                paddingLeft: 30,
                float: "left"
              }}
            >
              <i className="fa fa-warehouse" />
            </div>
            <div
              style={{
                height: "100%",
                marginTop: "auto",
                fontSize: 36,
                fontWeight: 500,
                textAlign: "right",
                paddingRight: 30,
              }}
            >
              {base.code}
            </div>
          </div>
        </div>
        <div
          className="card-footer"
          style={{
            borderTopColor: "#FFF3",
            fontSize: 18,
            padding: 10,
          }}
        >
          {base.services_count} Serviço(s) previstos para hoje
        </div>
      </div>
    </Link>
  );

  render() {
    return (
      <div className="row" style={{
        padding: 20,
      }}>
        <div
          className="animated fadeIn"
          style={{
            marginTop: 30,
            fontSize: 30,
            textAlign: "center",
            fontWeight: "bold"
          }}
        >
          Selecione uma Base
        </div>
        {this.state.bases.length > 0 ? (
          this.state.bases.map(base => this.renderCard(base))
        ) : this.state.loading ? null : (
          <div
            className="col-lg-3"
            style={{
              textAlign: "center",
              fontSize: 20,
              margin: "auto"
            }}
          >
            Nenhuma base encontrada!
          </div>
        )}
      </div>
    );
  }
}
