import React from 'react';

const LoadingModal = props => {
    return (
        <div
            id="loadingModal"
            style={{
                zIndex: 1000,
                position: 'absolute',
                width: '100%',
                height: '5vh',
                display: props.visible ? 'flex' : 'none'
            }}
        >
            {/* <div className='spinner spinner-lg' /> */}
            <div className='sk-spinner sk-spinner-chasing-dots'>
                <div className="sk-dot1" style={{ backgroundColor: "rgb(76, 90, 101)" }}></div>
                <div className="sk-dot2" style={{ backgroundColor: "rgb(76, 90, 101)" }}></div>
            </div>
        </div>
    );
}

export default LoadingModal;