import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import PcpServices from '../services/PcpService';
import Management from '../services/ManagementService';
import AircraftService from '../services/AircraftsService';
import AircraftModelService from '../services/AircraftModelsService';
import ImportArchiveService from '../services/ImportArchiveService';
import BaseService from '../services/BasesService';
import ReactSwitch from 'react-switch';
import { toast } from 'react-toastify';
import Moment from 'moment';
import HangarsService from '../services/HangarsService';
import Swal from 'sweetalert2';

export default class AddManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
      code: null,
      base: null,
      aircraft: null,
      inspection: null,
      execute_prediction: `NaN-NaN-NaNTNaN:NaN`,
      complexity: null,
      day_credits: null,
      hour_credits: null,
      circle_credits: null,
      register: null,
      register_type: '',
      man_hour_value: null,
      observation: '',
      aircrafts: [],
      aircraft_models: [],
      bases: [],
      imports: [],
      start_service: `NaN-NaN-NaNTNaN:NaN`,
      end_service: `NaN-NaN-NaNTNaN:NaN`,
      hangarsPositions: [],
      hangarPosition: {},
      hangarIndex: null,
      status: 'Conferido',
    };
    if (
      JSON.parse(localStorage.getItem('@omni/fast_track/user')).role === 'PCP'
    )
      browserHistory.push('/pcp-archives');
  }

  componentDidMount = async () => {
    const imports = await ImportArchiveService.getAll();
    this.setState({ imports: imports ? imports : [] });

    const aircrafts = await AircraftService.getAll();
    this.setState({ aircrafts: aircrafts ? aircrafts : [] });

    const aircrafts_models = await AircraftModelService.getAll();
    this.setState({
      aircrafts_models: aircrafts_models ? aircrafts_models : [],
    });

    const bases = await BaseService.getAll();
    this.setState({ bases: bases ? bases : [], base: this.props.params.base });
    this.getHangars(this.props.params.base);
  };

  getHangars = async (base) => {
    const hangars = await HangarsService.getByBase(base);
    let hangarsPositions = [];
    hangars.forEach((hangar) => {
      const composition = hangar.positions.map((pos) => ({
        hangar,
        position: pos.position,
      }));
      hangarsPositions = [...hangarsPositions, ...composition];
    });
    this.setState({ hangarsPositions });
  };

  validationDate = (date) => {
    return !date
      ? null
      : `${new Date(date).getFullYear()}-${`${
          new Date(date).getMonth() + 1
        }`.padStart(2, 0)}-${`${new Date(date).getDate()}`.padStart(
          2,
          0
        )}T${`${new Date(date).getHours()}`.padStart(2, 0)}:${`${new Date(
          date
        ).getMinutes()}`.padStart(2, 0)}`;
  };

  handleSave = async () => {
    const confirmation = (
      await Swal.fire({
        title: 'Confirmação',
        text: 'Você tem certeza que deseja adicionar este serviço?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })
    ).value;
    if (confirmation) {
      let obj = JSON.parse(JSON.stringify(this.state));
      obj.execute_prediction = this.state.execute_prediction
        ? new Date(this.state.execute_prediction)
        : null;
      obj.start_service = this.state.start_service
        ? new Date(this.state.start_service)
        : null;
      obj.end_service = this.state.end_service
        ? new Date(this.state.end_service)
        : null;
      obj.hangar = this.state.hangarPosition.hangar._id;
      obj.position = this.state.hangarPosition.position;
      if (obj.execute_prediction)
        obj.execute_prediction.setMinutes(
          obj.execute_prediction.getMinutes() -
            obj.execute_prediction.getTimezoneOffset()
        );
      if (obj.start_service)
        obj.start_service.setMinutes(
          obj.start_service.getMinutes() - obj.start_service.getTimezoneOffset()
        );
      if (obj.end_service)
        obj.end_service.setMinutes(
          obj.end_service.getMinutes() - obj.end_service.getTimezoneOffset()
        );
      if (obj.isMosa) obj.mosa_status = 'MOSA Solicitada pela Manutenção';
      await Management.add(obj).then(async function (res) {
        if (res) {
          browserHistory.push(`/managements/${obj.base}`);
          toast.success('Documento salvo com sucesso!');
        }
      });
      if (this.state.archive) {
        await ImportArchiveService.moreServices(
          this.state.archive
        ).then(function (result) {});
      }
    }
  };

  renderAircraftOptions = (aircraft) => (
    <option value={aircraft._id}>
      {`${aircraft.prefix}`} ({`${aircraft._model.manufacturer}`} -{' '}
      {`${aircraft._model.model}`})
    </option>
  );

  renderBaseOptions = (base) => (
    <option value={base._id}>
      {`${base.name}`} ({`${base.code}`}){' '}
    </option>
  );

  renderarchives = (archive) => {
    return (
      <tr id="identify" key={archive._id} style={{ cursor: 'pointer' }}>
        {/* <td>
                    <i className={`fa fa-${archive.active ? 'check' : 'times'} text-${archive.active ? 'success' : 'error'}`} />
                </td> */}
        <td>
          <Link to={'/pcp-import/' + archive._id} target="_blank">
            <a>{archive.name ? archive.name : 'Arquivo sem nome'}</a>
          </Link>
        </td>
        <td>{archive.number_services}</td>
        <td>
          {Moment(new Date(archive.date_uploaded))
            .locale('pt-BR')
            .format('DD/MM/YYYY HH:mm')}
        </td>
        <td>{archive.original_name}</td>
        <td>{archive.type}</td>
        <td>
          <span
            className="button-table"
            onClick={() => this.selectDocument(archive._id)}
          >
            <i className="fa fa-plus"></i>
          </span>
        </td>
      </tr>
    );
  };

  render() {
    return (
      <div className="wrapper wrapper-content">
        <div
          className="row wrapper border-bottom white-bg page-heading animated bounceInRight"
          style={{ margin: 'auto', padding: '40px' }}
        >
          <h2>Adicionar Serviço</h2>
          <br />
          <div className="row">
            <div className="col-lg-12 row animated fadeIn">
              <div
                className="col-lg-12 row"
                style={{
                  paddingLeft: '40px',
                  paddingRight: '40px',
                  paddingBottom: '20px',
                  paddingTop: '0px',
                  margin: '0px',
                }}
              >
                <h4
                  className="col-lg-12"
                  style={{
                    borderBottom: '1.5px solid rgba(0,0,0,0.07)',
                    textAlign: 'center',
                    marginTop: '20px',
                    marginBottom: '20px',
                    padding: '10px',
                    color: 'red',
                    fontSize: '18px',
                  }}
                >
                  DADOS DO SERVIÇO
                </h4>
                <div className="col-lg-12">
                  <h4>
                    <b>Ativo?</b>
                  </h4>
                  <ReactSwitch
                    checked={this.state.active}
                    onChange={(active) => this.setState({ active })}
                    onColor="#57b87b"
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                  <br />
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Aeronave</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-plane"></i>
                    </span>
                    <select
                      className="form-control"
                      value={
                        this.state.aircraft ? this.state.aircraft._id : null
                      }
                      onChange={(e) =>
                        this.setState({ aircraft: e.target.value })
                      }
                    >
                      <option value="">Selecione uma aeronave</option>
                      {this.state.aircrafts.map(this.renderAircraftOptions)}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Base</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-home"></i>
                    </span>
                    <select
                      className="form-control"
                      value={this.state.base}
                      onChange={(e) => {
                        this.getHangars(e.target.value);
                        this.setState({
                          base: e.target.value,
                          hangarIndex: null,
                        });
                      }}
                    >
                      <option value="">Selecione uma base</option>
                      {this.state.bases.map(this.renderBaseOptions)}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Inspeção</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-cogs"></i>
                    </span>
                    <input
                      type="text"
                      placeholder="Inspeção"
                      className="form-control"
                      value={this.state.inspection}
                      onChange={(e) =>
                        this.setState({ inspection: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Complexidade</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-fire"></i>
                    </span>
                    <select
                      disabled
                      className="form-control"
                      value={this.state.complexity}
                      onChange={(e) =>
                        this.setState({ complexity: e.target.value })
                      }
                    >
                      <option value="">Todas as Complexidades</option>
                      <option value="Base">Base</option>
                      <option value="Line">Line</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Previsão de Execução</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-calendar"></i>
                    </span>
                    <input
                      type="datetime-local"
                      className="form-control"
                      value={this.state.execute_prediction}
                      onChange={(e) =>
                        this.setState({ execute_prediction: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Tipo de Registro</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-check-circle"></i>
                    </span>
                    <select
                      type="text"
                      className="form-control"
                      value={this.state.register_type}
                      onChange={(e) =>
                        this.setState({ register_type: e.target.value })
                      }
                    >
                      <option value="">Tipo de Registro</option>
                      <option value="TC">TC</option>
                      <option value="DB">DB</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Registro</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-key"></i>
                    </span>
                    <input
                      type="text"
                      placeholder="Registro"
                      className="form-control"
                      value={this.state.register}
                      onChange={(e) =>
                        this.setState({ register: e.target.value })
                      }
                    />
                  </div>
                </div>
                <h4
                  className="col-lg-12"
                  style={{
                    borderBottom: '1.5px solid rgba(0,0,0,0.07)',
                    textAlign: 'center',
                    marginTop: '20px',
                    marginBottom: '20px',
                    padding: '10px',
                    color: 'red',
                    fontSize: '18px',
                  }}
                >
                  POSICIONAMENTO DA AERONAVE NO HANGAR
                </h4>
                <div className="col-lg-6">
                  <h4>
                    <b>Posição</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-map-marker-alt"></i>
                    </span>
                    <select
                      value={this.state.hangarIndex}
                      onChange={(e) => {
                        this.setState({
                          hangarPosition: this.state.hangarsPositions[
                            e.target.value
                          ],
                          hangarIndex: e.target.value,
                        });
                      }}
                      className="form-control"
                    >
                      <option value={null} label="Selecione um hangar" />
                      {this.state.hangarsPositions.map((comp, index) => (
                        <option
                          value={index}
                          label={`${comp.hangar.code} - Posição ${comp.position}`}
                        />
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Homem/Hora</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-clock"></i>
                    </span>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Homem/Hora"
                      value={this.state.man_hour_value}
                      onChange={(e) =>
                        this.setState({ man_hour_value: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Início do Serviço</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-calendar-alt"></i>
                    </span>
                    <input
                      type="datetime-local"
                      className="form-control"
                      value={this.state.start_service}
                      onChange={(e) =>
                        this.setState({ start_service: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Fim do Serviço</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-calendar-alt"></i>
                    </span>
                    <input
                      type="datetime-local"
                      className="form-control"
                      value={this.state.end_service}
                      onChange={(e) =>
                        this.setState({ end_service: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <h4>
                    <b>Créditos em Horas</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-clock"></i>
                    </span>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Crédito em Horas"
                      value={this.state.hour_credits}
                      onChange={(e) =>
                        this.setState({ hour_credits: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Crédito em Dias</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-calendar"></i>
                    </span>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Crédito em Dias"
                      value={this.state.day_credits}
                      onChange={(e) =>
                        this.setState({ day_credits: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Crédito em Ciclos</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-sync"></i>
                    </span>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Crédito em Ciclos"
                      value={this.state.circle_credits}
                      onChange={(e) =>
                        this.setState({ circle_credits: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <h4>
                    <b>Observações</b>
                  </h4>
                  <textarea
                    type="text"
                    rows="6"
                    placeholder="Observações"
                    className="form-control"
                    value={this.state.observation}
                    onChange={(e) =>
                      this.setState({ observation: e.target.value })
                    }
                  ></textarea>
                </div>
                <h4 className="col-lg-12 mosa-title">
                  MOSA&nbsp;&nbsp;&nbsp;&nbsp;
                  <ReactSwitch
                    checked={this.state.isMosa}
                    onChange={(isMosa) =>
                      this.setState({
                        isMosa,
                        mosa_selected_by: JSON.parse(
                          localStorage.getItem('@omni/fast_track/user')
                        )._id,
                      })
                    }
                    onColor="#57b87b"
                    checkedIcon={false}
                    uncheckedIcon={false}
                    style={{ marginBottom: -30 }}
                  />
                </h4>
              </div>
              <div className="col-lg-12" style={{ textAlign: 'center' }}>
                <button
                  className="btn btn-success"
                  onClick={this.handleSave}
                  style={{ margin: '2px' }}
                >
                  Salvar
                </button>
                {/* <Link to={`/managements/${this.state.base}`}> */}
                <button
                  className="btn btn-danger"
                  style={{ margin: '2px' }}
                  onClick={async () => {
                    const confirmation = (
                      await Swal.fire({
                        title: 'Confirmação',
                        text: 'Você tem certeza que deseja cancelar?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                        customContainerClass: 'sweetAlert',
                      })
                    ).value;
                    if (confirmation)
                      browserHistory.push(`/managements/${this.state.base}`);
                  }}
                >
                  Cancelar
                </button>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
