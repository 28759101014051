import React from 'react';
import ReactDOM from 'react-dom';
import { Router, browserHistory } from 'react-router';
import Routes from './config/routes';

import jquery from 'jquery';
import $ from 'jquery';
import metismenu from 'metismenu';
// import 'bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import './../node_modules/animate.css/animate.min.css'
import 'animate.css/animate.min.css';
import './styles/style.css'

ReactDOM.render(
<Routes />,
    document.getElementById('root')
);