import React, { Component } from "react";
import $ from "jquery";
import { Link, browserHistory } from "react-router";
import BasesService from "../services/BasesService";
import AddBase from "./AddBase";
import EditBase from "./EditBase";
import RoundedPagination from '../components/common/RoundedPagination';

export default class Bases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bases: [],
      filter: "",
      page: props.params.page || 1,
      pages: 0,
      selectedBase: null
    };
    const role = JSON.parse(localStorage.getItem("@omni/fast_track/user")).role;
    if (role === "PCP" || role === "COORDENADOR" || role === "VISUALIZADOR")
      browserHistory.push("/dashboard");
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.filter();
  }

  async componentWillReceiveProps(nextProps) {
    this.setState({ page: nextProps.params.page || 1 }, () => {
      this.filter();
    });
  }

  getBases = async () => {
    this.setState({ loading: true });
    const obj = await BasesService.get(this.state.page);
    const bases = obj ? obj.result : [];
    const pages = obj ? obj.pages : 0;
    this.setState({ bases, pages, loading: false });
  };

  filter = async () => {
    if (this.state.filter.length > 0) {
      this.setState({ loading: true });
      const obj = await BasesService.get(1, this.state.filter);
      const bases = obj ? obj.result : [];
      this.setState({ bases, loading: false });
    } else this.getBases();
  };

  renderbase = (base) => {
    return (
      <tr
        id="identify"
        key={base._id}
        data-toggle="modal"
        data-target="#editModal"
        onClick={() => {
          this.setState({ selectedBase: base._id });
          $("#editModal").removeClass("hide").addClass("show");
          $(".modal-backdrop").removeClass("hide").addClass("show");
        }}
        style={{ cursor: "pointer" }}
      >
        <td>
          <i
            className={`fa fa-${base.active ? "check" : "times"} text-${
              base.active ? "success" : "error"
            }`}
          />
        </td>
        <td style={{ color: "#337ab7" }}>{base.name}</td>
        <td style={{ color: "#337ab7" }}>{base.code}</td>
      </tr>
    );
  };


  handleFilterSubmit = (e) => {
    e.preventDefault();
    this.handlePagination(e, 1);
  }

  handlePagination = (_event, value) => {
    this.setState({ page: value }, () => {
      browserHistory.push(`/bases/${this.state.page}`);
    });
  };

  render() {
    return (
      <div className="wrapper wrapper-content animated fadeInDown">
        <AddBase refresh={() => this.getBases()} />
        <EditBase
          selected={this.state.selectedBase}
          refresh={() => this.getBases()}
        />
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-6">
                <h2>Bases</h2>
                <ol className="breadcrumb">
                  <li>
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li>
                    <Link>Configurações</Link>
                  </li>
                  <li className="active">
                    <strong>Bases</strong>
                  </li>
                </ol>
              </div>
              <div className="col-lg-6">
                <div className="pull-right" style={{ paddingTop: 10 }}>
                  <button
                    className="btn btn-success"
                    data-toggle="modal"
                    data-target="#addModal"
                    onClick={() => {
                      $("#addModal").removeClass("hide").addClass("show");
                      $(".modal-backdrop").removeClass("hide").addClass("show");
                    }}
                  >
                    Adicionar
                  </button>
                </div>
                <form onSubmit={this.handleFilterSubmit}>
                  <div
                    className="input-group pull-right"
                    style={{ marginTop: 15 }}
                  >
                    <input
                      type="text"
                      placeholder="Procurar Base"
                      className="input-sm form-control"
                      value={this.state.filter}
                      onChange={(e) =>
                        this.setState({ filter: e.target.value })
                      }
                      style={{ backgroundColor: "rgba(255, 255, 255, 0.835)" }}
                    />
                    <span className="input-group-btn">
                      <button type="submit" className="btn btn-sm btn-success">
                        <i className="fa fa-search"></i>
                      </button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper wrapper-content">
          <div className="row animated fadeInDown">
            <div className="col-lg-12 ibox-content">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Ativo</th>
                      <th>Nome</th>
                      <th>Código</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.loading ? (
                      <tr>
                        <th colSpan={6}>
                          <div className="spinner spinner-lg" />
                        </th>
                      </tr>
                    ) : (
                      this.state.bases.map(this.renderbase)
                    )}
                  </tbody>
                </table>
              </div>

              <RoundedPagination onChange={this.handlePagination}
                                 page={this.state.page}
                                 count={this.state.pages} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
