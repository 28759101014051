import logout from "../utils/Auth";

export default async (url, params) => {
  const result = await fetch(url, {
    ...params,
  });
  if (result.status !== 401) return result;
  logout();
  return result;
};
