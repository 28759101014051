import React from 'react';
import { browserHistory } from 'react-router';
import Moment from 'moment';

const getAircraftBaseName = (aircraft) => {
  const dateNow = new Date();
  let baseName = 'Base não informada';
  aircraft.movimentation.forEach((element) => {
    if (element.startOperation && element.endOperation) {
      const s = new Date(element.startOperation);
      const e = new Date(element.endOperation);

      if (
        dateNow.getTime() >= s.getTime() &&
        dateNow.getTime() <= e.getTime()
      ) {
        baseName = element._base.name;
      }
    } else if (element.startOperation && !element.endOperation) {
      const s = new Date(element.startOperation);
      if (dateNow.getTime() >= s.getTime()) {
        baseName = element._base.name;
      }
    }
  });
  return baseName;
};

const Flights = (flight) => {
  const downloadsCount = flight.downloads.filter(
    ({ download_has_done }) => download_has_done
  ).length;

  let flightRatio =
    1 - (flight.flightsCount ? downloadsCount / flight.flightsCount : 1);

  if (!flightRatio) {
    flightRatio = 0;
  }

  return (
    <tr
      id="identify"
      key={flight._id}
      onClick={
        flight.status === 0
          ? () => browserHistory.push(`/flights_management/${flight._id}`)
          : () => browserHistory.push(`/flights_management/${flight._id}`)
      }
      style={{ cursor: 'pointer' }}
    >
      <td>
        {flight.aircraft ? flight.aircraft._model.manufacturer : ''} -{' '}
        {flight.aircraft._model.model}
      </td>
      <td>
        <p>{flight.aircraft ? flight.aircraft.prefix : 'Não informada'}</p>
      </td>
      <td>{getAircraftBaseName(flight.aircraft)}</td>
      <td>
        {flight.date
          ? Moment(flight.date).utc().format('DD/MM/YYYY')
          : 'Data não informada'}
      </td>
      {/* <td>
        {flight.status === 1 ? (
          <div
            title='Voos e downloads preenchidos'
            style={{ color: '#1AB394', marginLeft: '42px', fontSize: '16px' }}
          >
            <i className='fas fa-check-circle'></i>
          </div>
        ) : (
          <div
            title='Preenchimento pendente'
            style={{ color: '#ED5568', marginLeft: '42px', fontSize: '16px' }}
          >
            <i className='fas fa-times-circle'></i>
          </div>
        )}
      </td> */}
      <td>
        <p style={{ paddingLeft: '36px' }}>
          {downloadsCount + ' / ' + flight.flightsCount}
        </p>
      </td>
      <td>
        {flightRatio === 0 ? (
          <div
            className="btn btn-sucess"
            style={{
              backgroundColor: '#1AB394',
              color: '#FFF',
              width: '100px',
              marginLeft: '30px',
            }}
          >
            {flightRatio.toFixed(2)}
          </div>
        ) : flightRatio <= 0.5 ? (
          <div
            className="btn btn-danger"
            style={{
              backgroundColor: '#FFC107',
              color: '#000',
              width: '100px',
              marginLeft: '30px',
              borderColor: '#FFC107',
            }}
          >
            {flightRatio.toFixed(2)}
          </div>
        ) : (
          <div
            className="btn btn-danger"
            style={{
              backgroundColor: '#ED5568',
              color: '#FFF',
              width: '100px',
              marginLeft: '30px',
            }}
          >
            {flightRatio.toFixed(2)}
          </div>
        )}
      </td>
    </tr>
  );
};

export default Flights;
