import React, { Component, useState } from 'react';
import { Link } from 'react-router';
import Dialog from '@material-ui/core/Dialog';
import { CircularProgress, IconButton } from '@material-ui/core';
import { MdDeleteForever } from 'react-icons/md';
import moment from 'moment';

import FlightStepService from '../../../services/FlightStepService';
import LoadingModal from '../../LoadingModal';
// import RoundedPagination from '../../../components/common/RoundedPagination';
import ReactSwitch from 'react-switch';

export default function NewFlightModal(props) {
  const [state, setState] = useState({
    active: true,
    pages: 0,
    page: 1,
    steps: [],
    upload_status: {
      status: false,
      justification: '',
    },
    data_status: {
      status: false,
      justification: '',
    },
    preliminary_analysis: {
      status: false,
      justification: '',
    },
  });
  const [flightNumber, setFlightNumber] = useState('');
  const [flightReport, setFlightReport] = useState('');

  const [preliminaryStatus, setPreliminaryStatus] = useState(false);
  const [preliminaryJustification, setPreliminaryJustification] = useState('');
  const [dataStatus, setDataStatus] = useState(false);
  const [dataJustification, setDataJustification] = useState('');
  const [uploadStatus, setUploadStatus] = useState(false);
  const [uploadJustification, setUploadJustification] = useState('');

  const getFlight = async (id) => {
    setState((oldState) => ({ ...oldState, loading: true, mosa_list: [] }));
    if (id !== 'new') {
      const response = await FlightStepService.getById(id);
      setState((oldState) => ({ ...oldState, loading: false, ...response }));
      setFlightNumber(response.flight_number);
      setFlightReport(response.flight_report);
      setPreliminaryStatus(response.preliminary_analysis.status);
      setPreliminaryJustification(response.preliminary_analysis.justification);
      setDataStatus(response.data_status.status);
      setDataJustification(response.data_status.justification);
      setUploadStatus(response.upload_status.status);
      setUploadJustification(response.upload_status.justification);
    } else {
      setState((oldState) => ({
        ...oldState,
        loading: false,
        steps: [],
      }));
      setPreliminaryStatus(false);
      setPreliminaryJustification('');
      setDataStatus(false);
      setDataJustification('');
      setUploadStatus(false);
      setUploadJustification('');
    }
  };

  const handleSave = async () => {
    const { upload_status, data_status, preliminary_analysis } = state;
    if (props.step === 'new') {
      await FlightStepService.add({
        flight: props.flight,
        flight_number: flightNumber,
        flight_report: flightReport,
        upload_status: {
          status: uploadStatus,
          justification: uploadJustification,
        },
        data_status: {
          status: dataStatus,
          justification: dataJustification,
        },
        preliminary_analysis: {
          status: preliminaryStatus,
          justification: preliminaryJustification,
        },
      });
    } else
      await FlightStepService.update(
        {
          flight_number: flightNumber,
          flight_report: flightReport,
          upload_status: {
            status: uploadStatus,
            justification: uploadJustification,
          },
          data_status: {
            status: dataStatus,
            justification: dataJustification,
          },
          preliminary_analysis: {
            status: preliminaryStatus,
            justification: preliminaryJustification,
          },
        },
        props.step
      );
    props.handleClose();
  };

  return (
    <>
      <LoadingModal visible={state.loading} />
      <Dialog
        onEnter={() => getFlight(props.step)}
        onExit={() => getFlight(props.step)}
        className={{ zIndex: 1000000, minHeight: 300 }}
        style={{ zIndex: 1000000 }}
        open={props.open}
        fullWidth={false}
        maxWidth={'lg'}
        scroll={'body'}
        keepMounted
        onClose={props.handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <div
          className='modal-content row wrapper border-bottom white-bg page-heading'
          style={{ margin: 'auto', padding: '30px' }}
        >
          <div className='mosas-modal-header'>
            <h2>Voo</h2>
            <hr />
          </div>
          <div className='row' style={{ marginTop: '20px' }}>
            <>
              <div className='col-lg-3'>
                Flight Report
                <div className='input-group m-b'>
                  <span className='input-group-addon'>
                    <i className='fa fa-download'></i>
                  </span>
                  <input
                    type='number'
                    className='form-control'
                    value={flightReport}
                    onChange={(e) => setFlightReport(e.target.value)}
                  />
                </div>
              </div>
              <div className='col-lg-3'>
                Flight Number
                <div className='input-group m-b'>
                  <span className='input-group-addon'>
                    <i className='fa fa-download'></i>
                  </span>
                  <input
                    type='number'
                    className='form-control'
                    value={flightNumber}
                    onChange={(e) => setFlightNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className='col-lg-12'>
                <h3>Download</h3>
              </div>
              <table className='table table-striped' style={{ minWidth: 700 }}>
                <thead>
                  <tr>
                    <th>Descrição</th>
                    <th>Status</th>
                    <th>Justificativa</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span>
                        O upload dos dados no software de análise foi feito?
                      </span>
                    </td>
                    <td>
                      <ReactSwitch
                        checked={uploadStatus}
                        onChange={(active) => setUploadStatus(active)}
                        onColor='#57b87b'
                        style={{ fontSize: 16 }}
                        checkedIcon={false}
                        uncheckedIcon={false}
                      />
                    </td>
                    <td>
                      <textarea
                        value={uploadJustification}
                        disabled={uploadStatus}
                        onChange={(event) =>
                          setUploadJustification(event.target.value)
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Os dados são válidos?</span>
                    </td>
                    <td>
                      <ReactSwitch
                        checked={dataStatus}
                        onChange={(active) => setDataStatus(active)}
                        onColor='#57b87b'
                        style={{ fontSize: 16 }}
                        checkedIcon={false}
                        uncheckedIcon={false}
                      />
                    </td>
                    <td>
                      <textarea
                        value={dataJustification}
                        disabled={dataStatus}
                        onChange={(event) =>
                          setDataJustification(event.target.value)
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>A análise preliminar dos dados foi feita?</span>
                    </td>
                    <td>
                      <ReactSwitch
                        checked={preliminaryStatus}
                        onChange={(active) => setPreliminaryStatus(active)}
                        onColor='#57b87b'
                        style={{ fontSize: 16 }}
                        checkedIcon={false}
                        uncheckedIcon={false}
                      />
                    </td>
                    <td>
                      <textarea
                        value={preliminaryJustification}
                        disabled={preliminaryStatus}
                        onChange={(event) =>
                          setPreliminaryJustification(event.target.value)
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          </div>
          <div className='col-lg-12' style={{ textAlign: 'center' }}>
            <button
              className='btn btn-primary'
              data-dismiss='modal'
              style={{ marginTop: '20px', margin: 5 }}
              onClick={() => handleSave()}
            >
              Salvar
            </button>
            <button
              className='btn btn-success'
              data-dismiss='modal'
              style={{ marginTop: '20px', margin: 5 }}
              onClick={() => props.handleClose()}
            >
              Voltar
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
