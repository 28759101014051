import React, { Component } from "react";
import { browserHistory } from "react-router";

import ManagementService from "../../services/ManagementService";
import BaseService from "../../services/BasesService";
import UsersService from "../../services/UsersService";
import FileService from "../../services/FileService";
import MosaService from "../../services/MosaService";


import MechanicMOSAsModal from "../MechanicMOSAsModal";
import AppBar from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Moment from 'moment';
import MechanicPendencies from './MechanicPendencies';
import NextPendencies from './NextPendencies';
import MosaByAuditor from './MosaByAuditor';
import MosaByMechanic from './MosaByMechanic';


export default class MechanicalReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      mechanics: [],
      filter: "",
      pages: 0,
      page: 1,
      filter_bases: [],
      users: [],
      base: "",
      name: "",
      period: 1,
      period_type: "year",
      selectedMechanic: null,
      value: 0,
      auditors: [],
      auditor: null,
      services: [],
      next_services: [],
      pages: 0,
      page: 1,
      start_service: '',
      end_service: '',
      start_mosa: ''
    };
    if (
      JSON.parse(localStorage.getItem("@omni/fast_track/user")).role === "PCP"
    )
      browserHistory.push("/pcp-archives");
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    const bases = await BaseService.getAll();
    const auditors = await UsersService.get(1, { text: '', base: '', role: 'AUDITOR MOSA' });

    this.setState({
      filter_bases: bases ? bases : [],
      searching: true,
      auditors: auditors.result,
    });
    // this.getMechanics();
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    return (
      <div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <MechanicMOSAsModal
            selected={this.state.selectedMechanic}
            refresh={() => this.getBases(this.props)}
          />
          <AppBar position="static" style={{ outline: 'none' }}>
            <Tabs value={this.state.value} onChange={this.handleChange} aria-label="simple tabs example">
              <Tab label="Relatório de Pendências" style={{ fontFamily: 'Roboto', fontSize: 14, fontWeight: 300, outline: 'none' }} />
              <Tab label="Mosa por Auditor" style={{ fontFamily: 'Roboto', fontSize: 14, fontWeight: 300, outline: 'none' }} />
              <Tab label="Mosa por Auditado" style={{ fontFamily: 'Roboto', fontSize: 14, fontWeight: 300, outline: 'none' }} />
              <Tab label="Próximas MOSAs" style={{ fontFamily: 'Roboto', fontSize: 14, fontWeight: 300, outline: 'none' }} />
            </Tabs>
          </AppBar>
          <div
            role="tabpanel"
            hidden={this.state.value !== 0}
            id={`simple-tabpanel-${0}`}
            aria-labelledby={`simple-tab-${0}`}

          >
            {this.state.value === 0 && (
              <Box p={3} m={-1}>
                <MechanicPendencies />
              </Box>
            )}
          </div>
          <div
            role="tabpanel"
            hidden={this.state.value !== 1}
            id={`simple-tabpanel-${1}`}
            aria-labelledby={`simple-tab-${1}`}

          >
            {this.state.value === 1 && (
              <Box p={3} m={-1}>
                <MosaByAuditor />
              </Box>
            )}
          </div>
          <div
            role="tabpanel"
            hidden={this.state.value !== 2}
            id={`simple-tabpanel-${2}`}
            aria-labelledby={`simple-tab-${2}`}

          >
            {this.state.value === 2 && (
              <Box p={3} m={-1}>
                <MosaByMechanic />
              </Box>
            )}
          </div>
          <div
            role="tabpanel"
            hidden={this.state.value !== 3}
            id={`simple-tabpanel-${3}`}
            aria-labelledby={`simple-tab-${3}`}

          >
            {this.state.value === 3 && (
              <Box p={3} m={-1}>
                <NextPendencies />
              </Box>
            )}
          </div>
        </div>
      </div>
    );
  }
}
