import React, { useEffect, useState } from 'react';
import { Link, browserHistory } from 'react-router';
import AircraftService from '../../services/AircraftsService';
import BaseService from '../../services/BasesService';
import LoadingModal from '../LoadingModal';
import FlightsReportService from '../../services/FlightsReportService';
import moment from 'moment';

export default function EditFlightReport(props) {
  const [state, setState] = useState({
    loading: true,
    registers: [],
  });
  const [flightReport, setFlightReport] = useState({});

  const verifyUserRole = () => {
    const role = JSON.parse(localStorage.getItem('@omni/fast_track/user')).role;
    if (role !== 'ADM' && role !== 'ADM - TI' && role !== 'ENGENHARIA')
      browserHistory.push('/dashboard');
  };

  const fetchData = async (flightNumber) => {
    window.scrollTo(0, 0);
    setState((oldState) => ({ ...oldState, loading: true }));
    const bases = await BaseService.getAll();
    const filter_aircrafts = await AircraftService.getAll();
    const flightReport = await FlightsReportService.getByFlightNumber(
      flightNumber
    );
    if (flightReport && flightReport._id) {
      setFlightReport(flightReport);
      setState((oldState) => ({
        ...oldState,
        filter_bases: bases ? bases : [],
        registers: flightReport.registers || [],
        filter_aircrafts: filter_aircrafts ? filter_aircrafts : [],
        loading: false,
        date: moment(flightReport.date).utc().endOf('day').format('YYYY-MM-DD'),
      }));
    }
  };

  useEffect(() => {
    verifyUserRole();
    if (props.params.id) fetchData(props.params.id);
  }, [props.params.id]);

  function renderPrettyAircraftName(aircraftId) {
    const aircraft = state.filter_aircrafts.find(
      (aircraft) => aircraft._id === aircraftId
    );
    if (aircraft) {
      return aircraft._model
        ? `${aircraft.prefix} (${aircraft._model.manufacturer} - ${aircraft._model.model})`
        : aircraft.prefix;
    }
    return 'Aeronave não definida';
  }

  function renderPrettyBaseName(baseId) {
    const base = state.filter_bases.find((base) => base._id === baseId);

    if (base) return base.name;

    return 'Base não definida';
  }

  const formatTime = (time) => {
    if (time) {
      const newTime = time.split(':');
      return `${newTime[0]}h:${newTime[1]}min`;
    }
  };

  return (
    <>
      {state.loading ? (
        <div className='col-lg-12'>
          <div className='row'>
            <LoadingModal visible={state.loading} />
          </div>
        </div>
      ) : (
        <div className='wrapper wrapper-content animated fadeInDown'>
          <div className='row wrapper border-bottom white-bg page-heading'>
            <div className='col-lg-12'>
              <div className='row'>
                <div className='col-lg-8'>
                  <h2>Relatório</h2>
                  <ol className='breadcrumb'>
                    <li>
                      <Link to='/flights_report'>Relatório de voos</Link>
                    </li>
                    <li className='active'>
                      <strong>Relatório</strong>
                    </li>
                  </ol>
                </div>
                <div className='col-lg-4'></div>
              </div>
            </div>
            <h4
              className='col-lg-12'
              style={{
                borderBottom: '1.5px solid rgba(0,0,0,0.07)',
                textAlign: 'center',
                marginTop: '20px',
                marginBottom: '20px',
                padding: '10px',
                color: 'red',
                fontSize: '18px',
              }}
            >
              DADOS GERAIS
            </h4>
            <div className='col-lg-12' style={{ paddingTop: 10 }}>
              <div className='row'>
                <div className='col-lg-6'>
                  <h4>
                    <b>Aeronave</b>
                  </h4>
                  <div className='input-group m-b'>
                    <span className='input-group-addon'>
                      <i className='fa fa-plane'></i>
                    </span>
                    <input
                      className='form-control'
                      value={renderPrettyAircraftName(flightReport.aircraft)}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className='col-lg-6'>
                  <h4>
                    <b>Base do registro</b>
                  </h4>
                  <div className='input-group m-b'>
                    <span className='input-group-addon'>
                      <i className='fa fa-home'></i>
                    </span>
                    <input
                      className='form-control'
                      value={renderPrettyBaseName(flightReport.base)}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className='col-lg-6'>
                  <h4>
                    <b>Data do registro</b>
                  </h4>
                  <div className='input-group m-b'>
                    <span className='input-group-addon'>
                      <i className='fa fa-calendar'></i>
                    </span>
                    <input
                      type='date'
                      className='form-control'
                      value={state.date}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className='col-lg-6'>
                  <h4>
                    <b>Profissionais envolvidos</b>
                  </h4>
                  <div className='input-group m-b'>
                    <span className='input-group-addon'>
                      <i className='fas fa-user'></i>
                    </span>
                    <input
                      className='form-control'
                      value={
                        flightReport.responsible ||
                        'Nenhum profissional encontrado'
                      }
                      disabled={true}
                    />
                  </div>
                </div>
                <div className='col-lg-6'>
                  <h4>
                    <b>Cliente</b>
                  </h4>
                  <div className='input-group m-b'>
                    <span className='input-group-addon'>
                      <i className='fas fa-file-signature'></i>
                    </span>
                    <input
                      className='form-control'
                      value={flightReport.client || 'Cliente não definido'}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className='col-lg-6'>
                  <h4>
                    <b>Contrato</b>
                  </h4>
                  <div className='input-group m-b'>
                    <span className='input-group-addon'>
                      <i className='fas fa-file-signature'></i>
                    </span>
                    <input
                      className='form-control'
                      value={flightReport.contract || 'Contrato não definido'}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className='col-lg-6'>
                  <h4>
                    <b>Local inicial</b>
                  </h4>
                  <div className='input-group m-b'>
                    <span className='input-group-addon'>
                      <i className='fas fa-plane-departure'></i>
                    </span>
                    <input
                      className='form-control'
                      value={
                        flightReport.departure_location || 'Local não definido'
                      }
                      disabled={true}
                    />
                  </div>
                </div>
                <div className='col-lg-6'>
                  <h4>
                    <b>Local final</b>
                  </h4>
                  <div className='input-group m-b'>
                    <span className='input-group-addon'>
                      <i className='fas fa-plane-arrival'></i>
                    </span>
                    <input
                      className='form-control'
                      value={
                        flightReport.arrival_location || 'Local não definido'
                      }
                      disabled={true}
                    />
                  </div>
                </div>
                <div className='col-lg-6'>
                  <h4>
                    <b>Horário da decolagem</b>
                  </h4>
                  <div className='input-group m-b'>
                    <span className='input-group-addon'>
                      <i className='fas fa-plane-departure'></i>
                    </span>
                    <input
                      className='form-control'
                      value={
                        flightReport.departure_time || 'Horário não definido'
                      }
                      disabled={true}
                    />
                  </div>
                </div>
                <div className='col-lg-6'>
                  <h4>
                    <b>Horário de aterrizagem</b>
                  </h4>
                  <div className='input-group m-b'>
                    <span className='input-group-addon'>
                      <i className='fas fa-plane-arrival'></i>
                    </span>
                    <input
                      className='form-control'
                      value={
                        flightReport.arrival_time || 'Horário não definido'
                      }
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-12' style={{ paddingTop: 10 }}>
              <div className='row'>
                <div className='col-lg-12'>
                  <h4>
                    <b>Observações</b>
                  </h4>
                  <div className='input-group m-b'>
                    <span className='input-group-addon'>
                      <i className='fa fa-file'></i>
                    </span>
                    <textarea
                      className='form-control'
                      value={
                        flightReport.observations ||
                        'Nenhuma observação encontrada para este registro'
                      }
                      disabled
                      style={{
                        height: '120px',
                        resize: 'none',
                        outline: 'none',
                      }}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-12' style={{ marginBottom: 20 }} />

            {/* Tabela com o relatório das pernas do voo */}

            <h4
              className='col-lg-12'
              style={{
                borderBottom: '1.5px solid rgba(0,0,0,0.07)',
                textAlign: 'center',
                marginTop: '20px',
                marginBottom: '20px',
                padding: '10px',
                color: 'red',
                fontSize: '18px',
              }}
            >
              REGISTROS
            </h4>

            <table className='table table-striped'>
              <thead>
                <tr>
                  <th>Número da perna</th>
                  <th>Local de partida (Horário)</th>
                  <th>Local de chegada (Horário)</th>
                  <th>Intervalo entre decolagem e pouso</th>
                  <th>Profissional (cargo)</th>
                  <th>Número de inscrição do profissional</th>
                  <th>Combustível inicial</th>
                  <th>Combustível final</th>
                </tr>
              </thead>
              <tbody>
                {state.registers.length === 0 ? (
                  <tr>
                    <td colSpan='7' style={{ textAlign: 'center' }}>
                      Nenhum relatório encontrado
                    </td>
                  </tr>
                ) : (
                  state.registers.map((register, index) => (
                    <tr style={{ cursor: 'pointer' }} key={index}>
                      <td>{register.leg_number}</td>
                      <td>
                        {register.departure_location} ({register.takeoff_time})
                      </td>
                      <td>
                        {register.arrival_location} ({register.landing_time})
                      </td>
                      <td>{formatTime(register.takeoff_land_time)}</td>
                      <td>
                        {register.staff} ({register.duty})
                      </td>
                      <td>{register.inscription}</td>
                      <td>{register.fuel_start}</td>
                      <td>{register.fuel_end}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}
