import React, { Component } from 'react';
import DashboardService from '../services/DashboardService';
import renderHTML from 'react-render-html';
import '../styles/svg.css';
import LoadingModal from './LoadingModal';
import { browserHistory } from 'react-router';

//To filter
import BaseService from '../services/BasesService';
import HangarService from '../services/HangarsService';
import AircraftService from '../services/AircraftsService';
import AircraftModels from '../services/AircraftModelsService';
import AircraftModelsService from '../services/AircraftModelsService';
import '../styles/filter.css';

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hangars: [],
      filter_hangars: [],
      filter_bases: [],
      filter_aircrafts: [],
      hangar: null,
      aircraft: null,
      base: null,
      date: this.validationDate(new Date()),
      role: JSON.parse(localStorage.getItem('@omni/fast_track/user')).role,
    };
    if (this.state.role === 'PCP') browserHistory.push('/pcp-archives');
    if (this.state.role === 'ENGENHARIA')
      browserHistory.push('/engineering/aircrafts');
    if (this.state.role === 'AVALIADOR')
      browserHistory.push('/mechanics-evaluation');
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ loading: true });
    const hangars = await DashboardService.getAll();
    await this.setState({ hangars: hangars ? hangars : [] });
    this.setState({ loading: false });

    const filter_hangars = await HangarService.getAll();
    const filter_aircrafts = await AircraftService.getAll();
    const filter_bases = await BaseService.getAll();
    this.setState({
      filter_hangars: filter_hangars ? filter_hangars : [],
      filter_aircrafts: filter_aircrafts ? filter_aircrafts : [],
      filter_bases: filter_bases ? filter_bases : [],
    });
  }

  renderSVG = (hangar) => {
    let heliStr = '';
    let str = hangar.hangar_layout;
    const index = str.indexOf('</svg>');

    hangar.services.forEach((service) => {
      const position =
        hangar.hangar_positions[
          hangar.hangar_positions.findIndex(
            (p) => p.position == service.position
          )
        ];
      if (position && service && service.aircraft) {
        const transform = position.align
          ? `transform="rotate(${position.align}, ${position.position_x}, ${
              position.position_y
            }) translate(${-position.width})"`
          : null;
        heliStr += `<image xlink:href="img/heli.png" x="${position.position_x}" y="${position.position_y}" height="${position.height}" width="${position.width}" ${transform} />`;
        heliStr += `
            <text
                x=${
                  !!position.align
                    ? position.position_x - 2 + position.height / 2
                    : position.position_x + 4
                }
                y=${
                  !!position.align
                    ? position.position_y + position.width + 9
                    : position.position_y + position.height + 9
                }
                font-family='sans'
                font-size=3
                fill='#4c5a65'
                font-weight='700'
                text-align='center'
            >
                ${service.aircraft.prefix}
            </text>`;
      }
    });

    str = str.substr(0, index) + heliStr + str.substr(index);
    return (
      <div
        className='svg'
        style={{
          cursor: this.state.role !== 'VISUALIZADOR' ? 'pointer' : 'initial',
        }}
        onClick={() =>
          !this.state.role === 'VISUALIZADOR' &&
          browserHistory.push(
            `/managements/${hangar.base}/${hangar.hangar}/${this.state.date}`
          )
        }
      >
        {renderHTML(str)}
      </div>
    );
  };

  renderBasesOptions = (base) => (
    <option value={base._id}>{`${base.name} (${base.code})`}</option>
  );

  renderHangarsOptions = (hangar) => (
    <option value={hangar._id}>{`${hangar.code} (${
      hangar.base ? hangar.base.name : 'Base não definida!'
    })`}</option>
  );

  renderAircraftsOptions = (aircraft) => (
    <option
      value={aircraft._id}
    >{`${aircraft.prefix}  (${aircraft._model.manufacturer} - ${aircraft._model.model})`}</option>
  );

  validationDate = (date) => {
    return !date
      ? null
      : `${new Date(date).getFullYear()}-${`${
          new Date(date).getMonth() + 1
        }`.padStart(2, 0)}-${`${new Date(date).getDate()}`.padStart(
          2,
          0
        )}T${`${new Date(date).getHours()}`.padStart(2, 0)}:${`${new Date(
          date
        ).getMinutes()}`.padStart(2, 0)}`;
  };

  handleSearch = async () => {
    this.setState({ loading: true });
    if (
      !this.state.aircraft &&
      !this.state.aircraft_model &&
      !this.state.base &&
      !this.state.hangar &&
      !this.state.date
    ) {
      let hangars = await DashboardService.getAll();
      await this.setState({ hangars: hangars ? hangars : [] });
    } else {
      let hangars = await DashboardService.filter(this.state);
      await this.setState({ hangars: hangars ? hangars : [] });
    }
    this.setState({ loading: false });
  };

  handleClear = async () => {
    await this.setState({
      base: '',
      aircraft: '',
      hangar: '',
      date: this.validationDate(new Date()),
    });
    this.handleSearch();
  };

  handleChangeBase = (e) => {
    const base = e.target.value;
    HangarService.getByBase(base).then((filter_hangars) => {
      this.setState({ base, filter_hangars });
    });
  };

  render() {
    return (
      <div className='row'>
        {/* Filter */}
        <div className='dashboard-filter'>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='input-group m-b'>
                <span className='input-group-addon'>
                  <i className='fa fa-home'></i>
                </span>
                <select
                  className='form-control'
                  value={this.state.base}
                  onChange={this.handleChangeBase}
                >
                  <option value=''>Todas as bases</option>
                  {this.state.filter_bases &&
                    this.state.filter_bases.map(this.renderBasesOptions)}
                </select>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='input-group m-b'>
                <span className='input-group-addon'>
                  <i className='fa fa-warehouse'></i>
                </span>
                <select
                  className='form-control'
                  value={this.state.hangar}
                  onChange={(e) => this.setState({ hangar: e.target.value })}
                >
                  <option value=''>Todos os hangares</option>
                  {this.state.filter_hangars &&
                    this.state.filter_hangars.map(this.renderHangarsOptions)}
                </select>
              </div>
            </div>
            {/* <div className="col-lg-4">
                  <div className="input-group m-b">
                      <span className="input-group-addon">
                          <i className="fa fa-plane"></i>
                      </span>
                      <select
                          className="form-control"
                          value={this.state.aircraft}
                          onChange={e => this.setState({ aircraft: e.target.value })}
                      >
                          <option value=''>Todas as aeronaves</option>
                          {this.state.filter_aircrafts.map(this.renderAircraftsOptions)}
                      </select>
                  </div>
              </div> */}
            <div className='col-lg-4'>
              <div className='input-group m-b'>
                <span className='input-group-addon'>
                  <i className='fa fa-calendar-alt'></i>
                </span>
                <input
                  type='datetime-local'
                  className='form-control'
                  value={this.state.date}
                  onChange={(e) => this.setState({ date: e.target.value })}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6'></div>
            <div className='col-lg-6 row'>
              <div className='col-md-6'>
                <button
                  onClick={this.handleClear}
                  className='btn btn-danger btn-block'
                >
                  Limpar Filtro
                </button>
              </div>
              <div className='col-md-6'>
                <button
                  onClick={this.handleSearch}
                  className='btn btn-success btn-block'
                >
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Dashboard images */}
        <div
          className='wrapper wrapper-content animated fadeInRight col-lg-12'
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            flexWrap: 'wrap',
          }}
        >
          <LoadingModal visible={this.state.loading} />
          {this.state.hangars.length > 0 ? (
            this.state.hangars.map(this.renderSVG)
          ) : (
            <div
              style={{
                textAlign: 'center',
                fontSize: 20,
                height: 100,
                verticalAlign: 'middle',
              }}
            >
              {/* Nenhum hangar encontrado! */}
            </div>
          )}
        </div>
      </div>
    );
  }
}
