import * as Moment from 'moment';
import React, { Component } from 'react';
import { browserHistory, Link } from 'react-router';
import Select from 'react-select';
import RoundedPagination from '../../components/common/RoundedPagination';
import EvaluationService from '../../services/EvaluationService';
import UsersService from '../../services/UsersService';
import LoadingModal from '../LoadingModal';
import Swal from 'sweetalert2';
import FileService from '../../services/FileService';
import "./styles.css";

export default class MechanicsEvaluation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadingReport: false,
      searching: false,
      evaluated: [],
      evaluations: [],
      filter: '',
      pages: 0,
      page: 1,
      base: null,
      code: '',
      user: null,
      filter_users: [],
      start_date: '',
      end_date: '',
      recent_mechanics: [],
      menuIsOpen: false
    };
    this.selectorRef = React.createRef()

    const role = JSON.parse(localStorage.getItem('@omni/fast_track/user')).role;
    if (role !== 'ADM' && role !== 'ADM - TI' && role !== 'AVALIADOR') {
      browserHistory.push('/dashboard');
    }
  }

  getUsers = async () => {
    const mechanics = await UsersService.getAllMechanics();
    const filter_users = mechanics.map((user) => ({
      label: `${user.name} (${user.registration})`,
      value: user.registration
    }))
    this.setState({
      mechanics,
      filter_users: filter_users ? filter_users : [],
    });
  };

  validationDate = (date) => {
    return !date
      ? null
      : `${new Date(date).getFullYear()}-${`${new Date(date).getMonth() + 1
        }`.padStart(2, 0)}-${`${new Date(date).getDate()}`.padStart(
          2,
          0
        )}T${`${new Date(date).getHours()}`.padStart(2, 0)}:${`${new Date(
          date
        ).getMinutes()}`.padStart(2, 0)}`;
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.getUsers();
    this.getEvaluations();
    this.getRecentMechanics()
  }

  componentDidUpdate(prevProps, { page }) {
    if (page !== this.state.page) this.getEvaluations();
  }

  getMechanicsFromLocalStorage() {
    let localMechanics = []
    if (localStorage.getItem('@omni/fast_track/mechanics')) {
      localMechanics = JSON.parse(localStorage.getItem('@omni/fast_track/mechanics'))
    }
    return localMechanics
  }

  getRecentMechanics() {
    const localMechanics = this.getMechanicsFromLocalStorage();
    const mecsToShow = this.state.evaluated && localMechanics && localMechanics.filter(({ registration: selectedRegistration }) => !this.state.evaluated.some((filteredMechanic) => filteredMechanic.value === selectedRegistration))
    console.log(localMechanics)
    console.log(this.state.evaluated)
    console.log(mecsToShow)
    this.setState({ recent_mechanics: mecsToShow })
  }

  saveMechanicsToLocalStorage(mechanics) {
    const localMechanics = this.getMechanicsFromLocalStorage()
    mechanics.map((mechanic) => {
      if (localMechanics.some((imec) => imec._id === mechanic._id)) return;
      if (localMechanics.length >= 5) localMechanics.shift();
      localMechanics.push(mechanic)
    })
    localStorage.setItem('@omni/fast_track/mechanics', JSON.stringify(localMechanics))
  }

  addRecentMechanicToEvalutedList(mechanic) {
    const evaluatedList = this.state.evaluated;
    if (evaluatedList.some((evaluated) => evaluated.value === mechanic.registration)) return;
    evaluatedList.push({
      label: `${mechanic.name} (${mechanic.registration})`,
      value: mechanic.registration
    })
    this.setState({ evaluated: evaluatedList });
    this.setState({ menuIsOpen: true });
    this.selectorRef.current.focus();
    this.getRecentMechanics();
  }

  handleBlur() {
    this.setState({ menuIsOpen: false });
    this.getRecentMechanics();
  }

  getEvaluations = async () => {
    this.setState({ loading: true });
    this.page = this.state.page;
    const user = this.state.evaluated && this.state.mechanics && this.state.evaluated.map(({ value: selectedRegistration }) => this.state.mechanics.find((mechanic) => mechanic.registration === selectedRegistration)._id) || []
    const selectedMechanics = this.state.evaluated && this.state.mechanics && this.state.evaluated.map(({ value: selectedRegistration }) => this.state.mechanics.find((mechanic) => mechanic.registration === selectedRegistration))
    this.saveMechanicsToLocalStorage(selectedMechanics || [])
    const obj = await EvaluationService.filter(
      {
        user,
        start_date: this.state.start_date,
        end_date: this.state.end_date,
      },
      this.page
    );

    const evaluations = obj ? obj.result : [];
    const pages = obj ? obj.pages : 0;
    const total = obj ? obj.total : 0;

    await this.setState({ loading: false, evaluations, pages, total });
  };

  renderEvaluations = (evaluation) => {
    return (
      <tr
        id="identify"
        key={evaluation._id}
      >
        <td>
          {evaluation.evaluated && evaluation.evaluated.name ? evaluation.evaluated.name : 'Não informado'}
        </td>
        <td>
          {evaluation.evaluator && evaluation.evaluator.name ? evaluation.evaluator.name : 'Não informado'}
        </td>
        <td>
          {evaluation.createdAt ? Moment(new Date(evaluation.createdAt))
            .locale('pt-BR')
            .format('DD/MM/YYYY HH:mm') : 'Não informado'}
        </td>
        <td>
          {evaluation.result ? evaluation.result : 'Não informado'}
        </td>
        <td>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => browserHistory.push(`/mechanics-evaluation/${evaluation._id}`)}
            style={{ cursor: 'pointer' }}
          >
            <i class="far fa-eye icon-mr" aria-hidden="true"></i>
            Ver detalhes
          </button>
        </td>
      </tr>
    );
  };

  handleClear = async () => {
    await this.setState({
      user: null,
      evaluations: [],
      page: 1,
      start_date: '',
      end_date: '',
    });
    this.getEvaluations();
    this.setState({ searching: true });
  };

  handlePagination = (event, value) => {
    this.setState({ page: value });
  };

  renderUsersOptions = (user) => (
    <option value={user._id}>
      {`${user.name}`}
    </option>
  );

  handleGenerate = async () => {
    this.setState({ loadingReport: true });
    const confirmation = (await Swal.fire({
      title: 'Confirmação',
      text: 'Você tem certeza que deseja gerar este relatório?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    })).value;
    if (confirmation) {
      await FileService.fetchEvaluationReport(this.state);
      setTimeout(() => {
        this.setState({ loadingReport: false });
      }, 4000);
    }
  }

  render() {
    return (
      <div>
        <div className="wrapper wrapper-content animated fadeInDown">
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-8 col-md-12">
                  <h2>Avaliação de Mecânico</h2>
                  <ol className="breadcrumb">
                    <li>
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="active">
                      <strong>Avaliação de Mecânico</strong>
                    </li>
                  </ol>
                </div>
                <div className='col-lg-4 col-md-12' style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '20px', gap: 12 }}>
                  <button
                    className='btn btn-success' disabled={this.state.loading || this.state.loadingReport} onClick={this.handleGenerate}>
                    <i className='fa fa-download' style={{ marginRight: '8px' }}></i>
                    Gerar Relatório
                  </button>
                  <button
                    className='btn btn-primary'
                    onClick={() => browserHistory.push('/evaluate-mechanic')}
                  >
                    <i className='fa fa-plus' style={{ marginRight: '8px' }}></i>
                    Realizar novo registro
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-12" style={{ paddingTop: 20 }}>
              <div className="row">
                <div className="col-xs-12 col-md-6 col-xl-6">
                  <span className='mec-label'>
                    Mecânicos
                  </span>
                  <div className="input-group m-b mec-input">
                    <span className="input-group-addon">
                      <i className="fa fa-user"></i>
                    </span>
                    <Select
                      isMulti
                      options={this.state.filter_users}
                      name='users'
                      className='multi-select'
                      defaultValue={''}
                      placeholder="Selecione os mecânicos"
                      value={this.state.evaluated}
                      onChange={(value) => {
                        this.setState({ evaluated: value });
                      }}
                      noOptionsMessage={() => "Nenhum mecânico encontrado"}
                      ref={this.selectorRef}
                      onFocus={() => this.setState({ menuIsOpen: true })}
                      onBlur={() => this.handleBlur()}
                      menuIsOpen={this.state.menuIsOpen}
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-xl-3">
                  <span className='mec-label'>
                    Data da Avaliação (de)
                  </span>
                  <div className="input-group m-b mec-input">
                    <span className="input-group-addon">
                      <i className="fa fa-plane"></i>
                    </span>
                    <input
                      type="date"
                      className="form-control"
                      value={this.state.start_date}
                      onChange={(e) =>
                        this.setState({
                          start_date: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-xl-3">
                  <span className='mec-label'>
                    Data da Avaliação (até)
                  </span>
                  <div className="input-group m-b mec-input">
                    <span className="input-group-addon">
                      <i className="fa fa-plane"></i>
                    </span>
                    <input
                      type="date"
                      className="form-control"
                      value={this.state.end_date}
                      onChange={(e) =>
                        this.setState({
                          end_date: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                {this.state.recent_mechanics.length > 0 && (
                  <div className="col-lg-12" style={{ marginBottom: 16, fontSize: 14 }}>
                    <span>
                      <b>Recentes: </b>
                    </span>
                    {this.state.recent_mechanics.map((mechanic, index) => (
                      <>
                        <span className="text-primary recent-mec" onClick={() => this.addRecentMechanicToEvalutedList(mechanic)}>{mechanic.name}</span>
                        <span>{this.state.recent_mechanics.length > index + 1 && ", "}</span>
                      </>
                    ))}
                  </div>
                )}

                <div className="col-xs-12">
                  <div className="row">
                    <div className="col-xs-12 col-sm-6">
                      <div className="row">
                        <div
                          className="col-xs-12 col-sm-6"
                          style={{ padding: 5, paddingTop: 20 }}
                        >
                          <button
                            onClick={this.handleClear}
                            className="btn btn-danger btn-block"
                          >
                            Limpar Filtro
                          </button>
                        </div>
                        <div
                          className="col-xs-12 col-sm-6"
                          style={{ padding: 5, paddingTop: 20 }}
                        >
                          <button
                            onClick={this.getEvaluations}
                            className="btn btn-success btn-block"
                          >
                            Buscar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row wrapper wrapper-content">
            <LoadingModal className="col-lg-12" visible={this.state.loading} />
            <div className="row animated fadeInDown">
              <div
                hidden={this.state.loading}
                className="col-lg-12 table-responsive ibox-content"
                style={{
                  border: 'rgba(0,0,0,0.05) solid 1px',
                  fontSize: 'smaller',
                  overflowX: 'auto',
                }}
              >
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope='col'>Mecânico</th>
                      <th scope='col'>Avaliador</th>
                      <th scope='col'>Data</th>
                      <th scope='col'>Nota Final</th>
                      <th scope='col'>Ações</th>
                    </tr>
                  </thead>
                  <tbody className='evaluation'>
                    {this.state.evaluations.length > 0 ? (
                      this.state.evaluations.map(this.renderEvaluations)
                    ) : (
                      <tr>
                        <td
                          colSpan={16}
                          style={{
                            textAlign: 'center',
                            fontSize: 20,
                            height: 100,
                            verticalAlign: 'middle',
                          }}
                        >
                          Nenhuma avaliação encontrada.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <RoundedPagination
                  count={this.state.pages}
                  onChange={this.handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
