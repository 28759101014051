import React, { useEffect } from 'react';

const Footer = () => {
  const dt = new Date();

  const getMonth = () => {
    if (dt) {
      return dt.getFullYear();
    }

    return '';
  };

  return (
    <div className="footer">
      <div className="pull-right">
        Powered By: Criatech Soluções LTDA. V1.7.0
      </div>
      <div>
        <strong>Copyright</strong> Omni Táxi Aéreo &copy; {getMonth()}
      </div>
    </div>
  )
};

export default Footer;