import React, { Component, Fragment } from "react";
import { Link, browserHistory } from "react-router";
import moment from "moment";
import { TextField } from "@material-ui/core";
import { IoMdInformationCircleOutline } from "react-icons/io";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import ManagementService from "../../../services/ManagementService";
import BaseService from "../../../services/BasesService";
import UsersService from "../../../services/UsersService";
import LoadingModal from "../../LoadingModal";
import Modal from '../MosaByAuditorModal';
import RoundedPagination from '../../../components/common/RoundedPagination';

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      mechanics: [],
      filter: "",
      pages: 0,
      page: 1,
      filter_bases: [],
      users: [],
      base: "",
      name: "",
      period: 1,
      period_type: "year",
      selectedMechanic: null,
      value: 0,
      auditors: [],
      auditor: null,
      services: [],
      next_services: [],
      pages: 0,
      page: 1,
      start_service: '',
      end_service: '',
      start_mosa: '',
      openModal: false
    };
    if (
      JSON.parse(localStorage.getItem("@omni/fast_track/user")).role === "PCP"
    )
      browserHistory.push("/pcp-archives");
  }

  validationDate = (date) => {
    return !date
      ? null
      : `${new Date(date).getFullYear()}-${`${new Date(date).getMonth() + 1
        }`.padStart(2, 0)}-${`${new Date(date).getDate()}`.padStart(
          2,
          0
        )}`
        // T${`${new Date(date).getHours()}`.padStart(2, 0)}:${`${new Date(
        //   date
        // ).getMinutes()}`.padStart(2, 0)}`;
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    const bases = await BaseService.getAll();
    const auditors = await UsersService.get(1, { text: '', base: '', role: 'AUDITOR MOSA' });
    let next_month = new Date();
    next_month.setMonth(new Date().getMonth()+1);
    this.setState({
      filter_bases: bases ? bases : [],
      searching: true,
      auditors: auditors.result,
      start_mosa: this.validationDate(new Date()),
      end_mosa: this.validationDate(next_month)
    });
    this.getNextServices();
  }

  handleClear = async () => {
    let start_service = new Date();
    this.setState({start_mosa: this.validationDate(start_service)});
    start_service.setFullYear(start_service.getFullYear() - 1);

    let end_service = new Date();
    end_service.setMonth(end_service.getMonth() + 1);
    this.setState({end_mosa: this.validationDate(end_service)});
    end_service.setFullYear(end_service.getFullYear() - 1);

    this.setState({
      loading: true,
      next_services: []
    });

    const obj = await ManagementService.next_pendencies(
      {
        start_service,
        end_service
      },
      this.page
    );
    this.page = this.state.page;
    const next_services = obj ? obj.docs : [];
    const pages = obj ? obj.pages : 0;
    const total = obj ? obj.total : 0;
    this.setState({ next_services, pages, total, loading: false  });
  };

  getNextServices = async () => {
    this.setState({ loading: true });
    const page = this.state.page;
    let start_service = new Date(this.state.start_mosa);
    start_service.setFullYear(start_service.getFullYear() - 1);
    let end_service = new Date(this.state.end_mosa);
    end_service.setFullYear(end_service.getFullYear() - 1);
    const obj = await ManagementService.next_pendencies(
      {
        start_service,
        end_service
      },
      page
    );
    const next_services = obj ? obj.docs : [];
    const pages = obj ? obj.pages : 0;
    const total = obj ? obj.total : 0;
    this.setState({ next_services, page, pages, total, loading: false  });
  };

  handlePagination = (event, value) => {
    this.setState({ searching: true, page: value }, () => {
      this.getNextServices();
    });
    this.setState({ searching: false });
  };

  handleSearch = async (type) => {
    this.setState({ searching: true, page: 1 });
    const {
      base,
      hangar,
      aircraft,
      start_service,
      end_service,
      start_prediction,
      end_prediction,
      complexity,
      programming,
      mosa_status,
      risk,
      code
    } = this.state;
    sessionStorage.setItem(
      '@fast_track/MOSA_filters',
      JSON.stringify({
        base,
        hangar,
        aircraft,
        start_service,
        end_service,
        start_prediction,
        end_prediction,
        complexity,
        programming,
        mosa_status,
        risk,
        code
      })
    );
    this.getNextServices();
  };

  handleCloseModal = () => { this.setState({ openModal: false }) }

  renderNextPendenciesUsers = (user) => {
    return (
      <tr id="identify" key={user._id}>
        <td style={{ minHeight: 60 }}>
          <a>{user.name}</a>
        </td>
        <td>
          <a>{user.register}</a>
        </td>
        <td>
          <a>{`${user.last_mosa.base ? user.last_mosa.base.name : ""} - ${user.last_mosa.base ? user.last_mosa.base.code : ""
            }`}</a>
        </td>
        {user.last_mosa ? (
          <Fragment>
            <td>
              <a>{user.last_mosa.aircraft.prefix}</a>
            </td>
            <td>
              <Link to={`/management/${user.last_mosa._id}`}>
                {user.last_mosa.register}
              </Link>
            </td>
            <td>
              <a>
                {user.last_mosa.mosa_start
                  ? moment(user.last_mosa.mosa_start).format("DD/MM/Y [às] HH:mm")
                  : "Data/hora não definida"}
              </a>
            </td>
          </Fragment>
        ) : (
          <td colSpan={3} align="center">
            Nenhuma auditoria MOSA encontrada nesse período.
          </td>
        )}
        <td className="icon-cell">
          <Tooltip title={<div style={{ padding: '2px 5px', fontSize: 12, fontWeight: 'bold' }}>Clique para mais informações</div>}>
            <IconButton
              // disabled={!user.last_mosa}
              onClick={() => {
                this.setState({ openModal: true, selectedMechanic: user._id });
              }}
              style={{ cursor: "pointer" }}
            >
              <IoMdInformationCircleOutline />
            </IconButton>
          </Tooltip>
        </td>
      </tr>
    );
  };

  render() {
    const styles = {
      dateContainer: {
        display: 'flex',
        flexDirection: 'column',
      },
    };

    return (
      <>
        <Modal open={this.state.openModal} handleClose={this.handleCloseModal} mechanic={this.state.selectedMechanic} />
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-6">
                <h2>Próximas MOSAs</h2>
              </div>
              <div className="col-lg-6"></div>
            </div>
          </div>
          <div className="col-lg-12" style={{ paddingTop: 20 }}>
            <div className="row">
              <div className="col-xs-12 col-md-6 col-xl-3" style={styles.dateContainer}>
                Intervalo de
                <TextField
                  type="date"
                  value={this.state.start_mosa}
                  onChange={(event)=>this.setState({start_mosa:event.target.value})}
                  InputProps={{
                    style: {
                      fontSize: 16,
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-xs-12 col-md-6 col-xl-3" style={styles.dateContainer}>
                Até
                <TextField
                  type="date"
                  value={this.state.end_mosa}
                  onChange={(e) =>
                    this.setState({
                      end_mosa: e.target.value,
                    })
                  }
                  InputProps={{
                    style: {
                      fontSize: 16,
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-xs-12 col-sm-6">
                <div className="row">
                  <div
                    className="col-xs-12 col-sm-6"
                    style={{ padding: 5, paddingTop: 20 }}
                  >
                    <button
                      onClick={this.handleClear}
                      className="btn btn-danger btn-block"
                    >
                      Limpar Filtro
                    </button>
                  </div>
                  <div
                    className="col-xs-12 col-sm-6"
                    style={{ padding: 5, paddingTop: 20 }}
                  >
                    <button
                      onClick={() => this.handleSearch('')}
                      className="btn btn-success btn-block"
                    >
                      Buscar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row animated fadeInRight" style={{ marginTop: "20px" }}>
          <LoadingModal className="col-lg-12" visible={this.state.loading} />
          <div
            hidden={this.state.loading}
            className="col-lg-12 table-responsive ibox-content"
            style={{
              paddingLeft: "40px",
              border: "rgba(0,0,0,0.05) solid 1px",
              paddingRight: "40px",
              paddingBottom: "20px",
              paddingTop: "20px",
            }}
          >
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Registro</th>
                  <th>Base</th>
                  <th>Última Aeronave</th>
                  <th>Último Registro</th>
                  <th>Última MOSA</th>
                  <th className="center-th">Informações</th>
                </tr>
              </thead>
              <tbody>{this.state.next_services.map(this.renderNextPendenciesUsers)}</tbody>
            </table>

            <RoundedPagination count={this.state.pages}
                               onChange={this.handlePagination} />
          </div>
        </div>
      </>
    );
  }
}
