import React, { Component } from 'react';
import { browserHistory, Link } from 'react-router';
import { CircularProgress, IconButton } from '@material-ui/core';
import { uniqueId } from 'lodash';
import { toast } from 'react-toastify';
import Moment from 'moment';
import Swal from 'sweetalert2';
import fileSize from 'filesize';
import axios from 'axios';
import {
  MdError,
  MdCheckCircle,
  MdCloudDownload,
  MdDeleteForever,
} from 'react-icons/md';

import Management from '../services/ManagementService';
import MosaService from '../services/MosaService';
import AircraftService from '../services/AircraftsService';
import AircraftModelService from '../services/AircraftModelsService';
import BaseService from '../services/BasesService';
import UserService from '../services/UsersService';
import HangarsService from '../services/HangarsService';
import LoadingModal from './LoadingModal';
import ProcessService from '../services/ProcessService';
import { apiURL } from '../config/consts';

function fixTimezone(date) {
  if (!date) return null;

  const fixedDate = new Date(date);
  fixedDate.setMinutes(fixedDate.getMinutes() + fixedDate.getTimezoneOffset());
  return fixedDate;
}
export default class EditMosa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
      code: null,
      base: null,
      aircraft: null,
      inspection: null,
      execute_prediction: `NaN-NaN-NaNTNaN:NaN`,
      complexity: null,
      day_credits: null,
      hour_credits: null,
      circle_credits: null,
      register: null,
      man_hour_value: null,
      register_type: '',
      observation: '',
      aircrafts: [],
      aircraft_models: [],
      bases: [],
      hangar: null,
      events: [],
      presence_list: [],
      inpector: null,
      start_service: `NaN-NaN-NaNTNaN:NaN`,
      end_service: `NaN-NaN-NaNTNaN:NaN`,
      mosa_start: `NaN-NaN-NaNTNaN:NaN`,
      mosa_end: `NaN-NaN-NaNTNaN:NaN`,
      hangars: [],
      hangarsPositions: [],
      hangarPosition: {},
      hangarIndex: null,
      users: [],
      references: [],
      products: [],
      proccess: [],
      fileList: [],
    };
    const role = JSON.parse(localStorage.getItem('@omni/fast_track/user')).role;
    if (role === 'PCP' || role === 'VISUALIZADOR')
      browserHistory.push('/dashboard');
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    this.setState({ loading: true });
    const management = await Management.getById(this.props.params.id);
    const attachs = await Management.getAttachs(this.props.params.id);

    const uploadedFiles = attachs.map((file) => ({
      file,
      id: file._id,
      name: file.original_name,
      progress: 0,
      readableSize: fileSize(file.size),
      preview: file.url,
      uploaded: true,
      error: false,
      url: file.url,
    }));
    this.setState({ fileList: uploadedFiles });

    await this.setState({ ...management });
    const hangars = await this.getHangars(management.base);

    const obj = await ProcessService.getAll();
    await this.setState({ proccess: obj });

    const inspector = {
      ...management.inspector,
      confirmation_date: this.validationDate(
        fixTimezone(management.inspector.confirmation_date)
      ),
    };

    const start_service = fixTimezone(management.start_service);

    const end_service = fixTimezone(management.end_service);

    const execute_prediction = fixTimezone(management.execute_prediction);

    const mosa_start = fixTimezone(management.mosa_start);

    const mosa_end = fixTimezone(management.mosa_end);

    const mosa_sent = fixTimezone(management.mosa_sent);

    const presence_list = Array.isArray(management.presence_list)
      ? management.presence_list.map((item) => ({
        ...item,
        confirmation_date: fixTimezone(item.confirmation_date),
      }))
      : management.presence_list;

    await this.setState({
      execute_prediction: this.validationDate(execute_prediction),
      hangars,
      start_service: this.validationDate(start_service),
      end_service: this.validationDate(end_service),
      hangar: management.hangar ? management.hangar._id : null,
      mosa_sent: this.validationDate(mosa_sent),
      mosa_start: this.validationDate(mosa_start),
      mosa_end: this.validationDate(mosa_end),
      base: management.base ? management.base._id : '',
      hangarPosition:
        management.hangar && management.position
          ? {
            hangar: management.hangar,
            position: parseInt(management.position, 10),
          }
          : {},
      inspector,
      presence_list,
    });

    const users = await UserService.getAll();
    await this.setState({ users: users });

    const aircrafts = await AircraftService.getAll();
    await this.setState({ aircrafts: aircrafts });

    const aircrafts_models = await AircraftModelService.getAll();
    await this.setState({ aircrafts_models: aircrafts_models });

    const bases = await BaseService.getAll();
    await this.setState({ bases: bases });
    this.setState({ loading: false });
  };

  getHangars = async (base) => {
    const hangars = await HangarsService.getByBase(base._id);
    if (hangars) {
      let hangarsPositions = [];
      hangars.forEach((hangar) => {
        const composition = hangar.positions.map((pos) => ({
          hangar,
          position: pos.position,
        }));
        hangarsPositions = [...hangarsPositions, ...composition];
      });
      this.setState({ hangarsPositions });
      return hangars;
    }
  };

  validationDate = (date) => {
    return !date
      ? null
      : `${new Date(date).getFullYear()}-${`${new Date(date).getMonth() + 1
        }`.padStart(2, 0)}-${`${new Date(date).getDate()}`.padStart(
          2,
          0
        )}T${`${new Date(date).getHours()}`.padStart(2, 0)}:${`${new Date(
          date
        ).getMinutes()}`.padStart(2, 0)}`;
  };

  validDate = (date) => {
    if (date && date !== 'NaN-NaN-NaNTNaN:NaN') {
      return date;
    } else {
      return null;
    }
  };

  handleSend = async () => {
    const confirmation = (
      await Swal.fire({
        title: 'Confirmação',
        text: 'Você tem certeza que deseja lançar o serviço?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })
    ).value;
    if (confirmation) {
      const result = await Management.edit(this.props.params.id, {
        hangar: this.state.hangarPosition.hangar
          ? this.state.hangarPosition.hangar._id
          : this.state.hangar,
        position: this.state.hangarPosition.position
          ? this.state.hangarPosition.position
          : this.state.position,
        presence_list: this.state.presence_list,
        man_hour_value: this.state.man_hour_value,
        start_service: this.validDate(this.state.start_service),
        end_service: this.validDate(this.state.end_service),
        observation: this.state.observation,
        inspector: this.state.inspector,
        mosa_status: 'MOSA Lançada',
        status: 'Conferido',
        events: this.state.events,
      });

      if (result) {
        browserHistory.push(`/mosa_managements`);
        toast.success('Documento atualizado com sucesso!');
      }
    }
  };

  handleDownloadPDF = async () => {
    window.open(
      `${apiURL}/mosa-pdf/${this.props.params.id}?token=Bearer ${JSON.parse(
        localStorage.getItem('@omni/fast_track/token')
      )}`
    );
  };

  handleUpdate = async () => {
    const confirmation = (
      await Swal.fire({
        title: 'Confirmação',
        text: 'Você tem certeza que deseja conferir o serviço?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })
    ).value;
    if (confirmation) {
      const result = await Management.edit(this.props.params.id, {
        hangar: this.state.hangarPosition.hangar
          ? this.state.hangarPosition.hangar._id
          : this.state.hangar,
        position: this.state.hangarPosition.position
          ? this.state.hangarPosition.position
          : this.state.position,
        presence_list: this.state.presence_list,
        man_hour_value: this.state.man_hour_value,
        start_service: this.validDate(this.state.start_service),
        end_service: this.validDate(this.state.end_service),
        observation: this.state.observation,
        mosa_status: 'MOSA Conferida',
        status: 'Conferido',
        events: this.state.events,
        inspector: this.state.inspector,
      });

      if (result) {
        browserHistory.push(`/mosa_managements`);
        toast.success('Documento atualizado com sucesso!');
      }
    }
  };

  selectColor = (probability, impact) => {
    const result = probability * impact;
    if (result >= 1 && result <= 3) return '#92d050';
    else if (result > 3 && result <= 6) return '#ffc000';
    else if (result > 6 && result <= 12) return '#ff0000';
    else if (result > 12 && result <= 25) return '#c00000';
    else return '#FFF';
  };

  selectResult = (probability, impact) => {
    const result = probability * impact;
    if (result >= 1 && result <= 3) return 'Risco Pequeno';
    else if (result > 3 && result <= 6) return 'Risco Moderado';
    else if (result > 6 && result <= 12) return 'Risco Alto';
    else if (result > 12 && result <= 25) return 'Risco Crítico';
    else return 'Não Há Ameaças';
  };

  renderEvents = (event, i) => {
    return (
      <tr id="identify" key={event._id}>
        {/* <td >{event.description}</td> */}
        <td>
          <textarea
            type="text"
            disabled
            rows="8"
            placeholder="Descrição"
            className="form-control"
            value={event.description}
          ></textarea>
        </td>
        <td>
          <textarea
            type="text"
            disabled
            rows="8"
            placeholder="Gerenciamento"
            className="form-control"
            value={event.management}
          ></textarea>
        </td>
        <td>
          <textarea
            type="text"
            placeholder="Código"
            className="form-control"
            rows="8"
            value={event.factor_code}
            style={{ backgroundColor: '#fff9c4', color: 'black' }}
            onChange={(e) => {
              let events = this.state.events;
              events[i].factor_code = e.target.value;
              this.setState({ events });
            }}
          ></textarea>
        </td>
        <td>
          <textarea
            type="text"
            placeholder="Efeito"
            className="form-control"
            rows="8"
            value={event.result_effect}
            style={{ backgroundColor: '#fff9c4', color: 'black' }}
            onChange={(e) => {
              let events = this.state.events;
              events[i].result_effect = e.target.value;
              this.setState({ events });
            }}
          ></textarea>
        </td>
        <td style={{ flexDirection: 'column', display: 'flex' }}>
          <label for={`impact${i}`}>Impacto</label>
          <select
            id={`impact${i}`}
            value={event.impact}
            onChange={(e) => {
              let events = this.state.events;
              events[i].impact = e.target.value;
              this.setState({ events });
            }}
          >
            <option value="" style={{ color: '#bdbdbd' }}>
              Impacto...
            </option>
            <option value={1}>1 - Insignificante</option>
            <option value={2}>2 - Pequeno</option>
            <option value={3}>3 - Moderado</option>
            <option value={4}>4 - Grande</option>
            <option value={5}>5 - Catastrófico</option>
          </select>
          <label for={`probability${i}`}>Probabilidade</label>
          <select
            id={`probability${i}`}
            value={event.probability}
            onChange={(e) => {
              let events = this.state.events;
              events[i].probability = e.target.value;
              this.setState({ events });
            }}
          >
            <option value="" style={{ color: '#bdbdbd' }}>
              Probabilidade...
            </option>
            <option value={1}>1 - Rara</option>
            <option value={2}>2 - Improvável</option>
            <option value={3}>3 - Possível</option>
            <option value={4}>4 - Provável</option>
            <option value={5}>5 - Quase certo</option>
          </select>
          <label for={`criteria${i}`}>Critério</label>
          <select
            id={`criteria${i}`}
            value={event.criteria}
            onChange={(e) => {
              let events = this.state.events;
              events[i].criteria = e.target.value;
              this.setState({ events });
            }}
          >
            <option value="" style={{ color: '#bdbdbd' }}>
              Critério...
            </option>
            <option value="Gestão">Gestão </option>
            <option value="Regulação">Regulação </option>
            <option value="Reputação">Reputação </option>
            <option value="Negócio">Negócio </option>
            <option value="Intervenção Hierárquica">
              Intervenção Hierárquica{' '}
            </option>
            <option value="Valor Orçamentário">Valor Orçamentário </option>
          </select>
        </td>
        <td style={{ textAlign: 'center' }}>
          {event.probability &&
            event.impact &&
            event.probability * event.impact}
        </td>
        <td
          style={{
            backgroundColor: this.selectColor(event.probability, event.impact),
            color:
              this.selectResult(event.probability, event.impact) ===
                'Não Há Ameaças'
                ? '#000'
                : '#FFF',
            textAlign: 'center',
          }}
        >
          {this.selectResult(event.probability, event.impact)}
        </td>
      </tr>
    );
  };

  renderMechanics = (obj, i) => {
    return (
      <tr id="identify" key={obj._id}>
        <td>{obj.mechanic ? obj.mechanic.name : ''}</td>
        <td>{obj.mechanic ? obj.mechanic.registration : ''}</td>
        <td>
          {obj.confirmed ? (
            <span style={{ fontSize: '18px', color: '#2e7d32' }}>
              <i class="fas fa-thumbs-up"></i>
            </span>
          ) : (
            <span style={{ fontSize: '18px', color: '#c62828' }}>
              <i class="fas fa-thumbs-down"></i>
            </span>
          )}
        </td>
        <td>
          <b>
            {obj.confirmation_date
              ? Moment(new Date(obj.confirmation_date))
                .locale('pt-BR')
                .format('DD/MM/YYYY HH:mm')
              : 'Sem data'}
          </b>
        </td>
      </tr>
    );
  };

  renderProducts = (obj, i) => {
    return (
      <tr id="identify" key={i}>
        <td>{obj.type}</td>
        <td>{obj.pn}</td>
        <td>{obj.description}</td>
      </tr>
    );
  };

  handleDelete = async (file) => {
    const { id } = file;
    const confirmation = (
      await Swal.fire({
        title: 'Confirmação',
        text: 'Você tem certeza que deseja apagar esse anexo?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })
    ).value;
    if (confirmation) {
      axios
        .delete(apiURL + '/pcp_service/attach/' + file.id, {
          onUploadProgress: (ProgressEvent) => {
            const progress = parseInt(
              Math.round(ProgressEvent.loaded * 100) / ProgressEvent.total
            );
            this.updateFile(id, { progress });
          },
        })
        .then((response) => {
          toast.success('Anexo deletado!');
          this.setState({
            fileList: this.state.fileList.filter((file) => file.id !== id),
          });
        })
        .catch((err) => {
          toast.error('Erro ao deletar anexo! ' + err);
        });
    }
  };

  renderReferences = (obj, i) => {
    return (
      <tr id="identify" key={i}>
        <td>{obj.type}</td>
        <td>{obj.identification}</td>
        <td>{obj.section}</td>
        <td>{obj.register_type}</td>
        <td>{obj.register_identification}</td>
      </tr>
    );
  };

  renderAircraftOptions = (aircraft) => (
    <option value={aircraft._id}>
      {`${aircraft.prefix}`} ({`${aircraft._model.manufacturer}`} -{' '}
      {`${aircraft._model.model}`})
    </option>
  );

  renderBaseOptions = (base) => (
    <option value={base._id}>
      {`${base.name}`} ({`${base.code}`}){' '}
    </option>
  );
  renderHangarOptions = (hangar) => (
    <option value={hangar._id}>
      {`${hangar.name}`} ({`${hangar.code}`}){' '}
    </option>
  );
  renderPositionOptions = (position) => (
    <option value={position.position}>{`Posição ${position.position}`} </option>
  );

  renderProccessOptions = (proccess) => (
    <option value={proccess._id}>
      {`${proccess.code}-${proccess.description}`}{' '}
    </option>
  );

  renderUserOptions = (user) => (
    <option value={user._id}>{`${user.name}`} </option>
  );

  updateFile = async (id, data) => {
    this.setState({
      fileList: await this.state.fileList.map((file) => {
        return id == file.id ? { ...file, ...data } : file;
      }),
    });
  };

  uploadFile = (file) => {
    const data = new FormData();
    let id = JSON.parse(localStorage.getItem('@omni/fast_track/user'))._id;
    let mosa_id = this.props.params.id;
    data.append('file', file.file, file.name);
    data.append('mosa_id', this.props.params.id);

    axios
      .post(apiURL + '/pcp_service/attach/' + id, data, {
        onUploadProgress: (ProgressEvent) => {
          const progress = parseInt(
            Math.round(ProgressEvent.loaded * 100) / ProgressEvent.total
          );
          this.updateFile(file.id, { progress });
        },
      })
      .then((response) => {
        this.updateFile(file.id, {
          id: response.data.result._id,
          uploaded: true,
          url: response.data.result.url,
        });
        toast.success('Upload concluído!');
      })
      .catch((err) => {
        this.updateFile(file.id, { error: true, uploaded: false });
        toast.error('Erro ao subir arquivo! ' + err);
      });
  };

  render() {
    return (
      <div className="wrapper wrapper-content">
        <LoadingModal visible={this.state.loading} />
        <div
          className="row wrapper border-bottom white-bg page-heading animated bounceInRight"
          style={{ margin: 'auto', padding: '40px' }}
        >
          <h2>Conferir serviço MOSA</h2>
          <button
            style={{ float: 'right' }}
            class="btn btn-success"
            onClick={() => this.handleDownloadPDF()}
          >
            Baixar PDF
          </button>
          <br />
          <div className="row">
            <div className="col-lg-12 row">
              <div
                className="col-lg-12 row"
                style={{
                  paddingLeft: '40px',
                  paddingRight: '40px',
                  paddingBottom: '20px',
                  paddingTop: '0px',
                  margin: '0px',
                }}
              >
                <h4
                  className="col-lg-12"
                  style={{
                    borderBottom: '1.5px solid rgba(0,0,0,0.07)',
                    textAlign: 'center',
                    marginTop: '20px',
                    marginBottom: '20px',
                    padding: '10px',
                    color: 'red',
                    fontSize: '18px',
                  }}
                >
                  DADOS DO SERVIÇO
                </h4>
                <div className="col-lg-6">
                  <h4>
                    <b>Aeronave</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-plane"></i>
                    </span>
                    <select
                      className="form-control"
                      disabled
                      value={
                        this.state.aircraft
                          ? this.state.aircraft._id || this.state.aircraft
                          : null
                      }
                      onChange={(e) =>
                        this.setState({ aircraft: e.target.value })
                      }
                    >
                      <option value="" disabled>
                        Selecione uma aeronave
                      </option>
                      {this.state.aircrafts.map(this.renderAircraftOptions)}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Base</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-home"></i>
                    </span>
                    <select
                      className="form-control"
                      value={this.state.base}
                      disabled
                      onChange={(e) => {
                        this.setState({
                          base: e.target.value,
                          hangarIndex: null,
                        });
                        this.getHangars(e.target.value);
                      }}
                    >
                      <option value="" disabled>
                        Selecione uma base
                      </option>
                      {this.state.bases.map(this.renderBaseOptions)}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Hangar</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-warehouse"></i>
                    </span>
                    <select
                      className="form-control"
                      value={this.state.hangar}
                      disabled
                      onChange={(e) => {
                        this.setState({ hangar: e.target.value });
                      }}
                    >
                      <option value="">Selecione uma hangar</option>
                      {this.state.hangars.map(this.renderHangarOptions)}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Posição</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-map-pin"></i>
                    </span>
                    <select
                      className="form-control"
                      value={this.state.hangarPosition.position}
                      disabled
                      onChange={(e) => {
                        this.setState({ hangarPosition: e.target.value });
                      }}
                    >
                      <option value="">Selecione uma posição</option>
                      {this.state.hangar && this.state.hangars.length > 0 && this.state.hangars.some((h) => h._id === this.state.hangar)
                        ? this.state.hangars.find((h) => h._id === this.state.hangar).positions.map(this.renderPositionOptions)
                        : null}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Tipo - Descrição</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-map-marked-alt"></i>
                    </span>
                    <input
                      type="text"
                      disabled
                      placeholder="Tipo - Descrição"
                      className="form-control"
                      value={`${this.state.position_type ? this.state.position_type : ''
                        }${this.state.position_description
                          ? ' - ' + this.state.position_description
                          : ''
                        }`}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Inspeção</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-cogs"></i>
                    </span>
                    <input
                      type="text"
                      disabled
                      placeholder="Inspeção"
                      className="form-control"
                      value={this.state.inspection}
                      onChange={(e) =>
                        this.setState({ inspection: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Complexidade</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-fire"></i>
                    </span>
                    <select
                      disabled
                      className="form-control"
                      value={this.state.complexity}
                      onChange={(e) =>
                        this.setState({ complexity: e.target.value })
                      }
                    >
                      <option value="">Todas as Complexidades</option>
                      <option value="Base">Base</option>
                      <option value="Line">Line</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Previsão de Execução</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-calendar"></i>
                    </span>
                    <input
                      type="datetime-local"
                      disabled
                      className="form-control"
                      value={this.state.execute_prediction}
                      onChange={(e) =>
                        this.setState({ execute_prediction: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Tipo de Registro</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-check-circle"></i>
                    </span>
                    <select
                      type="text"
                      className="form-control"
                      value={this.state.register_type}
                      onChange={(e) =>
                        this.setState({ registerType: e.target.value })
                      }
                      disabled={this.state.programming}
                    >
                      <option value="">Tipo de Registro</option>
                      <option value="TC">TC</option>
                      <option value="DB">DB</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Registro</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-key"></i>
                    </span>
                    <input
                      type="text"
                      disabled
                      placeholder="Registro"
                      className="form-control"
                      value={this.state.register}
                      onChange={(e) =>
                        this.setState({ register: e.target.value })
                      }
                    />
                  </div>
                </div>
                <h4
                  className="col-lg-12"
                  style={{
                    borderBottom: '1.5px solid rgba(0,0,0,0.07)',
                    textAlign: 'center',
                    marginTop: '20px',
                    marginBottom: '20px',
                    padding: '10px',
                    color: 'red',
                    fontSize: '18px',
                  }}
                >
                  DADOS DA AUDITORIA
                </h4>

                <div className="col-lg-12">
                  <h4>
                    <b>Código MOSA</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-tag"></i>
                    </span>
                    <input
                      disabled
                      className="form-control"
                      value={this.state.code}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <h4>
                    <b>Envio MOSA</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-calendar"></i>
                    </span>
                    <input
                      type="datetime-local"
                      disabled
                      className="form-control"
                      value={this.state.mosa_sent}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Início MOSA</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-calendar"></i>
                    </span>
                    <input
                      type="datetime-local"
                      disabled
                      className="form-control"
                      value={this.state.mosa_start}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Fim MOSA</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-calendar"></i>
                    </span>
                    <input
                      type="datetime-local"
                      disabled
                      className="form-control"
                      value={this.state.mosa_end}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Pontos Positivos</b>
                  </h4>
                  <textarea
                    type="text"
                    disabled
                    rows="6"
                    placeholder="Pontos Positivos"
                    className="form-control"
                    value={this.state.good_points}
                    onChange={(e) =>
                      this.setState({ good_points: e.target.value })
                    }
                  ></textarea>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Pontos Negativos</b>
                  </h4>
                  <textarea
                    type="text"
                    disabled
                    rows="6"
                    placeholder="Pontos Negativos"
                    className="form-control"
                    value={this.state.bad_points}
                    onChange={(e) =>
                      this.setState({ bad_points: e.target.value })
                    }
                  ></textarea>
                </div>
                <div className="col-lg-12">
                  <h4>
                    <b>Comentários</b>
                  </h4>
                  <textarea
                    type="text"
                    disabled
                    rows="8"
                    placeholder="Comentários"
                    className="form-control"
                    value={this.state.comments}
                    onChange={(e) =>
                      this.setState({ comments: e.target.value })
                    }
                  ></textarea>
                </div>
                <h4
                  className="col-lg-12"
                  style={{
                    borderBottom: '1.5px solid rgba(0,0,0,0.07)',
                    textAlign: 'center',
                    marginTop: '20px',
                    marginBottom: '20px',
                    padding: '10px',
                    color: 'red',
                    fontSize: '18px',
                  }}
                >
                  AUDITOR
                </h4>
                <div className="col-lg-6">
                  <h4>
                    <b>Auditor:</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-user"></i>
                    </span>
                    <input
                      disabled
                      className="form-control"
                      value={
                        this.state.inspector && this.state.inspector.inspector
                          ? this.state.inspector.inspector.name
                          : null
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Data de Confirmação</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-calendar"></i>
                    </span>
                    <input
                      type="datetime-local"
                      disabled
                      className="form-control"
                      value={
                        this.state.inspector
                          ? this.state.inspector.confirmation_date
                          : null
                      }
                      onChange={(e) =>
                        this.setState({ execute_prediction: e.target.value })
                      }
                    />
                  </div>
                </div>
                {this.state.mosa_status === 'MOSA não Realizada' ? (
                  <>
                    <h4
                      className="col-lg-12"
                      style={{
                        borderBottom: '1.5px solid rgba(0,0,0,0.07)',
                        textAlign: 'center',
                        marginTop: '20px',
                        marginBottom: '20px',
                        padding: '10px',
                        color: 'red',
                        fontSize: '18px',
                      }}
                    >
                      MOTIVO DO CANCELAMENTO DA MOSA
                    </h4>
                    <div className="col-lg-12">
                      <h4>
                        <b>Motivo:</b>
                      </h4>
                      <div className="input-group m-b">
                        <span className="input-group-addon">
                          <i className="fa fa-clipboard"></i>
                        </span>
                        <input
                          disabled
                          className="form-control"
                          value={
                            this.state.reason && this.state.reason.title
                              ? this.state.reason.title
                              : null
                          }
                        />
                      </div>
                      <h4>
                        <b>Descrição:</b>
                      </h4>
                      <textarea
                        rows="4"
                        disabled
                        className="form-control"
                        value={
                          this.state.reason && this.state.reason.description
                            ? this.state.reason.description
                            : null
                        }
                      ></textarea>
                      <h4>
                        <b>Observações:</b>
                      </h4>
                      <textarea
                        rows="4"
                        disabled
                        className="form-control"
                        value={
                          this.state.reason_obs ? this.state.reason_obs : null
                        }
                      ></textarea>
                    </div>
                  </>
                ) : null}

                <h4
                  className="col-lg-12"
                  style={{
                    borderBottom: '1.5px solid rgba(0,0,0,0.07)',
                    textAlign: 'center',
                    marginTop: '20px',
                    marginBottom: '20px',
                    padding: '10px',
                    color: 'red',
                    fontSize: '18px',
                  }}
                >
                  PROCESSO OBSERVADO
                </h4>
                <div className="col-lg-12">
                  <h4>
                    <b>Processo:</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-clipboard"></i>
                    </span>
                    <select
                      className="form-control"
                      disabled
                      value={this.state.process}
                    >
                      <option>Nenhum processo selecionado</option>
                      {this.state.proccess
                        ? this.state.proccess.map(this.renderProccessOptions)
                        : null}
                    </select>
                  </div>
                </div>
                <div className="col-lg-12" style={{ marginBottom: '20px' }}>
                  <h3>
                    <b>Produtos/Atividades Auditados:</b>
                  </h3>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Tipo</th>
                          <th>PN ou Modelo</th>
                          <th>Descrição</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.products.map(this.renderProducts)}
                      </tbody>
                    </table>
                  </div>
                </div>

                <h4
                  className="col-lg-12"
                  style={{
                    borderBottom: '1.5px solid rgba(0,0,0,0.07)',
                    textAlign: 'center',
                    marginTop: '20px',
                    marginBottom: '20px',
                    padding: '10px',
                    color: 'red',
                    fontSize: '18px',
                  }}
                >
                  REFERÊNCIAS
                </h4>
                <div className="col-lg-12" style={{ marginBottom: '20px' }}>
                  <div className="table-responsive" style={{}}>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th colSpan={3}>
                            Dados Técnicos ou Procedimentos Internos
                          </th>
                          <th colSpan={2}>Registros</th>
                        </tr>
                        <tr>
                          <th>Tipo</th>
                          <th>Identificação</th>
                          <th>Seção</th>
                          <th>Tipo</th>
                          <th>Identificação</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.references.map(this.renderReferences)}
                      </tbody>
                    </table>
                  </div>
                </div>

                <h4
                  className="col-lg-12"
                  style={{
                    borderBottom: '1.5px solid rgba(0,0,0,0.07)',
                    textAlign: 'center',
                    marginTop: '20px',
                    marginBottom: '20px',
                    padding: '10px',
                    color: 'red',
                    fontSize: '18px',
                  }}
                >
                  DADOS DO EVENTO OBSERVADO
                </h4>
                <div className="col-lg-12" style={{ marginBottom: '20px' }}>
                  <div className="table-responsive" style={{}}>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Descrição da Ameaça</th>
                          <th>Gerenciamento da Ameaça</th>
                          <th>Código do Fator Contribuinte</th>
                          <th>Efeito Resultante</th>
                          <th>Definições de Risco</th>
                          <th>Resultado</th>
                          <th>Nível de Risco</th>
                        </tr>
                      </thead>
                      <tbody>{this.state.events.map(this.renderEvents)}</tbody>
                    </table>
                    <a
                      href="/documents/FatoresDeAnaliseMOSA.pdf"
                      alt="Consultar Fatores de Análise"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ cursor: 'pointer' }}
                    >
                      <button
                        className="btn btn-info"
                        style={{ margin: '2px' }}
                      >
                        Consultar Fatores de Análise
                      </button>
                    </a>
                  </div>
                </div>
                <h4
                  className="col-lg-12"
                  style={{
                    borderBottom: '1.5px solid rgba(0,0,0,0.07)',
                    textAlign: 'center',
                    marginTop: '20px',
                    marginBottom: '20px',
                    padding: '10px',
                    color: 'red',
                    fontSize: '18px',
                  }}
                >
                  PARTICIPANTES
                </h4>
                <div className="col-lg-12" style={{ marginBottom: '30px' }}>
                  <div className="table-responsive" style={{}}>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th>Matrícula</th>
                          <th>Confirmação</th>
                          <th>Data/Hora Confirmação</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.presence_list.map(this.renderMechanics)}
                      </tbody>
                    </table>
                  </div>
                </div>
                <h4
                  className="col-lg-12"
                  style={{
                    borderBottom: '1.5px solid rgba(0,0,0,0.07)',
                    textAlign: 'center',
                    marginTop: '20px',
                    marginBottom: '20px',
                    padding: '10px',
                    color: 'red',
                    fontSize: '18px',
                  }}
                >
                  ANEXOS
                </h4>

                <label
                  for="file_input"
                  className="btn btn-success"
                  to="/pcp-import"
                >
                  <i className="fa fa-upload"></i> Novo anexo
                </label>
                <input
                  id="file_input"
                  multiple
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(event) => {
                    console.log('event: ', event.target.files);
                    const list = [...event.target.files];
                    const uploadedFiles = list.map((file, index) => ({
                      file,
                      id: uniqueId(),
                      name: file.name,
                      progress: 0,
                      readableSize: fileSize(file.size),
                      preview: URL.createObjectURL(file),
                      uploaded: false,
                      error: false,
                      url: null,
                    }));
                    const concatList = [
                      ...this.state.fileList,
                      ...uploadedFiles,
                    ];

                    const filter_list = concatList.filter((el) => {
                      if (!el.uploaded) {
                        this.uploadFile(el);
                        return el;
                      }
                    });
                    if (!!!filter_list.length)
                      toast.error(
                        'Nenhum arquivo para subir. Adicione um arquivo'
                      );
                    this.setState({ fileList: concatList });
                  }}
                />
                <table
                  className="table table-striped"
                  style={{ marginBottom: 100 }}
                >
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Tamanho</th>
                      <th style={{ textAlign: 'center' }}>Status</th>
                      <th style={{ width: 80 }}>Deletar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!this.state.fileList.length ? (
                      this.state.fileList.map((el, index) => (
                        <tr key={index}>
                          <td
                            title={el.name}
                            style={{
                              maxWidth: 250,
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                            }}
                          >
                            {!!el.url && (
                              <IconButton
                                size="small"
                                style={{ padding: '3px 5px', marginRight: 10 }}
                              >
                                <a
                                  href={el.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <MdCloudDownload
                                    title="Download"
                                    size={24}
                                    style={{ color: 'rgb(25, 42, 57)' }}
                                  />
                                </a>
                              </IconButton>
                            )}
                            {el.name}
                          </td>
                          <td>{el.readableSize}</td>
                          <td style={{ textAlign: 'center' }}>
                            {!el.uploaded && !el.error && (
                              <CircularProgress
                                size={24}
                                style={{ color: 'rgb(25, 42, 57)' }}
                                variant="determinate"
                                value={el.progress}
                              />
                            )}
                            {el.uploaded && (
                              <MdCheckCircle size={24} color="#78e5d5" />
                            )}
                            {el.error && <MdError size={24} color="#e57878" />}
                          </td>
                          <td
                            style={{
                              width: 80,
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'space-between',
                            }}
                          >
                            <IconButton
                              size="small"
                              onClick={() => this.handleDelete(el)}
                            >
                              <MdDeleteForever size={28} color="#e57878" />
                            </IconButton>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={4} style={{ textAlign: 'center' }}>
                          Nenhum arquivo anexado
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="col-lg-12" style={{ textAlign: 'center' }}>
                <button
                  className="btn btn-success"
                  onClick={this.handleUpdate}
                  style={{ margin: '2px' }}
                >
                  Conferir
                </button>
                {(this.state.mosa_status === 'MOSA Realizada' ||
                  this.state.mosa_status === 'MOSA Conferida') && (
                    <button
                      className="btn btn-warning"
                      onClick={this.handleSend}
                      style={{ margin: '2px' }}
                      title="Enviar a MOSA para o coordenador"
                    >
                      Lançar
                    </button>
                  )}
                <button
                  className="btn btn-danger"
                  style={{ margin: '2px' }}
                  onClick={async () => {
                    const confirmation = (
                      await Swal.fire({
                        title: 'Confirmação',
                        text: 'Você tem certeza que deseja cancelar?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                      })
                    ).value;
                    if (confirmation) {
                      browserHistory.push(`/mosa_managements/`);
                    }
                  }}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
