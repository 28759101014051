import React, { Component } from "react";
import { browserHistory, Link } from "react-router";
import RoundedPagination from "../components/common/RoundedPagination";
import BasesService from "../services/BasesService";
import UsersService from "../services/UsersService";

export default class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      filter: "",
      page: props.params.page || 1,
      pages: 0,
      base: "",
      bases: [],
    };
    const role = JSON.parse(localStorage.getItem("@omni/fast_track/user")).role;
    if (role !== "ADM" && role !== "ADM - TI" && role !== "COORDENADOR") {
      browserHistory.push("/dashboard");
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.filter();
    try {
      const bases = await BasesService.getAll();
      if (bases) {
        this.setState({ bases });
      }
    } catch (e) {
    }
  }

  async componentWillReceiveProps(nextProps) {
    this.setState({ page: nextProps.params.page || 1 }, () => {
      this.filter();
    });
  }

  getUsers = async () => {
    this.setState({ loading: true });
    const page = this.state.page;
    const obj = await UsersService.get(page);
    const users = obj ? obj.result : [];
    const pages = obj ? obj.pages : 0;
    this.setState({ users, pages, loading: false });
  };

  filter = async () => {
    if (this.state.filter.length > 0 || this.state.base.length > 0) {
      const { base, filter, page } = this.state;
      const obj = await UsersService.search(page, {
        base,
        text: filter,
      });
      const users = obj ? obj.result : [];
      this.setState({ users, pages: obj.pages });
    } else this.getUsers();
  };

  renderUser = (user) => {
    return (
      <tr
        id="identify"
        key={user._id}
        onClick={() => this.props.history.push(`/user/${user._id}`)}
        style={{ cursor: "pointer" }}
      >
        <td>
          <i
            className={`fa fa-${user.active ? "check" : "times"} text-${user.active ? "success" : "error"
              }`}
          />
        </td>
        <td style={{ color: "#337ab7" }}>{user.name}</td>
        <td>{user.login}</td>
        <td>{user.email}</td>
        <td>{user.role}</td>
        <td>{user.group}</td>
        <td>{user.base && user.base.name}</td>
      </tr>
    );
  };

  handleFilterSubmit = (e) => {
    e.preventDefault();
    this.handlePagination(e, 1);
  }

  handlePagination = (_event, value) => {
    this.setState({ page: value }, () => {
      this.filter();
    });
  }

  render() {
    return (
      <div className="wrapper wrapper-content  animated fadeInDown">
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-6">
                <h2>Usuários</h2>
                <ol className="breadcrumb">
                  <li>
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li>
                    <Link>Configurações</Link>
                  </li>
                  <li className="active">
                    <strong>Usuários</strong>
                  </li>
                </ol>
              </div>
              <div className="row col-lg-6">
                <div
                  style={{
                    paddingTop: 10,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Link to="/add-user">
                    <button
                      className="btn btn-success"
                      data-toggle="modal"
                      data-target="#addModal  "
                    >
                      Adicionar
                    </button>
                  </Link>
                </div>
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <div
                      className="input-group"
                      style={{ marginTop: 15, marginRight: 10 }}
                    >
                      <select
                        className="form-control"
                        value={this.state.base}
                        onChange={(e) => {
                          this.setState({ base: e.target.value });
                        }}
                        style={{
                          backgroundColor: "rgba(255, 255, 255, 0.835)",
                          padding: "0px 10px",
                          minWidth: 250,
                        }}
                      >
                        <option value="">Todas as Bases</option>
                        {this.state.bases.map((base) => (
                          <option key={base._id} value={base._id}>{base.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="input-group " style={{ marginTop: 15 }}>
                      <input
                        type="text"
                        placeholder="Procurar Usuário"
                        className="form-control"
                        value={this.state.filter}
                        onChange={(e) =>
                          this.setState({ filter: e.target.value })
                        }
                        onKeyPress={event => {
                          if (event.key === 'Enter') {
                            this.handleFilterSubmit(event)
                          }
                        }}
                        style={{
                          backgroundColor: "rgba(255, 255, 255, 0.835)",
                          width: '100%',
                          minWidth: 250,
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: 10,
                    }}
                  >
                    <button className="btn btn-success" onClick={this.handleFilterSubmit}>
                      <i className="fa fa-search"></i> Filtrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper wrapper-content">
          <div className="row">
            <div className="col-lg-12 ibox-content">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Ativo</th>
                      <th>Nome</th>
                      <th>Login</th>
                      <th>Email</th>
                      <th>Perfil</th>
                      <th>Setor</th>
                      <th>Base</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.loading ? (
                      <tr>
                        <th colSpan={6}>
                          <div className="spinner spinner-lg" />
                        </th>
                      </tr>
                    ) : (
                      this.state.users.map(this.renderUser)
                    )}
                  </tbody>
                </table>
              </div>

              <RoundedPagination onChange={this.handlePagination}
                page={this.state.page}
                count={this.state.pages} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
