import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { Box } from '@material-ui/core';

const RoundedPagination = (props) => {
  return (
    <Box display="flex"
         alignItems="center"
         justifyContent="center">
      <Pagination count={props.count}
                  shape="rounded"
                  variant="outlined"
                  page={props.page ? Number(props.page) : undefined}
                  onChange={props.onChange}
                  siblingCount={3} />
    </Box>
  );
};

export default RoundedPagination;
