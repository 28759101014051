import React, { Component, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import AlertService from '../../../services/AlertService';
import FlightStepService from '../../../services/FlightStepService';
import { toast } from 'react-toastify';

export default function AlertModal(props) {
  const [state, setState] = useState({
    active: true,
    pages: 0,
    page: 1,
    list: [],
    observation: '',
    description: '',
    pcp_service: 'apple',
    indicator: '',
    acquisition: '',
  });
  const [alertType, setAlertType] = useState('Alerta âmbar');
  const [acquisition, setAcquisition] = useState('');
  const [indicator, setIndicator] = useState('');
  const [observation, setObservation] = useState('');
  const [description, setDescription] = useState('');
  const [pcpService, setPcpService] = useState('');

  const getAlert = async (id) => {
    setState((oldState) => ({ ...oldState, loading: true, mosa_list: [] }));
    if (id !== 'new') {
      const response = await AlertService.getById(id);
      setAlertType(response.alert_type);
      setIndicator(response.indicator);
      setObservation(response.observation);
      setAcquisition(response.acquisition);
      setDescription(response.description);
      if (response.pcp_service) setPcpService(response.pcp_service);
    } else {
      setAlertType('Alerta âmbar');
      setIndicator('');
      setObservation('');
      setAcquisition('');
      setDescription('');
      setPcpService('');
    }
  };

  const handleSave = async () => {
    if (props.alert === 'new') {
      const response = await AlertService.add({
        flight_step: props.step,
        alert_type: alertType,
        observation,
        description,
        pcp_service: pcpService,
        indicator,
        acquisition,
      });
      toast.success(response.message);

      const alert_id = response.alert._id;

      const {
        data_status,
        flight_date,
        flight_number,
        flight_report,
        preliminary_analysis,
        upload_status,
      } = await FlightStepService.getById(props.step);

      await FlightStepService.update(
        {
          data_status,
          flight_date,
          flight_number,
          flight_report,
          preliminary_analysis,
          upload_status,
          flight_alert: alert_id,
        },
        props.step
      );
    } else {
      await AlertService.update(
        {
          alert_type: alertType,
          observation,
          description,
          pcp_service: pcpService,
          indicator,
          acquisition,
        },
        props.alert
      );
    }
    props.handleClose();
  };

  return (
    <>
      {/* <LoadingModal visible={state.loading} /> */}
      <Dialog
        onEnter={() => getAlert(props.alert)}
        onExit={() => getAlert(props.alert)}
        className={{ zIndex: 1000000, minHeight: 300 }}
        style={{ zIndex: 1000000 }}
        open={props.open}
        fullWidth={false}
        maxWidth={'lg'}
        scroll={'body'}
        keepMounted
        onClose={props.handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <div
          className='modal-content row wrapper border-bottom white-bg page-heading'
          style={{ margin: 'auto', padding: '30px' }}
        >
          <div className='mosas-modal-header'>
            <h2>Alerta</h2>
            <br />
          </div>
          <div className='row' style={{ marginTop: '20px' }}>
            <>
              <div className='col-lg-3'>
                Tipo
                <div className='input-group m-b'>
                  <span className='input-group-addon'>
                    <i className='fa fa-download'></i>
                  </span>
                  <select
                    className='form-control'
                    value={alertType}
                    onChange={(e) => {
                      setAlertType(e.target.value);
                    }}
                  >
                    <option value={'Alerta âmbar'}>Alerta âmbar</option>
                    <option value={'Alerta vermelho'}>Alerta vermelho</option>
                    <option value={'Alerta de falha'}>Alerta de falha</option>
                  </select>
                </div>
              </div>
              {(alertType === 'Alerta âmbar' ||
                alertType === 'Alerta vermelho') && (
                <>
                  <div className='col-lg-3'>
                    Número da aquisição
                    <div className='input-group m-b'>
                      <span className='input-group-addon'>
                        <i className='fa fa-download'></i>
                      </span>
                      <input
                        type='number'
                        className='form-control'
                        value={acquisition}
                        onChange={(e) => setAcquisition(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='col-lg-3'>
                    Indicador
                    <div className='input-group m-b'>
                      <span className='input-group-addon'>
                        <i className='fa fa-download'></i>
                      </span>
                      <input
                        type='number'
                        className='form-control'
                        value={indicator}
                        onChange={(e) => setIndicator(e.target.value)}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className='col-lg-12'>
                Ação de de manutenção
                <div className='input-group m-b'>
                  <span className='input-group-addon'>
                    <i className='fa fa-download'></i>
                  </span>
                  <input
                    type='number'
                    className='form-control'
                    value={pcpService}
                    onChange={(e) => setPcpService(e.target.value)}
                  />
                  {/* <Autocomplete
                      className="form-control"
                      getItemValue={(item) => item.label}
                      items={[
                        { label: 'apple' },
                        { label: 'banana' },
                        { label: 'pear' }
                      ]}
                      renderItem={(item, isHighlighted) =>
                        <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                          {item.label}
                        </div>
                      }
                      value={state.pcp_service}
                      onChange={(e) => setState({ pcp_service: e.target.value })}
                      onSelect={(value) => setState({ pcp_service: value })}
                    /> */}
                </div>
              </div>
              <div className='col-lg-6'>
                Descrição
                <div className='input-group m-b'>
                  <span className='input-group-addon'>
                    <i className='fa fa-download'></i>
                  </span>
                  <textarea
                    className='form-control'
                    rows='3'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className='col-lg-6'>
                Observação
                <div className='input-group m-b'>
                  <span className='input-group-addon'>
                    <i className='fa fa-download'></i>
                  </span>
                  <textarea
                    className='form-control'
                    rows='3'
                    value={observation}
                    onChange={(e) => setObservation(e.target.value)}
                  />
                </div>
              </div>
            </>
          </div>
          <div className='col-lg-12' style={{ textAlign: 'center' }}>
            <button
              className='btn btn-primary'
              data-dismiss='modal'
              style={{ marginTop: '20px', margin: 5 }}
              onClick={() => handleSave()}
            >
              Salvar
            </button>
            <button
              className='btn btn-success'
              data-dismiss='modal'
              style={{ marginTop: '20px', margin: 5 }}
              onClick={() => props.handleClose()}
            >
              Voltar
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
