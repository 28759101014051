import React, { useEffect, useState } from 'react';
import { Link, browserHistory } from 'react-router';
import AircraftService from '../../services/AircraftsService';
import AircraftModelsService from '../../services/AircraftModelsService';
import BaseService from '../../services/BasesService';
import Moment from 'moment';
import LoadingModal from '../LoadingModal';
import RoundedPagination from '../../components/common/RoundedPagination';
import FlightsReportService from '../../services/FlightsReportService';

export default function FlightReport(props) {
  const [state, setState] = useState({
    pages: 1,
    page: 1,
    base: null,
    bases: null,
    aircraft: {},
    filter_aircrafts: [],
    filter_bases: [],
    startDate: null,
    endDate: null,
    reports: [],
    loading: true,
  });
  const [selectedAircraft, setSelectedAircraft] = useState('');
  const [selectedAircraftModel, setSelectedAircraftModel] = useState('');
  const [selectedBase, setSelectedBase] = useState('');
  const [searchStart, setSearchStart] = useState('');
  const [searchEnd, setSearchEnd] = useState('');

  const verifyUserRole = () => {
    const role = JSON.parse(localStorage.getItem('@omni/fast_track/user')).role;
    if (role !== 'ADM' && role !== 'ADM - TI' && role !== 'ENGENHARIA')
      browserHistory.push('/dashboard');
  };

  const fetchData = async () => {
    window.scrollTo(0, 0);
    setState({ loading: true });
    const bases = await BaseService.getAll();
    const filter_aircrafts = await AircraftService.getAll();
    const filter_aircraft_models = await AircraftModelsService.getAll();
    const filter = {
      aircraft: selectedAircraft,
      aircraft_model: selectedAircraftModel,
    };

    setState((oldState) => ({
      ...oldState,
      filter_bases: bases ? bases : [],
      bases,
      filter_aircraft_models,
      searching: true,
      filter_aircrafts: filter_aircrafts ? filter_aircrafts : [],
      loading: false,
    }));
  };

  useEffect(() => {
    verifyUserRole();
    fetchData();
  }, []);

  useEffect(() => {
    if (state.page) fetchServices();
  }, [state.page]);

  const fetchServices = async () => {
    setState((oldState) => ({ ...oldState, loading: true }));
    const filter = {
      base: selectedBase,
      aircraft: selectedAircraft,
      aircraft_model: selectedAircraftModel,
      search_start: searchStart,
      search_end: searchEnd,
    };
    const obj = await FlightsReportService.filter(filter, state.page);
    const reports = obj ? obj.result : [];
    const pages = obj ? obj.pages : 0;
    const total = obj ? obj.total : 0;
    setState((oldState) => ({
      ...oldState,
      reports,
      pages,
      total,
      loading: false,
    }));
  };

  const handlePagination = (event, value) => {
    setState((oldState) => ({ ...oldState, page: value }));
  };

  const handleClear = async () => {
    setSelectedAircraft('');
    setSelectedAircraftModel('');
    setSearchStart('');
    setSearchEnd('');
    setSelectedBase('');
    setState((oldState) => ({ ...oldState, page: 1, searching: true }));
    fetchServices();
  };

  const renderBasesOptions = (base) => (
    <option key={base._id} value={base._id}>
      {`${base.name}`} ({`${base.code}`}){' '}
    </option>
  );

  const renderPrettyAircraftModelName = (aircraftModelId) => {
    const aircraft = state.filter_aircrafts.find(
      (aircraft) => aircraft._id === aircraftModelId
    );

    if (aircraft)
      return (
        <>
          {`${aircraft._model.manufacturer}`} - {`${aircraft._model.model}`}
        </>
      );

    return '—';
  };

  const renderPrettyAircraftName = (aircraftId) => {
    const aircraft = state.filter_aircrafts.find(
      (aircraft) => aircraft._id === aircraftId
    );

    if (aircraft) return <>{`${aircraft.prefix}`}</>;

    return '—';
  };

  const renderPrettyBaseName = (baseId) => {
    const base = state.bases.find((base) => base._id === baseId);

    if (base) return base.name;

    return '—';
  };

  const formatTime = (time) => {
    const newTime = time.split(':');
    return `${newTime[0]}h:${newTime[1]}m`;
  };

  const handleSearch = async () => {
    setState((oldState) => ({ ...oldState, page: 1 }));
    fetchServices();
  };

  return (
    <div>
      <LoadingModal className='col-lg-12' visible={!state.bases} />
      {state.bases && (
        <>
          <div
            className='wrapper wrapper-content animated fadeInDown'
            style={{ paddingBottom: '0' }}
          >
            <div className='row wrapper border-bottom white-bg page-heading'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <h2>Relatório de voos</h2>
                    <ol className='breadcrumb'>
                      <li>Engenharia</li>
                      <li className='active'>
                        <strong>Relatório de voos</strong>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className='col-lg-12' style={{ paddingTop: 20 }}>
                <div className='row'>
                  <div className='col-lg-6'>
                    Base
                    <div className='input-group m-b'>
                      <span className='input-group-addon'>
                        <i className='fa fa-home'></i>
                      </span>
                      <select
                        className='form-control'
                        value={selectedBase}
                        onChange={(e) => setSelectedBase(e.target.value)}
                      >
                        <option value=''>Todas as bases</option>
                        {state.filter_bases.map(renderBasesOptions)}
                      </select>
                    </div>
                  </div>

                  <div className='col-lg-6'>
                    Modelo de Aeronave
                    <div className='input-group m-b'>
                      <span className='input-group-addon'>
                        <i className='fa fa-plane'></i>
                      </span>
                      <select
                        className='form-control'
                        value={selectedAircraftModel}
                        onChange={(e) =>
                          setSelectedAircraftModel(e.target.value)
                        }
                      >
                        <option value=''>Todos os modelos de aeronave</option>
                        {state.filter_aircraft_models &&
                          state.filter_aircraft_models.map((aircraft_model) => (
                            <option
                              key={aircraft_model._id}
                              value={aircraft_model._id}
                            >{`${aircraft_model.manufacturer} - ${aircraft_model.model}`}</option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className='col-lg-12'>
                    Aeronave
                    <div className='input-group m-b'>
                      <span className='input-group-addon'>
                        <i className='fa fa-plane'></i>
                      </span>
                      <select
                        className='form-control'
                        value={selectedAircraft}
                        onChange={(e) => setSelectedAircraft(e.target.value)}
                      >
                        <option value=''>Todas as aeronave</option>
                        {state.filter_aircrafts &&
                          state.filter_aircrafts.map((aircraft) => (
                            <option value={aircraft._id} key={aircraft._id}>
                              {`${aircraft.prefix}`} (
                              {`${aircraft._model.manufacturer}`} -{' '}
                              {`${aircraft._model.model}`})
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className='col-lg-6'>
                    Início do período
                    <div className='input-group m-b'>
                      <span className='input-group-addon'>
                        <i className='fa fa-calendar'></i>
                      </span>
                      <input
                        type='date'
                        className='form-control'
                        value={searchStart}
                        onChange={(e) => setSearchStart(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    Fim do período
                    <div className='input-group m-b'>
                      <span className='input-group-addon'>
                        <i className='fa fa-calendar'></i>
                      </span>
                      <input
                        type='date'
                        className='form-control'
                        value={searchEnd}
                        onChange={(e) => setSearchEnd(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='col-lg-12'>
                    <div
                      className='col-md-6'
                      style={{
                        paddingTop: 18,
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                    />
                    <div
                      className='col-md-3'
                      style={{
                        paddingTop: 18,
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                    >
                      <button
                        onClick={handleClear}
                        className='btn btn-danger btn-block'
                      >
                        Limpar Filtro
                      </button>
                    </div>
                    <div
                      className='col-md-3'
                      style={{
                        paddingTop: 18,
                        paddingLeft: '8px',
                        paddingRight: 0,
                      }}
                    >
                      <button
                        onClick={handleSearch}
                        className='btn btn-success btn-block'
                      >
                        Buscar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='wrapper wrapper-content'>
            <div className='row animated fadeInDown'>
              <div
                className='col-lg-12 table-responsive ibox-content'
                style={{
                  paddingLeft: '40px',
                  border: 'rgba(0,0,0,0.05) solid 1px',
                  paddingRight: '40px',
                  paddingBottom: '20px',
                  paddingTop: '20px',
                  overflowX: 'auto',
                }}
              >
                {state.loading ? (
                  <div className='col-lg-12' style={{ height: '100px' }}>
                    <div className='row'>
                      <LoadingModal visible={state.loading} />
                    </div>
                  </div>
                ) : (
                  <>
                    <table className='table table-striped'>
                      <thead style={{ postion: 'absolute' }}>
                        <tr>
                          <th>Modelo de aeronave</th>
                          <th>Aeronave</th>
                          <th>Base</th>
                          <th>Data do registro</th>
                          <th>Cliente</th>
                          <th>Local de partida (horário)</th>
                          <th>Local de chegada (horário)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!state.reports || state.reports.length == 0 ? (
                          <tr>
                            <td colSpan='7' style={{ textAlign: 'center' }}>
                              Nenhum relatório encontrado
                            </td>
                          </tr>
                        ) : (
                          state.reports.map((el, index) => (
                            <tr
                              style={{ cursor: 'pointer' }}
                              key={el._id}
                              onClick={() =>
                                browserHistory.push(
                                  `/flights_report/${el.flight_number}`
                                )
                              }
                            >
                              <td>
                                {renderPrettyAircraftModelName(el.aircraft)}
                              </td>
                              <td>{renderPrettyAircraftName(el.aircraft)}</td>
                              <td>{renderPrettyBaseName(el.base)}</td>
                              <td>
                                {el.date
                                  ? Moment(el.date).utc().format('DD/MM/YYYY')
                                  : '—'}
                              </td>
                              <td>{el.client || '—'}</td>
                              <td>
                                {el.departure_time ? (
                                  <>
                                    {el.departure_location} (
                                    {formatTime(el.departure_time)})
                                  </>
                                ) : (
                                  el.departure_location
                                )}
                              </td>
                              <td>
                                {el.arrival_time ? (
                                  <>
                                    {el.arrival_location} (
                                    {formatTime(el.arrival_time)})
                                  </>
                                ) : (
                                  el.arrival_location
                                )}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                    <RoundedPagination
                      page={state.page}
                      count={state.pages}
                      onChange={handlePagination}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
