import React, { Component, Fragment } from "react";
import { Link } from "react-router";

import ManagementService from "../../../services/ManagementService";
import BaseService from "../../../services/BasesService";
import UsersService from "../../../services/UsersService";
import FileService from "../../../services/FileService";

import Modal from "../MosaByAuditorModal";
import LoadingModal from "../../LoadingModal";
import RoundedPagination from "../../../components/common/RoundedPagination";

import { TextField, Select, MenuItem } from "@material-ui/core";
import Swal from "sweetalert2";
import moment from "moment";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { BsFillXCircleFill } from "react-icons/bs";
import IconButton from "@material-ui/core/IconButton";

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mechanics: [],
      filter: "",
      filter_bases: [],
      users: [],
      base: "",
      name: "",
      period: 1,
      period_type: "year",
      selectedMechanic: null,
      value: 0,
      auditors: [],
      auditor: null,
      services: [],
      next_services: [],
      pages: 0,
      page: 1,
      start_service: "",
      end_service: "",
      start_mosa: "",
      openModal: false,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    const bases = await BaseService.getAll();
    const auditors = await UsersService.get(1, {
      text: "",
      base: "",
      role: "AUDITOR MOSA",
    });

    this.setState({
      filter_bases: bases ? bases : [],
      searching: true,
      auditors: auditors.result,
    });
    this.getMechanics();
  }

  componentDidUpdate(prevProps, { page }) {
    if (page !== this.state.page) this.getMechanics();
  }

  handleClear = async () => {
    this.setState({
      loading: true,
      base: "",
      name: "",
      period: 1,
      period_type: "year",
    });

    const obj = await ManagementService.mechanical_filter(
      {
        base: "",
        name: "",
        period: "",
        period_type: "",
      },
      this.page
    );
    const users = obj ? obj.docs : [];
    const pages = obj ? obj.pages : 0;
    this.setState({ users, pages, page: this.page, loading: false });
  };

  renderBasesOptions = (base) => (
    <option value={base._id}>
      {`${base.name}`} ({`${base.code}`}){" "}
    </option>
  );

  getMechanics = async (filter) => {
    this.setState({ loading: true });
    const { base, name, period, period_type } = this.state;
    if (this.state.filter_bases.length < 1) this.setState({ loading: true });
    this.page = filter ? 1 : this.state.page;
    const obj = await ManagementService.mechanical_filter(
      {
        base,
        name,
        period,
        period_type,
      },
      this.page
    );
    const users = obj ? obj.docs : [];
    const pages = obj ? obj.pages : 0;
    this.setState({ users, pages, page: this.page, loading: false });
  };

  handlePagination = (event, value) => {
    this.setState({ page: value });
  };

  renderUsers = (user) => {
    return (
      <tr id="identify" key={user._id}>
        <td style={{ minHeight: 60 }}>
          <a>{user.name}</a>
        </td>
        <td>
          <a>{user.register}</a>
        </td>
        <td>
          <a>
            {`${
              user.last_mosa
                ? user.last_mosa.base
                  ? user.last_mosa.base.name
                  : ""
                : ""
            }${
              user.last_mosa
                ? user.last_mosa.base
                  ? " - " + user.last_mosa.base.code
                  : ""
                : ""
            }`}
          </a>
        </td>
        {user.last_mosa ? (
          <Fragment>
            <td>
              <a>{user.last_mosa.aircraft.prefix}</a>
            </td>
            <td>
              <Link to={`/management/${user.last_mosa._id}`}>
                <a>{user.last_mosa.register ? user.last_mosa.register : ""}</a>
              </Link>
            </td>
            <td>
              <a>
                {user.last_mosa.mosa_start
                  ? moment(user.last_mosa.mosa_start).format(
                      "DD/MM/Y [às] HH:mm"
                    )
                  : "Data/hora não definida"}
              </a>
            </td>
          </Fragment>
        ) : (
          <td colSpan={3} align="center">
            Nenhuma auditoria MOSA encontrada nesse período.
          </td>
        )}
        <td className="icon-cell">
          <IconButton
            title="Clique para mais informações"
            onClick={() => {
              this.setState({ openModal: true, selectedMechanic: user._id });
            }}
            style={{ cursor: "pointer" }}
          >
            <IoMdInformationCircleOutline />
          </IconButton>
        </td>
      </tr>
    );
  };

  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  handleGenerate = async () => {
    const confirmation = (
      await Swal.fire({
        title: "Confirmação",
        text: "Você tem certeza que deseja gerar este relatório de pendências?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      })
    ).value;
    this.setState({ loading: true });
    if (confirmation) {
      const reportResponse = await FileService.fetchMechanicalPendingReport(
        this.state
      );
      if (reportResponse !== undefined && reportResponse.status !== 200) {
        Swal.fire({
          type: "warning",
          title: "Erro ao gerar relatório",
          text: "Error: " + reportResponse.status,
        });
      }
    }
    this.setState({ loading: false });
  };

  render() {
    const styles = {
      dateContainer: {
        display: "flex",
        flexDirection: "column",
      },
    };
    return (
      <>
        <Modal
          open={this.state.openModal}
          handleClose={this.handleCloseModal}
          mechanic={this.state.selectedMechanic}
        />
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-9 col-md-12">
                <h2>Relatório de Pendências</h2>
                <ol className="breadcrumb">
                  <li>
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="active">
                    <strong>Relatório de Pendências</strong>
                  </li>
                </ol>
              </div>
              <div className="col-lg-3" style={{ padding: 20 }}>
                <button
                  onClick={this.handleGenerate}
                  className="btn btn-success btn-block"
                >
                  Gerar Relatório
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-12" style={{ paddingTop: 20 }}>
            <div className="row">
              <div className="col-lg-6">
                Filtrar por colaborador:
                <TextField
                  type="text"
                  value={this.state.name}
                  inputProps={{ style: { fontSize: 16 } }}
                  onChange={(e) => {
                    this.setState({ name: e.target.value });
                  }}
                  style={{ width: "100%", fontSize: 16 }}
                />
              </div>
              <div className="col-lg-6">
                Selecione uma base
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.base}
                  style={{ width: "100%", fontSize: 16 }}
                  displayEmpty={true}
                  onChange={(e) =>
                    this.setState({
                      base: e.target.value,
                    })
                  }
                >
                  <MenuItem value={""} style={{ fontSize: 16 }}>
                    Todas as bases
                  </MenuItem>
                  {this.state.filter_bases
                    ? this.state.filter_bases.map((el) => (
                        <MenuItem
                          key={el._id}
                          value={el._id}
                          style={{ fontSize: 16 }}
                        >
                          {`${el.name} (${el.code})`}
                        </MenuItem>
                      ))
                    : ""}
                </Select>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6" style={{ marginTop: 5 }}>
                Busque colaboradores que não fizeram MOSA nos últimos
                <div
                  className="input-group m-b"
                  style={{ flexDirection: "row" }}
                >
                  <TextField
                    type="number"
                    value={this.state.period}
                    inputProps={{ style: { fontSize: 16 } }}
                    onChange={(e) => {
                      this.setState({ period: e.target.value });
                    }}
                    style={{ width: "45%", fontSize: 16, marginRight: 10 }}
                  />
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.period_type}
                    style={{ width: "45%", fontSize: 16 }}
                    displayEmpty={true}
                    onChange={(e) =>
                      this.setState({
                        period_type: e.target.value,
                      })
                    }
                  >
                    <MenuItem
                      key={"year"}
                      value={"year"}
                      style={{ fontSize: 16 }}
                    >
                      Anos
                    </MenuItem>
                    <MenuItem
                      key={"month"}
                      value={"month"}
                      style={{ fontSize: 16 }}
                    >
                      Meses
                    </MenuItem>
                    <MenuItem
                      key={"day"}
                      value={"day"}
                      style={{ fontSize: 16 }}
                    >
                      Dias
                    </MenuItem>
                  </Select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8"></div>
              <div className="col-md-2">
                <button
                  onClick={this.handleClear}
                  className="btn btn-danger btn-block"
                >
                  Limpar
                </button>
              </div>
              <div className="col-md-2">
                <button
                  onClick={() => this.getMechanics(true)}
                  className="btn btn-success btn-block"
                >
                  Filtrar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row animated fadeInRight" style={{ marginTop: "20px" }}>
          <LoadingModal className="col-lg-12" visible={this.state.loading} />
          <div
            hidden={this.state.loading}
            className="col-lg-12 table-responsive ibox-content"
            style={{
              paddingLeft: "40px",
              border: "rgba(0,0,0,0.05) solid 1px",
              paddingRight: "40px",
              paddingBottom: "20px",
              paddingTop: "20px",
            }}
          >
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Registro</th>
                  <th>Base</th>
                  <th>Última Aeronave</th>
                  <th>Último Registro</th>
                  <th>Última MOSA</th>
                  <th className="center-th">Informações</th>
                </tr>
              </thead>
              <tbody>{this.state.users.map(this.renderUsers)}</tbody>
            </table>

            <RoundedPagination
              count={this.state.pages}
              onChange={this.handlePagination}
            />
          </div>
        </div>
      </>
    );
  }
}
