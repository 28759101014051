import React, { useEffect, useState } from 'react';
import { browserHistory, Link } from 'react-router';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import EvaluationService from '../../services/EvaluationService';
import LoadingModal from '../LoadingModal';
import './styles.css';

export default function AddEvaluation(props) {
    const [loading, setLoading] = useState(true);
    const [mechanics, setMechanics] = useState([]);
    const [filterMechanics, setFilterMechanics] = useState([])
    const [selectedMechanic, setSelectedMechanic] = useState(undefined);
    const [topics, setTopics] = useState([]);
    const userId = JSON.parse(localStorage.getItem('@omni/fast_track/user'))._id;

    const verifyUserRole = () => {
        const role = JSON.parse(localStorage.getItem('@omni/fast_track/user')).role;
        if (role !== 'ADM' && role !== 'ADM - TI' && role !== 'AVALIADOR')
            browserHistory.push('/dashboard');
    };

    useEffect(() => {
        verifyUserRole();
        fetchData();
    }, []);

    const fetchData = async () => {
        window.scrollTo(0, 0);
        const [fetchedTopics, fetchedMechanics] = await Promise.all([EvaluationService.getTopics(), EvaluationService.getAllMechanics()]);
        const filter_mechanics = fetchedMechanics.map((mechanic) => ({
            label: `${mechanic.name} (${mechanic.registration})`,
            value: mechanic.registration
        }))

        const parsedTopics = fetchedTopics.map((topic) => {
            if (topic.avaliation) return;
            return {
                ...topic,
                total: topic.weight,
                avaliation: 1,
            }
        })

        setTopics(parsedTopics);
        setMechanics(fetchedMechanics)
        setFilterMechanics(filter_mechanics)
        setLoading(false)
    };

    const handleClick = async () => {
        const confirmation = (
            await Swal.fire({
                title: 'Confirmação',
                text: 'Você tem certeza que deseja criar esta avaliação?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
            })
        ).value;

        if (confirmation) {
            await consolidateData();
        }
    };

    const result = React.useMemo(() => topics ? topics.reduce((total, actual) => {
        return total + (actual && actual.total || 0)
    }, 0) : 0, [topics]);

    const consolidateData = React.useCallback(async () => {
        const mechanic = mechanics && selectedMechanic && mechanics.find((mechanic) => mechanic.registration === selectedMechanic.value)
        await EvaluationService.add({
            active: true,
            evaluator: userId,
            evaluated: mechanic,
            topics,
            result
        })
        toast.success('Avaliação criada com sucesso!');
        browserHistory.goBack();
    }, [topics, selectedMechanic, mechanics, userId]);

    const handleChangeSelect = React.useCallback((topicIndex, avaliation) => {
        setTopics((oldTopics) => oldTopics.map((item, index) => {
            if (index === topicIndex) {
                const topic = Object.assign({}, topics[topicIndex]);

                topic.avaliation = +avaliation;
                topic.total = +avaliation * topic.weight;
                return topic
            }
            return item
        }))
    }, [topics, setTopics])

    return (
        <>
            {loading ? (
                <div className="col-lg-12">
                    <div className="row">
                        <LoadingModal visible={loading} />
                    </div>
                </div>
            ) : (
                <div>
                    <div className="wrapper wrapper-content animated fadeInDown">
                        <div className="row wrapper border-bottom white-bg page-heading">
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <h2>Realizar nova avaliação</h2>
                                        <ol className="breadcrumb">
                                            <li>
                                                <Link to="/mechanics-evaluation">Registro de avaliações</Link>
                                            </li>
                                            <li className="active">
                                                <strong>Criar</strong>
                                            </li>
                                        </ol>
                                    </div>
                                    <div className="col-lg-4"></div>
                                </div>
                            </div>
                            <h4
                                className="col-lg-12"
                                style={{
                                    borderBottom: '1.5px solid rgba(0,0,0,0.07)',
                                    textAlign: 'center',
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                    padding: '10px',
                                    color: 'red',
                                    fontSize: '18px',
                                }}
                            >
                                DADOS DO REGISTRO
                            </h4>
                            <div className="col-lg-12" style={{ paddingTop: 10 }}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h4>
                                            <b>Avaliado</b>
                                        </h4>
                                        <div className="input-group m-b">
                                            <span className="input-group-addon">
                                                <i className="fa fa-user"></i>
                                            </span>
                                            <Select
                                                options={filterMechanics}
                                                name='mecanicos'
                                                defaultValue={''}
                                                placeholder="Selecione um mecânico"
                                                value={selectedMechanic}
                                                onChange={(value) => setSelectedMechanic(value)}
                                                noOptionsMessage={() => "Nenhum mecânico encontrado"}
                                                className='mec-select'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h4
                                className="col-lg-12"
                                style={{
                                    borderBottom: '1.5px solid rgba(0,0,0,0.07)',
                                    textAlign: 'center',
                                    marginTop: '20px',
                                    marginBottom: '0',
                                    padding: '10px',
                                    color: 'red',
                                    fontSize: '18px',
                                }}
                            >
                                TÓPICOS
                            </h4>
                            <div>
                                {!topics || topics.length === 0 ?
                                    (
                                        <span style={{ width: '100%', textAlign: 'center' }}>
                                            Nenhum tópico encontrado
                                        </span>
                                    ) : (
                                        <div>
                                            {topics.map((topic, index) => (
                                                <div key={index} className={`col-lg-12 topic-wrapper ${index % 2 === 0 ? 'stripped-topic-wrapper' : null}`}>
                                                    <span style={{ flex: 1 }}>{topic.title}</span>
                                                    <div class='form-group'>
                                                        <select
                                                            class="form-control form-control-sm"
                                                            value={topics[index] ? `${topics[index].avaliation}` : 1}
                                                            onChange={(event) => handleChangeSelect(index, event.target.value)}
                                                        >
                                                            {Array.from(Array(topic.maximumCriteria)).map((val, criteriaIndex) => (
                                                                <option value={criteriaIndex + 1}>{criteriaIndex + 1}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )
                                }
                            </div>
                            <div className='col-lg-12' style={{ textAlign: 'center', marginTop: '8px' }}>
                                <button
                                    className='btn btn-success'
                                    onClick={handleClick}
                                >
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </>
    );
}
