import React, { Component } from "react";
import { Link, browserHistory } from "react-router";
import UsersService from "../services/UsersService";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export default class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      newPassword: "",
      passwordConfirmation: "",
    };
  }

  save = async () => {
    const confirmation = (
      await Swal.fire({
        title: "Confirmação",
        text: "Você tem certeza que deseja alterar sua senha?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      })
    ).value;
    if (confirmation) {
      const result = await UsersService.changePassword({
        id: this.props.params.id,
        newPassword: this.state.newPassword,
        password: this.state.password,
      });

      if (result) {
        browserHistory.push("/dashboard");
        toast.success("Senha atualizada com sucesso!");
      }
    }
  };

  render() {
    return (
      <div className="wrapper wrapper-content">
        <div
          className="row wrapper border-bottom white-bg page-heading animated bounceInRight"
          style={{ margin: "auto", padding: "30px" }}
        >
          <h2>Alterar senha</h2>
          <br />
          <div className="row">
            <div className="col-lg-4">
              <h4>
                <b>Senha Atual</b>
              </h4>
              <div className="input-group m-b">
                <span className="input-group-addon">
                  <i className="fa fa-lock"></i>
                </span>
                <input
                  type="password"
                  placeholder="Senha Atual"
                  className="form-control"
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <h4>
                <b>Nova Senha</b>
              </h4>
              <div className="input-group m-b">
                <span className="input-group-addon">
                  <i className="fa fa-lock"></i>
                </span>
                <input
                  type="password"
                  placeholder="Nova Senha"
                  className="form-control"
                  value={this.state.newPassword}
                  onChange={(e) =>
                    this.setState({ newPassword: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-lg-4">
              <h4>
                <b>Confirmação de Senha</b>
              </h4>
              <div className="input-group m-b">
                <span className="input-group-addon">
                  <i className="fa fa-lock"></i>
                </span>
                <input
                  type="password"
                  placeholder="Confirmação de Senha"
                  className="form-control"
                  value={this.state.passwordConfirmation}
                  onChange={(e) =>
                    this.setState({ passwordConfirmation: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <br />
          <div className="col-lg-12" style={{ textAlign: "center" }}>
            <button
              className="btn btn-success"
              onClick={this.save}
              style={{ margin: "2px" }}
            >
              Salvar
            </button>
            {/* <Link to="/users"> */}
            <button
              className="btn btn-danger"
              style={{ margin: "2px" }}
              onClick={async () => {
                const confirmation = (
                  await Swal.fire({
                    title: "Confirmação",
                    text: "Você tem certeza que deseja cancelar?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sim",
                    cancelButtonText: "Não",
                  })
                ).value;
                if (confirmation) {
                  browserHistory.push(`/users`);
                }
              }}
            >
              Cancelar
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
    );
  }
}
