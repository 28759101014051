import React, { useState, useEffect } from 'react';
import Progress from '../common/Progress';
import ComponentSpinner from '../common/ComponentSpinner';
import Navigation from '../common/Navigation';
import Footer from '../common/Footer';
import TopHeader from '../common/TopHeader';
import { correctHeight, detectBody } from './Helpers';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as $ from 'jquery';

const Main = (props) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('@omni/fast_track/user'));
    if (
      (user && user.role === 'AUDITOR MOSA' &&
        props.location &&
        !props.location.pathname.includes('/management') &&
        !props.location.pathname.includes('/dashboard') &&
        !(
          props.location.pathname.includes('/user') &&
          props.params.id === user._id
        ))
    ) {
      toast.warn('Você não tem acesso a esta função.');
      props.router.replace('/dashboard');
    }
  }, [props.location]);

  useEffect(() => {
    setTimeout(function () {
      setLoading(false);
    }, 2000);

    setTimeout(() => {
      // Run correctHeight function on load and resize window event
      $(window).bind('load resize', function () {
        correctHeight();
        detectBody();
      });

      // Correct height of wrapper after metisMenu animation.
      $('.metismenu a').click(() => {
        setTimeout(() => {
          correctHeight();
        }, 300);
      });
    }, 1000);
  }, []);

  let wrapperClass = 'gray-bg ' + props.location.pathname;
  return (
    <div id="content">
      {loading && <ComponentSpinner />}
      {!loading && (
        <div>
          {/* <Progress /> */}
          <Navigation location={props.location} />
          <div id="page-wrapper" className={wrapperClass}>
            <TopHeader />
            {props.children}
            <Footer />
            <ToastContainer autoClose={2000} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Main;
