import React, { Component } from "react";
import ReactSwitch from "react-switch";
import BasesService from "../services/BasesService";
import { toast } from "react-toastify";
import * as $ from "jquery";
import LoadingModal from "./LoadingModal";
import Swal from "sweetalert2";

export default class EditBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
      name: "",
      code: "",
    };
  }

  async componentWillReceiveProps(nextProps) {
    this.setState({ loading: true });
    if (nextProps.selected) {
      const base = await BasesService.getById(nextProps.selected);
      if (base) this.setState({ ...base });
      else {
        $("#editModal").removeClass("show").addClass("hide");
        $(".modal-backdrop").removeClass("show").addClass("hide");
      }
    }
    this.setState({ loading: false });
  }

  save = async () => {
    const confirmation = (
      await Swal.fire({
        title: "Confirmação",
        text: "Você tem certeza que deseja salvar as alterações a esta base?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      })
    ).value;
    if (confirmation) {
      const result = await BasesService.edit(this.state._id, {
        active: this.state.active,
        name: this.state.name,
        code: this.state.code,
      });

      if (result) {
        toast.success("Base alterada com sucesso!");
        this.props.refresh();
        $("#editModal").removeClass("show").addClass("hide");
        $(".modal-backdrop").removeClass("show").addClass("hide");
      }
    }
  };

  render() {
    return (
      <div className="modal inmodal" id="editModal">
        <LoadingModal visible={this.state.loading} />
        <div className="modal-dialog animated bounceInRight">
          <div
            className="modal-content row wrapper border-bottom white-bg page-heading"
            style={{ margin: "auto", padding: "30px" }}
          >
            <div className="modal-header">
              <h2>Editar Base</h2>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <h4>
                  <b>Ativo?</b>
                </h4>
                <ReactSwitch
                  checked={this.state.active}
                  onChange={(active) => this.setState({ active })}
                  onColor="#57b87b"
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h4>
                  <b>Nome*</b>
                </h4>
                <div className="input-group m-b">
                  <span className="input-group-addon">
                    <i className="fa fa-road"></i>
                  </span>
                  <input
                    type="text"
                    placeholder="Nome"
                    className="form-control"
                    value={this.state.name}
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h4>
                  <b>Código*</b>
                </h4>
                <div className="input-group m-b">
                  <span className="input-group-addon">
                    <i className="fa fa-key"></i>
                  </span>
                  <input
                    type="text"
                    placeholder="Código"
                    className="form-control"
                    value={this.state.code}
                    onChange={(e) => this.setState({ code: e.target.value })}
                  />
                </div>
              </div>
            </div>
            <br />
            <div className="col-lg-12" style={{ textAlign: "center" }}>
              <button
                data-target="#editModal"
                className="btn btn-success"
                onClick={this.save}
                style={{ margin: "2px" }}
              >
                Salvar
              </button>
              <button
                className="btn btn-danger"
                data-dismiss="modal"
                style={{ margin: "2px" }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
