import { apiURL } from "../config/consts";
import { toast } from "react-toastify";
import logout from "../utils/Auth";
import fetch from "./fetchWrapper";
export default class DashboardService {
  static getAll = async () => {
    try {
      const url = `${apiURL}/dashboard`;
      const response = await fetch(url, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });
      if (response.status === 401) {
        logout();
        return;
      }
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e);
      return false;
    }
  };

  static filter = async (filter) => {
    try {
      const response = await fetch(`${apiURL}/dashboard/filter`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
        body: JSON.stringify(filter),
      });
      if (response.status === 401) {
        logout();
        return;
      }
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e);
      return false;
    }
  };
}
