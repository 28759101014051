import { apiURL } from "../config/consts";
import { toast } from "react-toastify";

export default class UsersService {
  static get = async (page, filter = false) => {
    try {
      const url = filter
        ? `${apiURL}/fast_tracking-filter?name=${filter.text}&base=${filter.base}&role=${filter.role}`
        : `${apiURL}/fast_trackings-listall/${page}`;
      const response = await fetch(url, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static search = async (page, filter = false) => {
    try {
      const url = `${apiURL}/fast_trackings-listall/${page}?name=${filter.text}&base=${filter.base}&role=${filter.role}`
      const response = await fetch(url, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static getAll = async () => {
    try {
      const url = `${apiURL}/fast_trackings-listall`;
      const response = await fetch(url, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static getMechanics = async (base) => {
    try {
      const url = `${apiURL}/mechanics/base/${base}`;
      const response = await fetch(url, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static getAllMechanics = async () => {
    try {
      const url = `${apiURL}/mechanics-listall`;
      const response = await fetch(url, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static getById = async (id) => {
    try {
      const response = await fetch(`${apiURL}/fast_tracking/${id}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static logout = () => {
    localStorage.removeItem("@omni/fast_track/user");
    window.location.reload();
  };

  static login = async (data, success, error) => {
    try {
      const response = await fetch(`${apiURL}/login/`, {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify(data),
      });
      if (response.status === 401)
        throw new Error("Usuário ou senha inválidos!");
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      success(await response.json());
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static redefine = async (data, success, error) => {
    try {
      const response = await fetch(`${apiURL}/forgot-password/`, {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify(data),
      });
      if (response.status === 401) throw new Error("Usuário não encontrado!");
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      success(await response.json());
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static reset = async (data, success, error) => {
    try {
      const response = await fetch(`${apiURL}/reset-password/`, {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify(data),
      });
      if (response.status !== 200) {
        throw new Error('Houve um problema ao redefinir sua senha. Por favor, tente novamente!');
      } else {
        success('Senha redefina com sucesso');
      }
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static authenticate = async () => {
    try {
      const response = await fetch(`${apiURL}/authenticate/`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });

      return {
        authenticated: response.status !== 401 ? true : false,
        message: (await response.json()).message,
      };
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static add = async (data) => {
    try {
      if (data.role !== 'AUDITOR MOSA')
        delete data.auditing_areas;

      const response = await fetch(`${apiURL}/fast_tracking`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
        body: JSON.stringify(data),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static edit = async (id, data) => {
    try {
      if (data.role !== 'AUDITOR MOSA')
        data.auditing_areas = [];

      const response = await fetch(`${apiURL}/fast_tracking/${id}`, {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
        body: JSON.stringify(data),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static changePassword = async (data) => {
    try {
      const response = await fetch(
        `${apiURL}/fast_tracking/change-password/` + data.id,
        {
          method: "PUT",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("@omni/fast_track/token")
            )}`,
          }),
          body: JSON.stringify(data),
        }
      );
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };
}
