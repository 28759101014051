import { apiURL } from "../config/consts";
import { toast } from "react-toastify";
import fetch from "./fetchWrapper";

export default class MosaService {
  static add = async (data) => {
    try {
      const response = await fetch(`${apiURL}/mosa`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
        body: JSON.stringify(data),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static get = async (page) => {
    try {
      const response = await fetch(`${apiURL}/mosas/${page}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static getById = async (id) => {
    try {
      const response = await fetch(`${apiURL}/mosa/${id}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static delete = async (archiveId) => {
    try {
      const response = await fetch(`${apiURL}/mosa/${archiveId}`, {
        method: "DELETE",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static edit = async (id, data) => {
    try {
      const response = await fetch(`${apiURL}/mosa`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
        body: JSON.stringify(data),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static get = async (page) => {
    try {
      const response = await fetch(`${apiURL}/mosas/${page}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static getById = async (id) => {
    try {
      const response = await fetch(`${apiURL}/mosa/${id}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static getByArchiveId = async (archiveId) => {
    try {
      const response = await fetch(`${apiURL}/mosas/archive/${archiveId}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static delete = async (archiveId) => {
    try {
      const response = await fetch(`${apiURL}/mosa/${archiveId}`, {
        method: "DELETE",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static edit = async (id, data) => {
    try {
      const response = await fetch(`${apiURL}/mosa/${id}`, {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
        body: JSON.stringify(data),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static filter = async (filter, page) => {
    try {
      const response = await fetch(`${apiURL}/mosa/filter/${page}`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
        body: JSON.stringify(filter),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static downloadPDF = async (id) => {
    try {
      const response = await fetch(`${apiURL}/mosa-pdf/${id}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        })
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error(response);
      console.log('response: ', response.body);
      return response;
    } catch (e) {
      console.log('erro:', e);
      toast.error(e.message);
      return false;
    }
  };
}
