import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { toast } from 'react-toastify';
import $ from 'jquery';
import axios from 'axios';
import { apiURL } from "../config/consts";
import LoadingModal from './LoadingModal';
import Swal from 'sweetalert2';

export default class AddPcpImport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            active: true,
            file: null,
            loaded: 0,
            disabledButton: false,
            loading: false
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        $('.inputfile').each(function () {
            var $input = $(this),
                $label = $input.next('label'),
                labelVal = $label.html();
            $input.on('change', function (e) {
                var fileName = '';
                if (this.files && this.files.length > 1)
                    fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
                else if (e.target.value)
                    fileName = e.target.value.split('\\').pop();

                if (fileName)
                    $label.find('span').html(fileName);
                else
                    $label.html(labelVal);
            });
            // Firefox bug fix
            $input
                .on('focus', function () { $input.addClass('has-focus'); })
                .on('blur', function () { $input.removeClass('has-focus'); });
        });
    }

    handleselectedFile = event => {
        this.setState({
            file: event.target.files[0],
            loaded: 0,
        })
    }

    handleUpload = async () => {
        const confirmation = (await Swal.fire({
            title: 'Confirmação',
            text: 'Você tem certeza que deseja fazer upload deste arquivo?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        })).value;
        if (confirmation) {
            //Ativar spinner
            let id = JSON.parse(localStorage.getItem('@omni/fast_track/user'))._id;
            let token = localStorage.getItem('@omni/fast_track/token');

            const data = new FormData()
            if (this.state.file.name)
                data.append('file', this.state.file, this.state.file.name)
            else
                data.append('file', this.state.file, 'programming.xlsx')

            axios
                .post(apiURL + '/pcp_archive/' + id, data, {
                    onUploadProgress: ProgressEvent => {
                        this.setState({
                            loaded: (ProgressEvent.loaded / ProgressEvent.total * 100),
                            disabledButton: true,
                            loading: true
                        })
                    },
                })
                .then(res => {
                    this.setState({
                        loading: false
                    })
                    browserHistory.push('/pcp-import/' + res.data.document._id)
                    toast.success('Upload concluído!')
                })
        }
    }

    render() {
        return (
            <div className="wrapper wrapper-content">
                <div
                    className="row wrapper border-bottom white-bg page-heading animated bounceInRight"
                    style={{ margin: 'auto', padding: '40px' }}
                >
                    <h2>Nova importação</h2><br />
                    <div className="row" style={{ textAlign: 'center' }}>
                        <div className="col-lg-12" style={{ marginTop: '10px' }}>
                            <div className="box">
                                <input type="file" name="file-5[]" onChange={this.handleselectedFile} id="file-5" className="inputfile inputfile-4" style={{ display: 'none' }}
                                    accept=".tsv, .csv" />
                                <label htmlFor="file-5">
                                    <figure>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                                            <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
                                        </svg>
                                    </figure>
                                    <span>
                                        Escolha o arquivo (.tsv)
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="row" style={{ textAlign: 'center' }} >
                        <div className="col-lg-12">
                            <button
                                className="btn btn-success"
                                onClick={this.handleUpload}
                                style={{ margin: '2px' }}
                            // disabled={this.state.disabledButton}
                            >Salvar</button>
                            {/* <Link to='pcp-import'> */}
                            <button
                                className="btn btn-danger"
                                style={{ margin: '2px' }}
                                onClick={async () => {
                                    const confirmation = (await Swal.fire({
                                        title: 'Confirmação',
                                        text: 'Você tem certeza que deseja cancelar?',
                                        type: 'warning',
                                        showCancelButton: true,
                                        confirmButtonColor: '#3085d6',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'Sim',
                                        cancelButtonText: 'Não'
                                    })).value;
                                    if (confirmation) {
                                        browserHistory.push(`/pcp-archives/`)
                                    }
                                }}
                            >Cancelar</button>
                            {/* </Link> */}
                        </div>
                    </div>
                    {this.state.loading &&
                        <div className="col-lg-12" style={{ height: '100px', marginTop: '56px' }}>
                            <div className="row">
                                <LoadingModal visible={this.state.loading} />
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}