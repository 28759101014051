import React, { useEffect, useState } from 'react';
import AircraftsService from '../../services/AircraftsService';
import { toast, ToastContainer } from 'react-toastify';
import { browserHistory } from 'react-router';

import './styles.css';
import FlightService from '../../services/FlightService';

const RecordHUMS = () => {
  const [aircrafts, setAircrafts] = useState([]);
  const [selectedAircraft, setSelectedAircraft] = useState();
  const [selectedDay, setSelectedDay] = useState();
  const [startFlightTime, setStartFlightTime] = useState();
  const [endFlightTime, setEndFlightTime] = useState();
  const [downloadHasDone, setDownloadHasDone] = useState(true);
  const [downloadReason, setDownloadReason] = useState();
  const [generatedDataWasValid, setGeneratedDataWasValid] = useState(true);
  const [generatedReason, setGeneratedReason] = useState();
  const [observation, setObservation] = useState();
  const [inputAircraft, setInputAircraft] = useState('');
  const [registration, setRegistration] = useState('');

  async function handleLoadAircrafts() {
    const fetchedAircrafts = await AircraftsService.getAll();
    setAircrafts(fetchedAircrafts);
  }

  function handleGoBack() {
    browserHistory.goBack();
  }

  function validateData() {
    if (
      !selectedAircraft ||
      !selectedDay ||
      !startFlightTime ||
      !endFlightTime ||
      !registration
    ) {
      throw new Error('Preencha os campos corretamente.');
    }
    if (!downloadHasDone && !downloadReason) {
      throw new Error('Justifique o motivo do download não ter sido realizado');
    }
    if (!generatedDataWasValid && !generatedReason) {
      throw new Error(
        'Justifique o motivo de não ter sido gerado dados válidos'
      );
    }
  }

  function handleClearForm() {
    setSelectedAircraft('');
    setSelectedDay('');
    setStartFlightTime('');
    setEndFlightTime('');
    setDownloadHasDone(true);
    setDownloadReason('');
    setGeneratedDataWasValid(true);
    setGeneratedReason('');
    setObservation('');
    setInputAircraft('');
    setRegistration('');
  }

  async function handleSubmit() {
    try {
      validateData();
      const confirm = await FlightService.recordHums({
        aircraft: selectedAircraft,
        flight_day: selectedDay,
        flight_start: startFlightTime,
        flight_end: endFlightTime,
        download_has_done: downloadHasDone,
        download_reason: downloadReason,
        generated_data_was_valid: generatedDataWasValid,
        generated_reason: generatedReason,
        observation: observation,
        registration,
      });
      if (confirm) handleClearForm();
    } catch (err) {
      toast.error(err.message);
    }
  }

  useEffect(() => {
    handleLoadAircrafts();
  }, []);

  useEffect(() => {
    if (!inputAircraft || !Array.isArray(aircrafts)) return;
    const aircraft = aircrafts.find(
      ({ prefix }) => prefix.toUpperCase() === inputAircraft.toUpperCase()
    );
    if (aircraft) setSelectedAircraft(aircraft._id);
  }, [inputAircraft]);

  return (
    <>
      <div className="main container-fluid hums-main">
        <div className="hums-container text-center animated fadeIn">
          <h3>Formulário HUMS - Registro de Dados</h3>
          <div className="input-wrapper">
            <label>Matrícula do colaborador</label>
            <input
              value={registration || ''}
              onChange={(e) => {
                setRegistration(e.target.value);
              }}
              className="form-control"
            />
          </div>
          <div className="input-wrapper">
            <label>Selecione uma aeronave</label>
            <input
              value={inputAircraft || ''}
              onInput={(e) => {
                setInputAircraft(e.target.value);
              }}
              className="form-control"
              list="aircrafts"
            />
            <datalist id="aircrafts">
              <option disabled value="">
                Selecione uma aeronave
              </option>
              {aircrafts &&
                aircrafts.map((aircraft) => (
                  <option key={aircraft._id} value={aircraft.prefix} />
                ))}
            </datalist>
          </div>
          <div className="input-wrapper">
            <label>Informe a data do voo</label>
            <input
              type="date"
              value={selectedDay}
              onChange={(e) => setSelectedDay(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="input-wrapper">
            <label>Informe o horário inicial do voo</label>
            <input
              type="time"
              value={startFlightTime}
              onChange={(e) => setStartFlightTime(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="input-wrapper">
            <label>Informe o horário final do voo</label>
            <input
              type="time"
              value={endFlightTime}
              onChange={(e) => setEndFlightTime(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="input-wrapper">
            <label>O download foi realizado?</label>
            <form>
              <div className="radio-input-wrapper">
                <input
                  id="download-has-done-yes"
                  type="radio"
                  value={true}
                  name="Sim"
                  checked={!!downloadHasDone}
                  onChange={() => setDownloadHasDone(true)}
                />
                <label htmlFor="download-has-done-yes">Sim</label>
              </div>
              <div className="radio-input-wrapper">
                <input
                  id="download-has-done-no"
                  type="radio"
                  value={false}
                  name="Não"
                  checked={!downloadHasDone}
                  onChange={() => setDownloadHasDone(false)}
                />
                <label htmlFor="download-has-done-no">Não</label>
              </div>
            </form>
            {!downloadHasDone && (
              <div className="input-wrapper animated fadeIn">
                <label>Justifique o motivo</label>
                <textarea
                  value={downloadReason}
                  onChange={(e) => setDownloadReason(e.target.value)}
                  className="form-control"
                  rows="3"
                  placeholder="Justifique aqui o motivo de não ter sido realizado o download"
                  style={{ resize: 'none' }}
                />
              </div>
            )}
          </div>
          <div className="input-wrapper">
            <label>Foram gerados dados válidos?</label>
            <form>
              <div className="radio-input-wrapper">
                <input
                  id="generated-data-was-valid-yes"
                  type="radio"
                  value={true}
                  name="Sim"
                  checked={!!generatedDataWasValid}
                  onChange={() => setGeneratedDataWasValid(true)}
                />
                <label htmlFor="generated-data-was-valid-yes">Sim</label>
              </div>
              <div className="radio-input-wrapper">
                <input
                  id="generated-data-was-valid-no"
                  type="radio"
                  value={false}
                  name="Não"
                  checked={!generatedDataWasValid}
                  onChange={() => setGeneratedDataWasValid(false)}
                />
                <label htmlFor="generated-data-was-valid-no">Não</label>
              </div>
            </form>
            {!generatedDataWasValid && (
              <div className="input-wrapper animated fadeIn">
                <label>Justifique o motivo</label>
                <textarea
                  value={generatedReason}
                  onChange={(e) => setGeneratedReason(e.target.value)}
                  className="form-control"
                  rows="3"
                  placeholder="Justifique aqui o motivo de não ter sido gerado dados válidos"
                  style={{ resize: 'none' }}
                />
              </div>
            )}
            <div className="input-wrapper">
              <label>Observações gerais</label>
              <textarea
                value={observation}
                onChange={(e) => setObservation(e.target.value)}
                className="form-control"
                rows="3"
                style={{ resize: 'none' }}
              />
            </div>
          </div>
          <button className="btn btn-light" onClick={handleSubmit}>
            Enviar
          </button>
          <div className="go-back-wrapper">
            <button className="btn btn-outline-light" onClick={handleGoBack}>
              Voltar
            </button>
          </div>
        </div>
      </div>

      <ToastContainer autoClose={3000} />
    </>
  );
};

export default RecordHUMS;
