import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import ManagementService from '../services/ManagementService';
import HangarService from '../services/HangarsService';
import AircraftService from '../services/AircraftsService';
import BaseService from '../services/BasesService';
import Moment from 'moment';
import LoadingModal from './LoadingModal';
import HangarsService from '../services/HangarsService';
import Axios from 'axios';
import { apiURL } from '../config/consts';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';
import FileService from '../services/FileService';

export default class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
      filter: '',
      pages: 0,
      page: 1,
      filter_base: [],
      filter_hangars: [],
      filter_aircrafts: [],
      filter_bases: [],
      filter_complexities: [],
      filter_inspections: [],
      hangar: null,
      base: null,
      loading: true,
    };
    if(JSON.parse(localStorage.getItem('@omni/fast_track/user')).role === 'PCP')
      browserHistory.push('/pcp-archives');
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    let start_service = Moment();
    start_service.set({ hour: 0, minute: 0 });
    start_service = this.validationDate(start_service.format());
    let end_service = Moment().add(15, 'days');
    end_service.set({ hour: 23, minute: 59 });
    end_service = this.validationDate(end_service.format());

    const bases = await BaseService.getAll();

    const filter_aircrafts = await AircraftService.getAll();

    const filter_hangars = await HangarsService.getAll();

    await this.setState({
      filter_bases: bases?bases:[],
      filter_hangars: filter_hangars?filter_hangars:[],
      filter_aircrafts: filter_aircrafts?filter_aircrafts:[],
      start_service,
      end_service,
      searching: true,
      loading: false,
    });
  }

  changeBase = async (base) => {
    if (base) {
      let hangars = await HangarService.getByBase(base);
      await this.setState({ filter_hangars: hangars });
    } else {
      let hangars = await HangarService.getAll();
      await this.setState({ filter_hangars: hangars });
    }
  }

  getServices = async (props) => {
    this.setState({ loading: true });
    this.page = this.state.page;
    const obj = await ManagementService.filter({
      hangar: this.state.hangar,
      aircraft: this.state.aircraft,
      start_service: this.state.start_service,
      end_service: this.state.end_service,
      complexity: this.state.complexity,
      programming: this.state.programming
    }, this.page);
    const services = obj?obj.result:[];
    const pages = obj?obj.pages:0;
    await this.setState({ services, pages });
    this.setState({ loading: false });
  }

  handleClear = async () => {
    const start_service = this.validationDate(Moment().format());
    let end_service = Moment().add(15, 'days');
    end_service.set({ hour: 23, minute: 59 });
    end_service = this.validationDate(end_service.format());
    await this.setState({
      base: null,
      hangar: null,
      filter_base: null,
      filter_hangar: null,
      start_service: start_service,
      end_service: end_service
    });

    this.changeBase();
  }

  validationDate = (date) => {
    return !date ? null :
      (`${new Date(date).getFullYear()}-${`${new Date(date).getMonth() + 1}`.padStart(2, 0)}-${`${new Date(date).getDate()}`.padStart(2, 0)}T${`${new Date(date).getHours()}`.padStart(2, 0)}:${`${new Date(date).getMinutes()}`.padStart(2, 0)}`)
  }

  handleGenerate = async () => {
    this.setState({ loading: true });
    const confirmation = (await Swal.fire({
      title: 'Confirmação',
      text: 'Você tem certeza que deseja gerar este relatório?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    })).value;
    if (confirmation) {
      await FileService.fetchReport(this.state);
      setTimeout(() => {
        this.setState({ loading: false });
      }, 4000);
    }
  }

  renderBasesOptions = base => (
    <option value={base._id}>{`${base.name}`} ({`${base.code}`}) </option>
  )

  renderHangarsOptions = hangar => (
    <option value={hangar._id}>{`${hangar.code} (${hangar.base.name})`}</option>
  )

  renderAircraftsOptions = aircraft => (
    <option value={aircraft._id}>{`${aircraft.prefix}  (${aircraft._model.manufacturer} - ${aircraft._model.model})`}</option>
  )

  render() {
    return (
      <div>
        <div className="wrapper wrapper-content animated fadeInDown">
          <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12">
                  <h2>Relatório da Programação na Base</h2>
                  <ol className="breadcrumb">
                    <li>
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="active">
                      <strong>Relatório</strong>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="col-lg-12" style={{ paddingTop: 20 }}>
              <div className="row">
                <div className="col-lg-6">
                  Base
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-home"></i>
                    </span>
                    <select
                      className="form-control"
                      value={this.state.base}
                      onChange={e => {
                        this.setState({ base: e.target.value })
                        this.changeBase(e.target.value)
                      }}
                    >
                      <option value=''>Todas as bases</option>
                      {this.state.filter_bases.map(this.renderBasesOptions)}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  Hangar
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-warehouse"></i>
                    </span>
                    <select
                      className="form-control"
                      value={this.state.hangar}
                      onChange={e => this.setState({ hangar: e.target.value })}
                    >
                      <option value=''>Todos os hangares</option>
                      {this.state.filter_hangars.map(this.renderHangarsOptions)}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  Início do Serviço
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-calendar-alt"></i>
                    </span>
                    <input
                      type="datetime-local"
                      className="form-control"
                      value={this.state.start_service}
                      onChange={e => this.setState({ start_service: this.validationDate(e.target.value) })}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  Fim do Serviço
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-calendar-alt"></i>
                    </span>
                    <input
                      type="datetime-local"
                      className="form-control"
                      value={this.state.end_service}
                      onChange={e => this.setState({ end_service: this.validationDate(e.target.value) })}
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-12'>
                <div className="col-md-8">
                </div>
                <div className='col-md-2'>
                  <button onClick={this.handleClear} className="btn btn-danger btn-block">
                    Limpar
                  </button>
                </div>
                <div className='col-md-2'>
                  <button disabled={this.state.loading} onClick={this.handleGenerate} className="btn btn-success btn-block">
                    Gerar Relatório
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoadingModal visible={this.state.loading} />
      </div >
    )
  }
}
