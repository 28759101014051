import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import UsersService from '../services/UsersService';
import ReactSwitch from 'react-switch';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import MultiSelect from "react-multi-select-component";
import { auditor_cmpt_arr } from "../utils/select-options";


const styles = {
  helperText: {
    fontSize: 10,
  },
};

export default class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
      name: '',
      login: '',
      email: '',
      password: '',
      group: '',
      sigmec_role: '',
      sigmec_post: '',
      role: '',
      registration: '',
      auditing_areas: []
    };
    const role = JSON.parse(localStorage.getItem('@omni/fast_track/user')).role;
    if (role === 'PCP' || role === 'COORDENADOR' || role === 'VISUALIZADOR')
      browserHistory.push('/dashboard');
  }

  save = async () => {
    const confirmation = (
      await Swal.fire({
        title: 'Confirmação',
        text: 'Você tem certeza que deseja adicionar este usuário?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })
    ).value;
    if (confirmation) {
      const result = await UsersService.add(this.state);
      if (result) {
        browserHistory.push('/users');
        toast.success('Usuário criado com sucesso!');
      }
    }
  };

  render() {
    return (
      <div className="wrapper wrapper-content">
        <div
          className="row wrapper border-bottom white-bg page-heading animated bounceInRight"
          style={{ margin: 'auto', padding: '30px' }}
        >
          <h2>Criar novo usuário</h2>
          <br />
          <div className="row">
            <div className="col-lg-6">
              <h4>
                <b>Ativo?</b>
              </h4>
              <ReactSwitch
                checked={this.state.active}
                onChange={(active) => this.setState({ active })}
                onColor="#57b87b"
                checkedIcon={false}
                uncheckedIcon={false}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <h4>
                <b>Nome*</b>
              </h4>
              <div className="input-group m-b">
                <span className="input-group-addon">
                  <i className="fa fa-user"></i>
                </span>
                <input
                  type="text"
                  placeholder="Nome completo"
                  className="form-control"
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <h4>
                <b>E-mail*</b>
              </h4>
              <div className="input-group m-b">
                <span className="input-group-addon">
                  <i className="fa fa-envelope"></i>
                </span>
                <input
                  type="text"
                  placeholder="E-mail"
                  className="form-control"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <span className="col-lg-12" styles={styles.helperText}>
              Info de Acesso
            </span>
            <div className="col-lg-6">
              <h4>
                <b>Login*</b>
              </h4>
              <div className="input-group m-b">
                <span className="input-group-addon">
                  <i className="fa fa-envelope"></i>
                </span>
                <input
                  type="text"
                  placeholder="Login"
                  className="form-control"
                  value={this.state.login}
                  onChange={(e) => this.setState({ login: e.target.value })}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <h4>
                <b>Senha*</b>
              </h4>
              <div className="input-group m-b">
                <span className="input-group-addon">
                  <i className="fa fa-key"></i>
                </span>
                <input
                  type="password"
                  placeholder="Senha"
                  className="form-control"
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <span className="col-lg-12" styles={styles.helperText}>
              Info referente à matrícula no SIGMEC
            </span>
            <div className="col-lg-6">
              <h4>
                <b>Matrícula</b>
              </h4>
              <div className="input-group m-b">
                <span className="input-group-addon">
                  <i className="fa fa-credit-card"></i>
                </span>
                <input
                  type="text"
                  placeholder="Matrícula"
                  className="form-control"
                  value={this.state.registration}
                  onChange={(e) =>
                    this.setState({ registration: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-lg-6">
              <h4>
                <b>Grupo*</b>
              </h4>
              <div className="input-group m-b">
                <span className="input-group-addon">
                  <i className="fa fa-users"></i>
                </span>
                <input
                  type="text"
                  placeholder="Grupo"
                  className="form-control"
                  value={this.state.group}
                  onChange={(e) => this.setState({ group: e.target.value })}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <h4>
                <b>Perfil*</b> (Permissões de acesso ao Fast Track)
              </h4>
              <div className="input-group m-b">
                <span className="input-group-addon">
                  <i className="fa fa-address-book"></i>
                </span>
                <select
                  className="form-control"
                  value={this.state.role}
                  onChange={(e) =>
                    this.setState({
                      role: e.target.value,
                      enable_maintenance_web: false,
                    })
                  }
                  defaultValue="Selecione a Função"
                >
                  <option value="" disabled>
                    Selecione a Função
                  </option>
                  <option>ADM - TI</option>
                  <option>ADM</option>
                  <option>AVALIADOR</option>
                  <option>COORDENADOR</option>
                  <option>ENGENHARIA</option>
                  <option>VISUALIZADOR</option>
                  <option>PCP</option>
                  <option>AUDITOR MOSA</option>
                  <option>MECÂNICO</option>
                  <option>APOIO</option>
                </select>
              </div>
              {this.state.role === 'AUDITOR MOSA' && (
                <div className="form-check" style={{ paddingLeft: 0 }}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="enableMaintenanceCheck"
                    style={{ margin: 0 }}
                    checked={this.state.enable_maintenance_web}
                    onChange={(e) =>
                      this.setState({
                        enable_maintenance_web: e.target.checked,
                      })
                    }
                  />
                  <label
                    className="form-check-label"
                    for="enableMaintenanceCheck"
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp; Acesso à manutenção no cliente web
                  </label>
                </div>
              )}
            </div>
            <div className="col-lg-6">
              <h4>
                <b>Sigmec Post</b>
              </h4>
              <div className="input-group m-b">
                <span className="input-group-addon">
                  <i className="fa fa-users"></i>
                </span>
                <input
                  type="text"
                  placeholder="Sigmec Post"
                  className="form-control"
                  value={this.state.sigmec_post}
                  onChange={(e) => this.setState({ sigmec_post: e.target.value })}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <h4>
                <b>Sigmec Role</b>
              </h4>
              <div className="input-group m-b">
                <span className="input-group-addon">
                  <i className="fa fa-users"></i>
                </span>
                <input
                  type="text"
                  placeholder="Sigmec Role"
                  className="form-control"
                  value={this.state.sigmec_role}
                  onChange={(e) => this.setState({ sigmec_role: e.target.value })}
                />
              </div>
            </div>
            <div className="col-lg-6">
              {this.state.role === 'AUDITOR MOSA' && (<>
                <h4>
                  <b>Competência</b>
                </h4>
                <MultiSelect
                  options={auditor_cmpt_arr}
                  value={this.state.auditing_areas}
                  onChange={(value) => this.setState({ auditing_areas: value })}
                  labelledBy={'Selecione as competências'}
                  disableSearch
                  hasSelectAll={false}
                  overrideStrings={{
                    "allItemsAreSelected": "All items are selected.",
                    "clearSearch": "Limpar busca",
                    "noOptions": "Sem opções",
                    "search": "Buscar",
                    "selectAll": "Selecionar todos",
                    "selectSomeItems": "Selecione as competências"
                  }}
                /></>)}
            </div>
          </div>
          <br />
          <div className="col-lg-12" style={{ textAlign: 'center' }}>
            <button
              className="btn btn-success"
              onClick={this.save}
              style={{ margin: '2px' }}
            >
              Salvar
            </button>
            {/* <Link to="/users"> */}
            <button
              className="btn btn-danger"
              data-dismiss="modal"
              style={{ margin: '2px' }}
              onClick={async () => {
                const confirmation = (
                  await Swal.fire({
                    title: 'Confirmação',
                    text: 'Você tem certeza que deseja cancelar?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                  })
                ).value;
                if (confirmation) {
                  browserHistory.push('/users');
                }
              }}
            >
              Cancelar
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div >
    );
  }
}
