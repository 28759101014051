import React from 'react';
import { browserHistory, IndexRedirect, Route, Router } from 'react-router';
import { ToastContainer } from 'react-toastify';
import Main from '../components/layouts/Main';
import UsersService from '../services/UsersService';
import AddEvaluation from '../views/AddEvaluation';
import AddManagement from '../views/AddManagement';
import AddUser from '../views/AddUser';
import AircraftModels from '../views/AircraftModels';
import Aircrafts from '../views/Aircrafts/index';
import Bases from '../views/Bases';
import Dashboard from '../views/Dashboard';
import EditFlight from '../views/EditFlight';
import EditFlightReport from '../views/EditFlightReport';
import EditManagement from '../views/EditManagement';
import EditMosa from '../views/EditMosa';
import EditPassword from '../views/EditPassword';
import EditPCPImport from '../views/EditPcpImport';
import EngineeringStatistics from '../views/EngineeringStatistics';
import EvaluationDetails from '../views/EvaluationDetails';
import FlightManagement from '../views/FlightManagement';
import FlightReport from '../views/FlightReport';
import Hangars from '../views/Hangars';
import ImportFlight from '../views/ImportFlights';
import Login from '../views/Login';
import Management from '../views/Management';
import ManagementBases from '../views/ManagementBases';
import MechanicsEvaluation from '../views/MechanicsEvaluation';
import MosaManagement from '../views/MosaManagement';
import MosaPendencies from '../views/MosaPendencies';
import MosaReports from '../views/MosaReports.js';
import NewPCPImport from '../views/NewPcpImport';
import PcpImport from '../views/PcpImport';
import Processes from '../views/Process/index';
import AddProcess from '../views/ProcessAdd/index';
import EditProcess from '../views/ProcessEdit/index';
import Profiles from '../views/Profiles';
import Reasons from '../views/Reason/index';
import AddReason from '../views/ReasonAdd/index';
import EditReason from '../views/ReasonEdit/index';
import RecordHums from '../views/RecordHums';
import RedefinePassword from '../views/RedefinePassword/index';
import Reports from '../views/Reports.js';
import ResetPassword from '../views/ResetPassword/index';
import User from '../views/User';
import Users from '../views/Users';



const unauthenticatedRoutes = ['/login', '/reset-password', '/record-hums'];
export default class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
    };
    this.authenticate();
  }

  authenticate = async () => {
    const params = window.location.search;
    const formattedParams = params.split('?token=');
    const token = formattedParams.length ? formattedParams[1] : null;

    const loggedUser = JSON.parse(
      localStorage.getItem('@omni/fast_track/user')
    );
    const loggedToken = JSON.parse(
      localStorage.getItem('@omni/fast_track/token')
    );
    if (
      !unauthenticatedRoutes.includes(
        browserHistory.getCurrentLocation().pathname
      ) &&
      !loggedUser &&
      !token
    ) {
      browserHistory.replace(`/login`);
    } else {
      const result = await UsersService.authenticate(`Bearer ${loggedToken}`);
      if (
        !unauthenticatedRoutes.includes(
          browserHistory.getCurrentLocation().pathname
        ) &&
        !result.authenticated
      ) {
        localStorage.clear('@omni/fast_track/user');
        localStorage.clear('@omni/fast_track/token');
        browserHistory.replace(`/login`);
      }
    }
  };

  render() {
    return (
      <Router history={browserHistory}>
        <Router history={browserHistory}>
          <Route path="/" component={Main}>
            <IndexRedirect to="/dashboard" />
            <Route path={`dashboard`} component={Dashboard} />
            <Route path={`managements`} component={Management} />
            <Route path={`flights_management`} component={FlightManagement} />
            <Route path={`flights_management/:id`} component={EditFlight} />
            <Route path={`flights_report`} component={FlightReport} />
            <Route path={`flights_report/:id`} component={EditFlightReport} />
            <Route
              path={`engineering/statistics`}
              component={EngineeringStatistics}
            />
            <Route path={`engineering/import`} component={ImportFlight} />
            <Route path={`mosa_managements`} component={MosaManagement} />
            <Route path={`mosa_management/:id`} component={EditMosa} />
            <Route path={`managements/bases`} component={ManagementBases} />
            <Route path={`managements/:base`} component={Management} />
            <Route path={`mechanics-evaluation`} component={MechanicsEvaluation} />
            <Route path={`mechanics-evaluation/:id`} component={EvaluationDetails} />
            <Route path={`evaluate-mechanic`} component={AddEvaluation} />
            <Route
              path={`managements/:base/:hangar/:date`}
              component={Management}
            />
            <Route path={`management/:id`} component={EditManagement} />
            <Route path={`new-management/:base`} component={AddManagement} />
            <Route path={`pcp-archives`} component={PcpImport} />
            <Route path={`pcp-archives/:page`} component={PcpImport} />
            <Route path={`pcp-import`} component={NewPCPImport} />
            <Route path={`pcp-import/:id`} component={EditPCPImport} />
            <Route path={`reports`} component={Reports} />
            <Route path={`mosa_reports`} component={MosaReports} />
            <Route path={`mechanical_reports`} component={MosaPendencies} />
            <Route path={`add-reason`} component={AddReason} />
            <Route path={`edit-reason/:id`} component={EditReason} />
            <Route path={`add-process`} component={AddProcess} />
            <Route path={`edit-process/:id`} component={EditProcess} />
            <Route
              path={`users`}
              component={(props) => (
                <Users {...props} history={browserHistory} />
              )}
            />
            <Route
              path={`reasons`}
              component={(props) => (
                <Reasons {...props} history={browserHistory} />
              )}
            />
            <Route
              path={`reasons/:page`}
              component={(props) => (
                <Reasons {...props} history={browserHistory} />
              )}
            />
            <Route
              path={`process`}
              component={(props) => (
                <Processes {...props} history={browserHistory} />
              )}
            />
            <Route
              path={`process/:page`}
              component={(props) => (
                <Processes {...props} history={browserHistory} />
              )}
            />
            <Route path={`profiles`} component={Profiles} />
            <Route path={`aircrafts`} component={Aircrafts} />
            <Route path={`aircrafts/:page`} component={Aircrafts} />
            <Route path={`aircraft-models`} component={AircraftModels} />
            <Route path={`aircraft-models/:page`} component={AircraftModels} />
            <Route path={`bases`} component={Bases} />
            <Route path={`bases/:page`} component={Bases} />
            <Route path={`hangars`} component={Hangars} />
            <Route path={`hangars/:page`} component={Hangars} />
            <Route path={`add-user`} component={AddUser} />
            <Route path={`user/:id`} component={User} />
            <Route path={`edit-password/:id`} component={EditPassword} />
          </Route>
          <Route
            path={`login`}
            component={(props) => <Login {...props} history={browserHistory} />}
          />
          <Route
            path={`redefine-password`}
            component={(props) => (
              <RedefinePassword {...props} history={browserHistory} />
            )}
          />
          <Route
            path={`reset-password`}
            component={(props) => (
              <ResetPassword {...props} history={browserHistory} />
            )}
          />
          <Route
            path={`record-hums`}
            component={(props) => (
              <RecordHums {...props} history={browserHistory} />
            )}
          />
        </Router>
        <ToastContainer autoClose={3000} />
      </Router>
    );
  }
}
