import { browserHistory } from "react-router";

const logout = () => {
    try {
        localStorage.clear('omni/fast_track/user');
        localStorage.clear('omni/fast_track/token');
        browserHistory.replace('/login');
    } catch (err) {
    }
};

export default logout;
