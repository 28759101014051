import React, { Component } from "react";
import ReactSwitch from "react-switch";
import BasesService from "../services/BasesService";
import { toast } from "react-toastify";
import * as $ from "jquery";
import LoadingModal from "./LoadingModal";
import Swal from "sweetalert2";
import MosaService from "../services/MosaService";
import moment from "moment";
import RoundedPagination from '../components/common/RoundedPagination';

export default class MechanicMOSAsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
      name: "",
      code: "",
      pages: 0,
      page: 1,
    };
  }

  componentDidUpdate(prevProps, { page }) {
    if (page !== this.state.page) this.getMOSA(this.props.selected);
  }

  getMOSA = async (mechanic) => {
    this.setState({ loading: true });
    this.page = this.state.page;
    const mosa = await MosaService.filter({
      hangar: '',
      code: '',
      aircraft: '',
      start_service: '',
      end_service: '',
      start_prediction: '',
      end_prediction: '',
      complexity: '',
      programming: '',
      mosa_status: '',
      mechanic,
      risk: false,}, this.page);
    if (mosa) this.setState({ ...mosa });
    else {
      $("#MOSAsModal").removeClass("show").addClass("hide");
      $(".modal-backdrop").removeClass("show").addClass("hide");
    }

    this.setState({ loading: false });
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.selected && nextProps.selected !== this.props.selected) {
      this.getMOSA(nextProps.selected);
    }
  }

  handlePagination = (event, value) => {
    this.setState({ page: value });
  };

  renderMosas = ({ _id, code, mosa_end, aircraft, register, inspection  }) => {
    return (
      <tr id="identify" key={_id}>
        <td>
          <a>{moment(mosa_end).format("DD/MM/YYYY")}</a>
        </td>
        <td>
          <a>{aircraft ? aircraft.prefix : ''}</a>
        </td>
        <td>
          <a>{register}</a>
        </td>
        <td>
          <a>{inspection}</a>
        </td>
        <td>
          <a>{code}</a>
        </td>
      </tr>
    );
  };

  render() {
    return (
      <div className="modal  inmodal" id="MOSAsModal">
        <LoadingModal visible={this.state.loading} />
        <div className="modal-dialog modal-lg animated bounceInRight">
          <div
            className="modal-content row wrapper border-bottom white-bg page-heading"
            style={{ margin: "auto", padding: "30px" }}
          >
            <div className="mosas-modal-header">
              <h2>MOSAs</h2>
              <hr />
            </div>
            <div className="row" style={{ marginTop: "20px" }}>
              <LoadingModal className="col-lg-12" visible={this.state.loading} />
              <div
                hidden={this.state.loading}
                className="col-lg-12 table-responsive ibox-content"
                style={{
                  paddingLeft: "40px",
                  border: "rgba(0,0,0,0.05) solid 1px",
                  paddingRight: "40px",
                  paddingBottom: "20px",
                  paddingTop: "20px",
                }}
              >
                {this.state.result && this.state.result.length > 0 ? (
                  <>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Data</th>
                          <th>Aeronave</th>
                          <th>Registro</th>
                          <th>Inspeção</th>
                          <th>Código MOSA</th>
                        </tr>
                      </thead>
                      {this.state.result && <tbody>{this.state.result.map(this.renderMosas)}</tbody>}
                    </table>

                    <RoundedPagination count={this.state.pages}
                                       onChange={this.handlePagination}/>
                  </>
                ) : (
                  <center> <span>Não existem registros para o usuário selecionado.</span></center>
                )}
              </div>
            </div>
            <div className="col-lg-12" style={{ textAlign: "center" }}>
              <button
                data-target="#MOSAsModal"
                className="btn btn-success"
                data-dismiss="modal"
                style={{ marginTop: "20px" }}
              >
                Voltar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
