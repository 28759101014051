import React, { Component } from 'react';
import { uniqueId } from 'lodash';
import { browserHistory, Link } from 'react-router';
import { CircularProgress, IconButton } from '@material-ui/core';
import { toast } from 'react-toastify';
import ReactSwitch from 'react-switch';
import Swal from 'sweetalert2';
import axios from 'axios';
import fileSize from 'filesize';

import { MdError, MdCheckCircle, MdCloudDownload, MdDeleteForever } from 'react-icons/md';


import Management from '../services/ManagementService';
import AircraftService from '../services/AircraftsService';
import AircraftModelService from '../services/AircraftModelsService';
import UserService from '../services/UsersService';
import BaseService from '../services/BasesService';
import HangarsService from '../services/HangarsService';
import LoadingModal from './LoadingModal';

import { apiURL } from '../config/consts';


export default class EditManagement extends Component {
  constructor(props) {
    super(props);
    const role = JSON.parse(localStorage.getItem('@omni/fast_track/user')).role;
    this.state = {
      active: true,
      code: null,
      base: null,
      aircraft: null,
      inspection: null,
      execute_prediction: `NaN-NaN-NaNTNaN:NaN`,
      complexity: null,
      day_credits: null,
      hour_credits: null,
      circle_credits: null,
      register: null,
      man_hour_value: null,
      register_type: '',
      observation: '',
      aircrafts: [],
      status: null,
      aircraft_models: [],
      bases: [],
      start_service: `NaN-NaN-NaNTNaN:NaN`,
      end_service: `NaN-NaN-NaNTNaN:NaN`,
      hangars: [],
      hangarsPositions: [],
      hangarPosition: {},
      hangarIndex: null,
      isMosa: false,
      query: '',
      mechanics: [],
      presence_list: [],
      initial_status: false,
      fileList: [],
      role,

    };
    // if (role === 'PCP' || role === 'VISUALIZADOR')
    //   browserHistory.push('/dashboard');
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    this.setState({ loading: true });
    const management = await Management.getById(this.props.params.id);
    const attachs = await Management.getAttachs(this.props.params.id);

    const uploadedFiles = attachs.map((file) => ({
      file,
      id: file._id,
      name: file.original_name,
      progress: 0,
      readableSize: fileSize(file.size),
      preview: file.url,
      uploaded: true,
      error: false,
      url: file.url
    }))
    this.setState({ fileList: uploadedFiles })

    await this.setState({ ...management });
    if (management.base) {
      const hangars = await this.getHangars(management.base);
      const index = management.hangar
        ? hangars.findIndex(
          (h) =>
            h.hangar._id === management.hangar._id &&
            h.position === Number(management.position)
        )
        : -1;

      const hangarIndex =
        management.base && management.hangar && index !== -1 ? index : null;
      await this.setState({ hangarIndex });

      const mechanics = await UserService.getMechanics(management.base._id);
      if (mechanics) await this.setState({ mechanics });
    } else {
      this.setState({
        base: '',
      });
    }
    let start_service = `NaN-NaN-NaNTNaN:NaN`;
    if (management.start_service) {
      start_service = new Date(management.start_service);
      start_service.setMinutes(
        start_service.getMinutes() + start_service.getTimezoneOffset()
      );
    }
    let end_service = `NaN-NaN-NaNTNaN:NaN`;
    if (management.end_service) {
      end_service = new Date(management.end_service);
      end_service.setMinutes(
        end_service.getMinutes() + end_service.getTimezoneOffset()
      );
    }

    let execute_prediction = `NaN-NaN-NaNTNaN:NaN`;
    if (management.execute_prediction) {
      execute_prediction = new Date(management.execute_prediction);
      execute_prediction.setMinutes(
        execute_prediction.getMinutes() + execute_prediction.getTimezoneOffset()
      );
    }

    await this.setState({
      execute_prediction: this.validationDate(execute_prediction),
      start_service: this.validationDate(start_service),
      end_service: this.validationDate(end_service),
      initial_status: management.isMosa,
    });

    const aircrafts = await AircraftService.getAll();
    await this.setState({ aircrafts: aircrafts });

    const aircrafts_models = await AircraftModelService.getAll();
    await this.setState({ aircrafts_models: aircrafts_models });

    const bases = await BaseService.getAll();
    await this.setState({ bases: bases });
    this.setState({ loading: false });
  };

  getHangars = async (base) => {
    if (base) {
      const hangars = await HangarsService.getByBase(base._id || base);
      if (hangars) {
        let hangarsPositions = [];
        hangars.forEach((hangar) => {
          const composition = hangar.positions.map((pos) => ({
            hangar,
            position: pos.position,
          }));
          hangarsPositions = [...hangarsPositions, ...composition];
        });
        this.setState({ hangarsPositions });
        return hangarsPositions;
      }
    }
  };

  validationDate = (date) => {
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const month = (newDate.getMonth() + 1).toString().padStart(2, 0);
    const day = newDate.getDate().toString().padStart(2, 0);
    const hour = newDate.getHours().toString().padStart(2, 0);
    const minute = newDate.getMinutes().toString().padStart(2, 0);

    return !date ? null : `${year}-${month}-${day}T${hour}:${minute}`;
  };

  handleUpdate = async () => {
    const confirmation = (
      await Swal.fire({
        title: 'Confirmação',
        text:
          'Você tem certeza que deseja salvar as alterações a este serviço?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })
    ).value;
    if (confirmation) {
      if (
        this.state.aircraft &&
        this.state.aircraft !== '' &&
        this.state.base &&
        this.state.base !== ''
      ) {
        if (this.state.status === 'Error') {
          // If the document has to be recovered
          await this.setState({ status: 'Importado' });
        } else if (this.state.start_service && this.state.end_service) {
          await this.setState({ status: 'Conferido' });
        }

        const start_service = new Date(this.state.start_service);
        start_service.setMinutes(
          start_service.getMinutes() - start_service.getTimezoneOffset()
        );
        const end_service = new Date(this.state.end_service);
        end_service.setMinutes(
          end_service.getMinutes() - end_service.getTimezoneOffset()
        );
        const result = await Management.edit(this.props.params.id, {
          aircraft: this.state.aircraft,
          base: this.state.base,
          hangar: this.state.hangarPosition.hangar
            ? this.state.hangarPosition.hangar._id
            : this.state.hangar,
          position: this.state.hangarPosition.position
            ? this.state.hangarPosition.position
            : this.state.position,
          man_hour_value: this.state.man_hour_value,
          start_service,
          end_service,
          observation: this.state.observation,
          status: this.state.status,
          mosa_status:
            this.state.isMosa && !this.state.mosa_status
              ? 'MOSA Solicitada pela Qualidade'
              : this.state.mosa_status,
          presence_list: this.state.presence_list,
          isMosa: this.state.isMosa,
          mosa_selected_by: this.state.mosa_selected_by,
        });

        if (result) {
          // This base id can be the object or the string
          if (this.state.base && this.state.base._id) {
            browserHistory.push(`/managements/${this.state.base._id}`);
          } else if (this.state.base) {
            // browserHistory.push(`/managements/${this.state.base}`);
            browserHistory.goBack();
          }
          toast.success('Documento atualizado com sucesso!');
        }
      } else {
        toast.error('Os campos de aeronave e base são obrigatórios!');
      }
    }
  };

  startValidation = (date) => {
    if (this.state.end_service === `NaN-NaN-NaNTNaN:NaN`) return;
    let start = new Date(date);
    let end = new Date(this.state.end_service);
    if (this.state.start_service) {
      if (start.getTime() > end.getTime()) {
        toast.error('A data de início não pode ser depois da data de fim!');
      }
    }
  };

  endValidation = (date) => {
    let start = new Date(this.state.start_service);
    let end = new Date(date);
    if (this.state.start_service) {
      if (start.getTime() > end.getTime()) {
        toast.error('A data de início não pode ser depois da data de fim!');
      }
    }
  };

  renderAircraftOptions = (aircraft) => (
    <option value={aircraft._id}>
      {`${aircraft.prefix}`} ({`${aircraft._model.manufacturer}`} -{' '}
      {`${aircraft._model.model}`})
    </option>
  );

  renderBaseOptions = (base) => (
    <option value={base._id}>
      {`${base.name}`} ({`${base.code}`}){' '}
    </option>
  );

  updateFile = async (id, data) => {
    this.setState({
      fileList: await this.state.fileList.map(file => {
        return id == file.id ? { ...file, ...data } : file;
      })
    })
  }

  uploadFile = (file) => {
    const data = new FormData();
    let id = JSON.parse(localStorage.getItem('@omni/fast_track/user'))._id;
    let mosa_id = this.props.params.id
    data.append('file', file.file, file.name);
    data.append('mosa_id', this.props.params.id);


    axios.post(apiURL + '/pcp_service/attach/' + id, data, {
      onUploadProgress: ProgressEvent => {
        const progress = parseInt(Math.round((ProgressEvent.loaded) * 100) / ProgressEvent.total);
        this.updateFile(file.id, { progress });
      }
    }).then(response => {
      this.updateFile(file.id, { id: response.data.result._id, uploaded: true, url: response.data.result.url });
      toast.success('Upload concluído!');
    }).catch(err => {
      this.updateFile(file.id, { error: true, uploaded: false });
      toast.error('Erro ao subir arquivo! ' + err);
    })
  }

  handleDelete = async file => {
    const { id } = file;
    const confirmation = (
      await Swal.fire({
        title: 'Confirmação',
        text:
          'Você tem certeza que deseja apagar esse anexo?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })
    ).value;
    if (confirmation) {
      axios.delete(apiURL + '/pcp_service/attach/' + file.id, {
        onUploadProgress: ProgressEvent => {
          const progress = parseInt(Math.round((ProgressEvent.loaded) * 100) / ProgressEvent.total);
          this.updateFile(id, { progress });
        }
      }).then(response => {
        toast.success('Anexo deletado!');
        this.setState({ fileList: this.state.fileList.filter(file => file.id !== id) });
      }).catch(err => {
        toast.error('Erro ao deletar anexo! ' + err);
      })

    }
  }

  blockFieldsCondition = () => { 
    return ['PCP', 'VISUALIZADOR'].includes(this.state.role);
  };

  render() {
    return (
      <div className="wrapper wrapper-content">
        <LoadingModal visible={this.state.loading} />
        <div
          className="row wrapper border-bottom white-bg page-heading animated bounceInRight"
          style={{ margin: 'auto', padding: '40px' }}
        >
          <h2>Editar Dados do Serviço de Manutenção</h2>
          <br />
          <div className="row">
            <div className="col-lg-12 row">
              <div
                className="col-lg-12 row"
                style={{
                  paddingLeft: '40px',
                  paddingRight: '40px',
                  paddingBottom: '20px',
                  paddingTop: '0px',
                  margin: '0px',
                }}
              >
                <h4
                  className="col-lg-12"
                  style={{
                    borderBottom: '1.5px solid rgba(0,0,0,0.07)',
                    textAlign: 'center',
                    marginTop: '20px',
                    marginBottom: '20px',
                    padding: '10px',
                    color: 'red',
                    fontSize: '18px',
                  }}
                >
                  DADOS DO SERVIÇO
                </h4>
                <div className="col-lg-6">
                  <h4>
                    <b>Aeronave</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-plane"></i>
                    </span>
                    <select
                      className="form-control"
                      disabled={this.state.status !== 'Error'}
                      value={
                        this.state.aircraft ? this.state.aircraft._id : null
                      }
                      onChange={(e) =>
                        this.setState({ aircraft: e.target.value })
                      }
                    >
                      <option value="">Selecione uma aeronave</option>
                      {this.state.aircrafts.map(this.renderAircraftOptions)}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Base</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-home"></i>
                    </span>
                    <select
                      className="form-control"
                      value={this.state.base ? this.state.base._id : null}
                      onChange={(e) => {
                        this.setState({
                          base: e.target.value,
                          hangarIndex: null,
                        });
                        this.getHangars(e.target.value);
                      }}
                      disabled={this.blockFieldsCondition()}
                    >
                      <option value="">Selecione uma base</option>
                      {this.state.bases.map(this.renderBaseOptions)}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Inspeção</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-cogs"></i>
                    </span>
                    <input
                      type="text"
                      disabled
                      placeholder="Inspeção"
                      className="form-control"
                      value={this.state.inspection}
                      onChange={(e) =>
                        this.setState({ inspection: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Complexidade</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-fire"></i>
                    </span>
                    <select
                      disabled
                      className="form-control"
                      value={this.state.complexity}
                      onChange={(e) =>
                        this.setState({ complexity: e.target.value })
                      }
                    >
                      <option value="">Todas as Complexidades</option>
                      <option value="Base">Base</option>
                      <option value="Line">Line</option>
                      <option value="External">External</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Previsão de Execução</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-calendar"></i>
                    </span>
                    <input
                      type="datetime-local"
                      disabled
                      className="form-control"
                      value={this.state.execute_prediction}
                      onChange={(e) =>
                        this.setState({ execute_prediction: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Tipo de Registro</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-check-circle"></i>
                    </span>
                    <select
                      type="text"
                      className="form-control"
                      value={this.state.register_type}
                      onChange={(e) =>
                        this.setState({ registerType: e.target.value })
                      }
                      disabled={this.state.programming}
                    >
                      <option value="">Tipo de Registro</option>
                      <option value="TC">TC</option>
                      <option value="DB">DB</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Registro</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-key"></i>
                    </span>
                    <input
                      type="text"
                      disabled
                      placeholder="Registro"
                      className="form-control"
                      value={this.state.register}
                      onChange={(e) =>
                        this.setState({ register: e.target.value })
                      }
                    />
                  </div>
                </div>
                <h4
                  className="col-lg-12"
                  style={{
                    borderBottom: '1.5px solid rgba(0,0,0,0.07)',
                    textAlign: 'center',
                    marginTop: '20px',
                    marginBottom: '20px',
                    padding: '10px',
                    color: 'red',
                    fontSize: '18px',
                  }}
                >
                  POSICIONAMENTO DA AERONAVE NO HANGAR
                </h4>

                <div className="col-lg-6">
                  <h4>
                    <b>Posição</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-map-marker-alt"></i>
                    </span>
                    <select
                      style={{ backgroundColor: '#fff9c4', color: 'black' }}
                      value={this.state.hangarIndex}
                      disabled={this.blockFieldsCondition()}
                      onChange={(e) => {
                        this.setState({
                          hangarPosition: e.target.value
                            ? this.state.hangarsPositions[e.target.value]
                            : { hangar: { _id: null }, position: null },
                          hangarIndex: e.target.value,
                        });
                      }}
                      className="form-control"
                    >
                      <option
                        value={null}
                        label="Selecione um hangar e posição"
                      />
                      {this.state.hangarsPositions.map((comp, index) => (
                        <option
                          value={index}
                          label={`${comp.hangar.code} - Posição ${comp.position}`}
                        />
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Homem/Hora</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-clock"></i>
                    </span>
                    <input
                      style={{ backgroundColor: '#fff9c4', color: 'black' }}
                      type="number"
                      className="form-control"
                      placeholder="Homem/Hora"
                      disabled={this.blockFieldsCondition()}
                      value={this.state.man_hour_value}
                      onChange={(e) =>
                        this.setState({ man_hour_value: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Início do Serviço</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-calendar-alt"></i>
                    </span>
                    <input
                      style={{ backgroundColor: '#fff9c4', color: 'black' }}
                      type="datetime-local"
                      className="form-control"
                      disabled={this.blockFieldsCondition()}
                      value={this.state.start_service}
                      onChange={(e) => {
                        this.setState({ start_service: e.target.value });
                        this.startValidation(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4>
                    <b>Fim do Serviço</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-calendar-alt"></i>
                    </span>
                    <input
                      style={{ backgroundColor: '#fff9c4', color: 'black' }}
                      type="datetime-local"
                      className="form-control"
                      disabled={this.blockFieldsCondition()}
                      value={this.state.end_service}
                      onChange={(e) => {
                        this.setState({ end_service: e.target.value });
                        this.endValidation(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="col-lg-12 row"
                style={{
                  paddingLeft: '40px',
                  paddingRight: '40px',
                  paddingBottom: '20px',
                  paddingTop: '0px',
                  margin: '0px',
                }}
              >
                <div className="col-lg-4">
                  <h4>
                    <b>Créditos em Horas</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-clock"></i>
                    </span>
                    <input
                      disabled
                      type="number"
                      className="form-control"
                      placeholder="Crédito em Horas"
                      value={this.state.hour_credits}
                      onChange={(e) =>
                        this.setState({ hour_credits: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <h4>
                    <b>Crédito em Dias</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-calendar"></i>
                    </span>
                    <input
                      type="number"
                      disabled
                      placeholder="Crédito em Dias"
                      className="form-control"
                      value={this.state.day_credits}
                      onChange={(e) =>
                        this.setState({ day_credits: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <h4>
                    <b>Crédito em Ciclos</b>
                  </h4>
                  <div className="input-group m-b">
                    <span className="input-group-addon">
                      <i className="fa fa-sync-alt"></i>
                    </span>
                    <input
                      type="number"
                      disabled
                      placeholder="Crédito em Ciclos"
                      className="form-control"
                      value={this.state.circle_credits}
                      onChange={(e) =>
                        this.setState({ circle_credits: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <h4>
                    <b>Observações</b>
                  </h4>
                  <textarea
                    style={{ backgroundColor: '#fff9c4', color: 'black' }}
                    type="text"
                    rows="6"
                    placeholder="Observações"
                    className="form-control"
                    disabled={this.blockFieldsCondition()}
                    value={this.state.observation}
                    onChange={(e) =>
                      this.setState({ observation: e.target.value })
                    }
                  ></textarea>
                </div>
              </div>
              <h4 className="col-lg-12 mosa-title">
                MOSA&nbsp;&nbsp;&nbsp;&nbsp;
                <ReactSwitch
                  checked={this.state.isMosa}
                  onChange={(isMosa) =>
                    this.setState({
                      isMosa,
                      mosa_selected_by: JSON.parse(
                        localStorage.getItem('@omni/fast_track/user')
                      )._id,
                    })
                  }
                  onColor="#57b87b"
                  checkedIcon={false}
                  uncheckedIcon={false}
                  style={{ marginBottom: -30 }}
                  disabled={this.state.initial_status || this.blockFieldsCondition()}
                />
              </h4>
              <h4
                className="col-lg-12"
                style={{
                  borderBottom: '1.5px solid rgba(0,0,0,0.07)',
                  textAlign: 'center',
                  marginTop: '20px',
                  marginBottom: '20px',
                  padding: '10px',
                  color: 'red',
                  fontSize: '18px',
                }}
              >
                ANEXOS
              </h4>

              {!this.blockFieldsCondition() && 
              <>
                <label for="file_input" className="btn btn-success" to="/pcp-import"><i className="fa fa-upload"></i> Novo anexo</label>
                <input id="file_input"
                  multiple
                  type="file"
                  style={{ display: 'none' }}
                  disabled={this.blockFieldsCondition()}
                  onChange={(event) => {
                    const list = [...event.target.files];
                    const uploadedFiles = list.map((file, index) => ({
                      file,
                      id: uniqueId(),
                      name: file.name,
                      progress: 0,
                      readableSize: fileSize(file.size),
                      preview: URL.createObjectURL(file),
                      uploaded: false,
                      error: false,
                      url: null
                    }))
                    const concatList = [...this.state.fileList, ...uploadedFiles];

                    const filter_list = concatList.filter(el => {
                      if (!el.uploaded) {
                        this.uploadFile(el)
                        return el;
                      }
                    })
                    if (!!!filter_list.length)
                      toast.error('Nenhum arquivo para subir. Adicione um arquivo');
                    this.setState({ fileList: concatList })
                  }}
                />
              </>}
              <table className="table table-striped" style={{ marginBottom: 100 }}>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Tamanho</th>
                    <th>Status</th>
                    <th>Deletar</th>
                  </tr>
                </thead>
                <tbody>
                  {!!this.state.fileList.length ?
                    this.state.fileList.map((el, index) =>
                      <tr key={index}>
                        <td 
                            title={el.name}
                            style={{
                              maxWidth: 250,
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                            }}
                           >
                            {!!el.url && <IconButton size="small" style={{ padding: '3px 5px', marginRight: 10  }}>
                                <a href={el.url} target="_blank" rel="noopener noreferrer">
                                  <MdCloudDownload title="Download" size={24} style={{ color: 'rgb(25, 42, 57)' }} />
                                </a>
                              </IconButton>
                            }
                            {el.name}
                          </td>
                          <td>{el.readableSize}</td>
                          <td style={{ textAlign: 'center' }}>
                            {!el.uploaded && !el.error && <CircularProgress size={24} style={{ color: 'rgb(25, 42, 57)' }} variant="determinate" value={el.progress} />}
                            {el.uploaded && <MdCheckCircle size={24} color="#78e5d5" />}
                            {el.error && <MdError size={24} color="#e57878" />}
                          </td>
                          <td style={{ width: 80, display: 'flex', alignItems: 'center', flexDirection: 'space-between' }}>
                            {!this.blockFieldsCondition() && <IconButton size="small" onClick={() => this.handleDelete(el)}>
                              <MdDeleteForever size={28} color="#e57878" />
                            </IconButton>}
                          </td>
                      </tr>
                    ) :
                    <tr><td colSpan={4} style={{ textAlign: 'center' }}>Nenhum arquivo anexado</td></tr>
                  }
                </tbody>
              </table>
              <div className="col-lg-12" style={{ textAlign: 'center' }}>
                {!['PCP', 'VISUALIZADOR'].includes(this.state.role) ? <>
                  <button
                    className="btn btn-success"
                    onClick={this.handleUpdate}
                    style={{ margin: '2px' }}
                  >
                    Salvar
                  </button>
                  <button
                    className="btn btn-danger"
                    style={{ margin: '2px' }}
                    onClick={async () => {
                      const confirmation = (
                        await Swal.fire({
                          title: 'Confirmação',
                          text: 'Você tem certeza que deseja cancelar?',
                          type: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          confirmButtonText: 'Sim',
                          cancelButtonText: 'Não',
                        })
                      ).value;
                      if (confirmation) {
                        browserHistory.push(
                          `/managements/${this.state.base._id}`
                        );
                      }
                    }}
                  >
                    Cancelar
                  </button>
                 </> : <>
                    <button
                      className="btn btn-danger"
                      style={{ margin: '2px' }}
                      onClick={async () => {
                          browserHistory.push(
                            `/managements/${this.state.base._id}`
                          );
                      }}
                    >
                      Voltar
                    </button>
                 </>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
