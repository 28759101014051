import { apiURL } from "../config/consts";
import { toast } from "react-toastify";
import logout from "../utils/Auth";
import fetch from "./fetchWrapper";

export default class AircraftModelsService {
  static get = async (page, filter = false) => {
    try {
      const url = filter
        ? `${apiURL}/aircraft_model-filter/${filter}`
        : `${apiURL}/aircraft_models-listall/${page}`;
      const response = await fetch(url, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });
      if (response.status === 401) {
        logout();
        return;
      }
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static getAll = async () => {
    try {
      const url = `${apiURL}/aircraft_models-listall`;
      const response = await fetch(url, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });
      if (response.status === 401) {
        logout();
        return;
      }
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static getById = async (id) => {
    try {
      const response = await fetch(`${apiURL}/aircraft_model/${id}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static add = async (data) => {
    try {
      const response = await fetch(`${apiURL}/aircraft_model`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
        body: JSON.stringify(data),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static edit = async (id, data) => {
    try {
      const response = await fetch(`${apiURL}/aircraft_model/${id}`, {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
        body: JSON.stringify(data),
      });
      if (response.status !== 200 && response.status !== 401)
        throw new Error((await response.json()).message);
      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };
}
