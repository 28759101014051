import { apiURL } from "../config/consts";
import { toast } from "react-toastify";
import fetch from "./fetchWrapper";

export default class FlightService {
  static getAll = async () => {
    try {
      const url = `${apiURL}/flights`;
      const response = await fetch(url, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });

      if (response.status !== 200 && response.status !== 401) {
        throw new Error((await response.json()).message);
      }

      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static getById = async (id) => {
    try {
      const url = `${apiURL}/flights/${id}`;

      const response = await fetch(url, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
      });

      if (response.status !== 200 && response.status !== 401) {
        throw new Error((await response.json()).message);
      }

      return await response.json();
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static add = async (data) => {
    try {
      const url = `${apiURL}/flights`;

      const response = await fetch(url, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error((await response.json()).message);
      }

      const message = (await response.json()).message
      toast.success(message)
      return true;
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  };

  static update = async (data, id) => {
    try {
      const url = `${apiURL}/flights/${id}`;

      const response = await fetch(url, {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
        body: JSON.stringify(data),
      });

      if (response.status !== 200 && response.status !== 401) {
        throw new Error((await response.json()).message);
      }

      const message = (await response.json()).message
      toast.success(message)
      return true;
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  }

  static filter = async (filter, page, flag = 0) => {
    try {
      const url = flag === 0 ? `${apiURL}/flights/filter/${page}` : `${apiURL}/flights/filter/${page}?limit=10000`;

      const response = await fetch(url, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("@omni/fast_track/token")
          )}`,
        }),
        body: JSON.stringify(filter),
      });

      if (!response.ok) {
        throw new Error((await response.json()).message);
      }

      return await response.json()
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  }

  static recordHums = async (data) => {
    try {
      const url = `${apiURL}/record-hums`;

      const response = await fetch(url, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json"
        }),
        body: JSON.stringify(data),
      });

      if (response.status !== 200 && response.status !== 401) {
        throw new Error((await response.json()).message);
      }

      const message = (await response.json()).message
      toast.success(message)
      return true;
    } catch (e) {
      toast.error(e.message);
      return false;
    }
  }
}
