import React, { Component } from "react";
import { Link, browserHistory } from "react-router";

export default class Profiles extends Component {
  constructor(props) {
    super(props);
    const role = JSON.parse(localStorage.getItem("@omni/fast_track/user")).role;
    if (role === "PCP" || role === "COORDENADOR" || role === "VISUALIZADOR")
      browserHistory.push("/dashboard");
  }
  render() {
    return (
      <div className="wrapper wrapper-content animated fadeInDown">
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-6">
                <h2>Perfis de Usuário</h2>
                <ol className="breadcrumb">
                  <li>
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li>
                    <Link>Configurações</Link>
                  </li>
                  <li className="active">
                    <strong>Perfis de Usuário</strong>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper wrapper-content">
          <div className="row animated fadeInDown">
            <div className="col-lg-12 ibox-content">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Perfil</th>
                      <th>Descrição</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="identify">
                      <td>ADM - TI</td>
                      <td>
                        Administrador do sistema (possui todas as permissões)
                      </td>
                    </tr>
                    <tr className="identify">
                      <td>ADM</td>
                      <td>
                        Administrador (possui todas as permissões - exceto
                        adição de usuários)
                      </td>
                    </tr>
                    <tr className="identify">
                      <td>COORDENADOR</td>
                      <td>
                        Coordenador (pode inserir e atualizar as situações das
                        aeronaves/ver relatórios)
                      </td>
                    </tr>
                    <tr className="identify">
                      <td>ENGENHARIA</td>
                      <td>
                        Engenharia (pode inserir e atualizar as situações das
                        aeronaves/ver relatórios)
                      </td>
                    </tr>
                    <tr className="identify">
                      <td>VISUALIZADOR</td>
                      <td>
                        Visualizador (apenas visualização do dashboard/relatórios)
                      </td>
                    </tr>
                    <tr className="identify">
                      <td>PCP</td>
                      <td>
                        Responsável por importação de planilhas de serviços
                      </td>
                    </tr>
                    <tr className="identify">
                      <td>AUDITOR MOSA</td>
                      <td>Auditor de segurança de organização de manutenção</td>
                    </tr>
                    <tr className="identify">
                      <td>MECÂNICO</td>
                      <td>Mecânico</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
