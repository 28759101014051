import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { smoothlyMenu } from '../layouts/Helpers';
import $ from 'jquery';
import { Link } from 'react-router';

class TopHeader extends React.Component {

  toggleNavigation(e) {
    e.preventDefault();
    $("body").toggleClass("mini-navbar");
    smoothlyMenu();
  }

  logout = () => {
    localStorage.removeItem('@omni/fast_track/user');
    window.location.reload();
  }

  render() {
    return (
      <div className="row border-bottom">
        <nav className="navbar navbar-static-top" role="navigation" style={{ marginBottom: 0, backgroundColor: '#192a39', display: 'block' }}>
          <div className="nav navbar-top-links navbar-left"
            style={{ 
              display: 'flex',
              color: '#999c9e',
            }}
          >
            <button className="navbar-minimalize minimalize-styl-2 btn btn-success " onClick={this.toggleNavigation} href="#"><i className="fa fa-bars"></i> </button>
            <span style={{ margin: 'auto', marginLeft: 20, fontWeight: 'bold' }}>Bem vindo, Sr. {JSON.parse(localStorage.getItem('@omni/fast_track/user')).name}</span>
          </div>

          <ul className='nav navbar-top-links navbar-right'>
            <li><Link to={`/user/${JSON.parse(localStorage.getItem('@omni/fast_track/user'))._id}`} title="Editar Usuário">
              <i className="fa fa-user" />
            </Link></li>
            <li><a onClick={this.logout} title="Sair">
              <i className="fa fa-sign-out"></i> Sair
            </a></li>
          </ul>

        </nav>
      </div>
    )
  }
}

export default TopHeader
