import React, { Component } from 'react';
import { Link } from 'react-router';
import '../../styles/login.css';
import UsersService from '../../services/UsersService';
import { toast, ToastContainer } from 'react-toastify';

const loginLabel = {
  fontSize: 16,
  color: '#fff',
  cursor: 'pointer',
  marginBottom: 20,
};

export default class RedefinePassword extends Component {
  constructor(props) {
    super(props);
    this.state = { password: '', confirmPassword: '' };
  }

  login = async (event) => {
    const params = window.location.search;
    const formattedParams = params.split('?token=');
    const token = formattedParams.length ? formattedParams[1] : null;
    event.preventDefault();
    this.setState({ loading: true });
    await UsersService.reset({ ...this.state, token }, async (user) => {
      toast.success('Sua senha alterada com sucesso!');
      setTimeout(() => {
        this.props.history.push('/login');
      }, 1000);
    }, (e) => {
      toast.error(e.message ? e.message : 'Erro ao redefinir a senha!');
    });
    this.setState({ loading: false });
  }

  render() {
    return (
      <div className='main container-fluid'>
        <form onSubmit={this.login}>
          <div className='loginContainer text-center animated fadeIn'>
            <img src='/img/logo.png' alt='Logo' />
            <h2>Resete sua senha</h2>
            <input
              type='password'
              placeholder='Nova senha'
              value={this.state.password}
              className='form-control'
              onChange={e => this.setState({ password: e.target.value })}
            /><br />
            <input
              type='password'
              placeholder='Confirmação da nova senha'
              value={this.state.confirmPassword}
              className='form-control'
              onChange={e => this.setState({ confirmPassword: e.target.value })}
            /><br />
            {
              this.state.loading
                ? <div className='btn btn-light btn-block'>
                  <div className="spinner"></div>
                </div>
                : <input
                  className='btn btn-light btn-block'
                  type='submit'
                  value='Resetar'
                />
            }
            <br />
            <Link to='login'>
              <span style={loginLabel}>Voltar para a página de Login</span>
            </Link>
            <p><small>Powered by Criatech {new Date().getFullYear()}</small></p>
          </div>
        </form>
        <ToastContainer autoClose={3000} />
      </div>
    );
  }
}
